import React, {useContext} from "react";
import {CreateView} from "react-admin";
import {UnitEditForm, useUnitEditCreate} from "./UnitEdit";
import {__RouterContext} from "react-router";

export const UnitCreate = (props: {id: string}) => {
  const router = useContext(__RouterContext);

  const controllerProps = useUnitEditCreate({});

  return (
    <CreateView {...controllerProps}>
      <UnitEditForm isCreate redirect={"show"} />
    </CreateView>
  );
};
