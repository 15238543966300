import React, {useState} from "react";
import {Button, Card, IconButton} from "@material-ui/core";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import {DragHandle as DragHandleIcon, Edit} from "@material-ui/icons";
import {gql} from "@apollo/client";
import {useMutation, useQuery} from "@apollo/client";
import {makeStyles} from "@material-ui/styles";
import {useRedirect} from "../../components/useRedirect";
import {SeasonQuery_series_seasons_units} from "../../types/SeasonQuery";
import {SelectUnitDialog} from "./SelectUnitDialog";
import {useAssignUnitToSeason} from "../UnitShow/BelongsToTab/AssignSeriesDialog";
import {Link} from "react-router-dom";

function useSetSeasonUnitOrder(seasonId: string) {
  const [setSeasonUnitOrder, _] = useMutation<any>(
    gql`
      mutation SetEpisodeIndices($seasonId: ID!, $orderedIds: [ID]!) {
        setSeasonUnitOrder(seasonId: $seasonId, orderedUnitIds: $orderedIds) {
          season {
            units {
              id
            }
          }
        }
      }
    `,
    {
      variables: {
        seasonId,
      },
    }
  );
  return setSeasonUnitOrder;
}

export function EpisodeList(props: {
  seasonId: string;
  seriesId: string;
  units: SeasonQuery_series_seasons_units[];
  refetch?: () => void;
}) {
  const [selectDialogOpen, setSelectDialogOpen] = useState<boolean>(false);
  const setSeasonUnitOrder = useSetSeasonUnitOrder(props.seasonId);
  const [assignUnit, _] = useAssignUnitToSeason();

  // TODO: Whenever we get new units from the top, use those.
  const [items, setItems] = useState([...props.units]);

  const handleUnitToAddSelected = async (unitId: string) => {
    await assignUnit({
      variables: {
        unitId: unitId,
        seasonId: props.seasonId,
      },
    });
    setSelectDialogOpen(false);
    if (props.refetch) {
      props.refetch();
    }
  };

  const onSortEnd = ({oldIndex, newIndex}: any) => {
    console.log(oldIndex, newIndex);

    // Change locally
    const newOrder = arrayMove(items, oldIndex, newIndex);
    setItems(newOrder);

    // Send new order to the server
    setSeasonUnitOrder({
      variables: {
        orderedIds: newOrder.map((unit) => unit.id),
      },
    });
  };

  return (
    <div>
      <SortableEpisodeList
        onSortEnd={onSortEnd}
        useDragHandle={true}
        lockAxis="y"
        units={items}
        seriesId={props.seriesId}
      />

      <SelectUnitDialog
        isOpen={selectDialogOpen}
        onRequestClose={() => setSelectDialogOpen(false)}
        onSelect={handleUnitToAddSelected}
      />

      <Button variant={"contained"} onClick={() => setSelectDialogOpen(true)}>
        Add Unit
      </Button>
    </div>
  );
}

const useEpisodeStyles = makeStyles({
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "1em",
  },
});

const Episode = SortableElement(
  (opts: {unit: SeasonQuery_series_seasons_units; seriesId: string}) => {
    const {unit} = opts;
    const styles = useEpisodeStyles();
    const redirect = useRedirect(
      `/units/${unit.id}/edit?returnToSeries=${opts.seriesId}`
    );

    return (
      <Card
        key={unit.id!}
        elevation={2}
        style={{
          margin: "1em 0",
          padding: ".4em",
        }}
      >
        <div className={styles.header}>
          <DragHandle />{" "}
          <Link to={`/units/${unit.id}/show`}>{unit.name || "Unnamed"}</Link>
          <IconButton size={"small"} onClick={redirect}>
            <Edit />
          </IconButton>
        </div>
        <div>
          {unit.isReady ? (
            <div
              style={{fontWeight: "bold", marginBottom: "10px", color: "green"}}
            >
              Ready!
            </div>
          ) : null}
          {unit.description}
        </div>
      </Card>
    );
  }
);

const DragHandle = SortableHandle(() => <DragHandleIcon />);

const SortableEpisodeList = SortableContainer(
  (props: {units: SeasonQuery_series_seasons_units[]; seriesId: string}) => {
    const items = props.units.map((unit: any, index: number) => {
      return (
        <Episode
          index={index}
          key={unit.id}
          unit={unit}
          seriesId={props.seriesId}
        />
      );
    });

    return <div>{items}</div>;
  }
);
