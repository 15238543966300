import * as React from "react";
import {Line, Vocable} from "../../models";
import {getVocableText} from "../../models/getVocableText";
import {getLanguageDisplayName, isLanguageRtl} from "../../i18n";
import { useState, Fragment } from "react";
import {useCurrentVocableIds} from "./useCurrentVocableIds";
import {Text, StyleSheet} from 'react-native';

/**
 * Renders a subtitle line with individual words clickable.
 */
export function ClickableSubtitleLine(props: {
  lineGroup: Line,
  clickable: boolean,
  language: string,
  className?: string,
  showLanguage?: boolean,
  onElementClick?: any,
  getElementNodeId?: (element: Vocable) => string|number|null,
  getElementClickable?: (element: Vocable) => boolean,
  useAlternates?: string[],
  fallbackToMain?: boolean,
  altFormatter?: (value: any) => string,
  prePostFormatter?: (value?: string) => string,
  style?: any
}) {
  const {lineGroup, clickable: lineClickable} = props;
  const currentVocIds = useCurrentVocableIds();

  const [hoveredNodeId, setHoveredNodeId] = useState<null|string>(null);

  const prePostFormatter = props.prePostFormatter || ((x?: string) => x);

  if (!lineGroup) {
    return null;
  }

  const handleMouseMove = (event: any) => {
    const nodeId = event.target ? event.target.dataset.nodeid : null;
    if (nodeId) {
      setHoveredNodeId(nodeId);
    }
  };

  const handleMouseLeave = (event: any) => {
    setHoveredNodeId(null);
  };

  const lineEls = lineGroup.elements.map((line, idx) => {
    const wordEls = line.map((el: Vocable, idx: number) => {
      let wordClickable = lineClickable;
      if (props.getElementClickable) {
        wordClickable = props.getElementClickable(el);
      }

      const onClick = !wordClickable ? undefined : (e: any) => props.onElementClick(e, el);

      let textToRender = getVocableText(el, props);

      let hoverId;
      if (wordClickable && props.getElementNodeId) {
        hoverId = props.getElementNodeId(el);
      }

      const isCurrent = (currentVocIds?.indexOf(el.id) ?? -1) > -1;

      return <Fragment key={idx}>
        {prePostFormatter(el.pre)}<Word
          onClick={onClick}
          nodeId={hoverId}
          style={[
            isCurrent ? {color: '#ffc107'} : null,
            // width used to be: 0.07em
            (hoveredNodeId && hoverId === hoveredNodeId) ? {borderBottomWidth: 2, borderBottomColor: 'white'} : null
          ]}
        >
          {textToRender}
        </Word>{prePostFormatter(el.post)}
      </Fragment>
    });

    return <Text
      key={idx}
      style={props.style}
      // @ts-ignore: rn-web supports this, but it is not in the types of react-native...
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}

      // @ts-ignore Make react-native-web *not* insert dir="auto", use direction from parent instead.
      dir={""}
    >
      {wordEls}
    </Text>
  });

  return <CommonSubtitleLine
    language={props.language}
    showLanguage={props.showLanguage}
  >
    {lineEls}
  </CommonSubtitleLine>
}


function Word(props: {
  children: any,
  style?: any,
  onClick?: (e: any) => void,
  nodeId?: string|number|null
}) {
  return <Text
    style={props.style}
    onPress={props.onClick}
    dataSet={{nodeid: props.nodeId}}

    // @ts-ignore Make react-native-web *not* insert dir="auto", use direction from parent instead.
    dir={""}
  >
    {props.children}
  </Text>
}


export function TranslationLine(props: {
  line: string,
  language: string,
  showLanguage?: boolean,
  style?: any
}) {
  return <CommonSubtitleLine
    language={props.language}
    showLanguage={props.showLanguage}
    style={props.style}
  >
    {props.line}
  </CommonSubtitleLine>
}


/**
 * Sets the root "Text" properties for the nested Text items.
 */
function CommonSubtitleLine(props: {
  style?: any,
  language: string,
  showLanguage?: boolean,
  children: any
}) {
  const direction = isLanguageRtl(props.language) ? 'rtl' : 'ltr';

  return <Text
    /**
     * We guarantee this to have a darkened background, so we can set the text to white.
     */
    style={StyleSheet.flatten([
      {
        textAlign: 'center',
        marginTop: 5,
        marginBottom: 5,
        writingDirection: direction
      },
      props.style
    ])}
    key={props.language}

    // @ts-ignore react-native-web forces dir="auto" on all <Text> elements. We have to set this for the line,
    // then ensure the dir is unset for all.
    dir={direction}
  >
    {(props.showLanguage && false) ?
      <Text style={{
        fontSize: 12,
        paddingRight: 4,
        textTransform: 'uppercase',
      }}>
        {getLanguageDisplayName(props.language)}:
      </Text>
      : null}
    {props.children}
  </Text>;
}