import React, {useCallback} from "react";
import {ShowView, TabbedShowLayout, Tab} from "react-admin";
import {InjectTopBottom} from "../../components/InjectTopBottom";
import {Header} from "./Header";
import {TranslationList} from "./TranslationList";
import {TaskList} from "./TaskList";
import {useQuery} from "@apollo/client";
import {gql as gqlm} from "@apollo/client";
import {TrackList} from "./TrackList";
import {BelongsToTab} from "./BelongsToTab";
import {useApolloShowController} from "../../dataProvider/apolloControllers";
import {SummaryTab} from "./SummaryTab";
import {UnitShowQuery} from "../../types/UnitShowQuery";
import {MediaTrackTab} from "./MediaTrackTab";

const PostTitle = ({record}: any) => {
  return <span>Unit {record ? `"${record.name}"` : ""}</span>;
};

const useUnit = (id: string) => {
  return useQuery<UnitShowQuery>(
    gqlm`  
    query UnitShowQuery($id: ID!) {
      unit(id: $id) {        
        id,
        name,
        difficulty,
        description,
        previewImage {
          url
        }
        metadata {
          youtube,
          aparat
        },
        isReady,
        isMetadataComplete,
        mediaTrack { id, runtimeSeconds },
        captionTrack { 
          id,
          isTextReady, 
          isGrammarReady,
          currentVersion {
            id,
            translations(langs: ["en"]) {
              isReady
            }
          } 
        },
        hasGrammarTask: hasActiveTask(taskType: grammar),
        hasCaptionTask: hasActiveTask(taskType: captioning),
        hasTranslationTask: hasActiveTask(taskType: translation),
        accessDefinitions {
          siteId,
          requiredTokens         
        }
      }
    }`,
    {
      variables: {
        id,
      },
    }
  );
};

export const UnitShow = (props: {id: string}) => {
  const query = useUnit(props.id);
  const showProps = useApolloShowController(query, (data) => data.unit, {
    resourceName: "units",
  });

  return (
    <InjectTopBottom top={<Header {...showProps} />}>
      <ShowView {...showProps} title={<PostTitle />}>
        <TabbedShowLayout>
          <Tab label={"Summary"}>
            {query.data ? <SummaryTab unit={query.data.unit!} /> : null}
          </Tab>
          <Tab label={"Belongs To"}>
            {showProps.record ? (
              <BelongsToTab unitId={showProps.record.id} />
            ) : null}
          </Tab>
          <Tab label={"Text Tracks"}>
            {showProps.record ? (
              <TrackList unitId={showProps.record.id} />
            ) : null}
          </Tab>
          <Tab label={"Media"}>
            {showProps.record ? (
              <MediaTrackTab unitId={showProps.record.id} />
            ) : null}
          </Tab>
          <Tab label={"Tasks"}>
            {showProps.record ? (
              <TaskList unitId={showProps.record.id} />
            ) : null}
          </Tab>
          <Tab label={"Translations"}>
            {showProps.record ? (
              <TranslationList unitId={showProps.record.id} />
            ) : null}
          </Tab>
        </TabbedShowLayout>
      </ShowView>
    </InjectTopBottom>
  );
};
