import * as React from "react";
import {gql} from "@apollo/client";
import {useQuery, useMutation} from "@apollo/client";
import {FormControlLabel, Checkbox, Button} from "@material-ui/core";
import {InspectTranslation} from "./Inspect/InspectTranslation";

export function TranslationList(props: {unitId: string}) {
  const {data, error, loading} = useQuery(
    gql`
      query TranslationsQuery($id: ID!) {
        unit(id: $id) {
          id
          captionTrack {
            id
            latestVersion {
              id
              translations {
                id
                language
                numLines
                isReady
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        id: props.unitId,
      },
    }
  );

  const [approve, {data: mutationData}] = useMutation(gql`
    mutation SetTrackReady($transId: ID!, $isReady: Boolean!) {
      markCaptionTrackTranslationReady(
        trackTranslationId: $transId
        isReady: $isReady
      ) {
        captionTrackTranslation {
          id
          isReady
        }
      }
    }
  `);

  if (loading) {
    return null;
  }
  if (error) {
    return <>{JSON.stringify(error)}</>;
  }

  const translations = data.unit.captionTrack.latestVersion.translations;

  return (
    <div>
      <div>Showing translations for the latest version:</div>
      {translations.map((trans: any) => {
        return (
          <li data-id={trans.id} key={trans.id}>
            Language: {trans.language}, with {trans.numLines} lines.
            <Button>Inspect</Button>
            <FormControlLabel
              control={
                <Checkbox
                  checked={trans.isReady}
                  onChange={(e) => {
                    approve({
                      variables: {
                        isReady: e.target.checked,
                        transId: trans.id,
                      },
                    });
                  }}
                />
              }
              label="Approved & Ready"
            />
            <div style={{border: "1px solid silver", padding: "20px"}}>
              <InspectTranslation translationId={trans.id} />
            </div>
          </li>
        );
      })}
    </div>
  );
}
