export type MediaBackground = {
  url?: string,
  color?: string
}


/**
 * These are the media source types that we have hard-coded support for.
 */
export type MediaSourceType = 'youtube'|'url';


/**
 * This defines a media source we know how to play. If your type is not one of `MediaSourceType`, you'll
 * have to register a resolver.
 */
export type MediaSource = {
  lang: string,
  type: string,
  url: string,

  // Allows indicating there is no video track here
  audioOnly?: boolean,
  // Allows a custom background.
  background?: MediaBackground
};


const AUDIO_EXTENSIONS = /\.(m4a|mp4a|mpga|mp2|mp2a|mp3|m2a|m3a|wav|weba|aac|oga|spx)($|\?)/i

/**
 * This obviously requires the URLs to be somewhat clean - it is a guess.
 *
 * From react-player:FilePlayer.js.
 */
export function isAudioUrl(url: string) {
  return AUDIO_EXTENSIONS.test(url)
}


// For now, this is a global registry
type Resolver = (id: string) => Promise<{type: MediaSourceType, url: string} & Partial<Omit<MediaSource, 'type'|'url'>>>;
export const CUSTOM_MEDIA_SOURCE_TYPES: {[key: string]: Resolver} = {};


export function registerCustomMediaSourceType(type: string, resolver: Resolver) {
  CUSTOM_MEDIA_SOURCE_TYPES[type] = resolver;
}



