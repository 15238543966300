// This shows the "main" text of the unit.
import {VocableFormat} from "../../models/getVocableText";

export interface MainLine { type: 'main' };

// This shows a particular language translation for the unit.
export interface TranslationLineDef { type: 'translation', language: string };

// This shows particular vocable alternative sets, such as "diacritics-auto".
export interface AlternateLine {
  type: 'alternate',
  alternates: string[],
  // If not given, use the default language direction.
  direction?: 'rtl' | 'ltr',
  // If a word is missing, fall back to the word from the main subtitles?
  fallbackToMain?: boolean,
  // In case the vocable values need formatting, as when they contain "sound" instructions.
  formatter?: <D>(value: D | null | undefined) => string,
  prePostFormatter?: (value?: string) => string
};

export type DisplayLine = MainLine | TranslationLineDef | AlternateLine;


// Maybe we only want to use a single type in the future? Combine them somehow? Inherit from VocableFormat?
export function convertDisplayLineToVocableFormat(line: DisplayLine): VocableFormat {
  if (line.type === 'main') {
    return {};
  }
  else if (line.type === 'alternate') {
    return {
      useAlternates: line.alternates,
      direction: line.direction,
      altFormatter: line.formatter,
      prePostFormatter: line.prePostFormatter,
      fallbackToMain: line.fallbackToMain
    }
  }
  else {
    throw new Error("not supported")
  }
}