/**
 * Total cost field. Calculate the total cost based on minutes/words times rate.
 */

import React from "react";
import gqlm from "graphql-tag";
import {TotalCostOnTask} from "../types/TotalCostOnTask";

export const TotalCostOnTaskFragment = gqlm`
  fragment TotalCostOnTask on Task {
    price {
      amount,
      currency,
      perWord      
    },
    wordCount,
    reportedTime
  }
`;

const f = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"});

export function TaskTotalCost(props: {task: TotalCostOnTask}) {
  const {task} = props;

  if (!task.price) {
    return null;
  }
  const amount = parseInt(task.price.amount);

  let totalAmount;
  if (task.price.perWord) {
    if (!task.wordCount) {
      return null;
    }
    totalAmount = task.wordCount * amount;
  } else {
    if (!task.reportedTime) {
      return null;
    }
    totalAmount = (task.reportedTime / 60) * amount;
  }

  let currency = task.price.currency;

  if (currency === "IRR") {
    currency = "USD";
    // bonbast.ir
    totalAmount = totalAmount / 259500;
  }

  if (currency === "USD") {
    currency = "$";
  }

  return <div>{f.format(totalAmount)}</div>;
}
