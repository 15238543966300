import React from "react";
import {
  Pagination,
  Datagrid,
  TextField,
  ListView,
  DateField,
} from "react-admin";
import {gql as gqlm} from "@apollo/client";
import {useApolloListController} from "../../dataProvider/apolloControllers";
import {
  ContentIssueListQuery,
  ContentIssueListQuery_contentIssues_edges_node,
} from "../../types/ContentIssueListQuery";
import {getIntPaginationVars} from "../../dataProvider";
import {AssignmentField} from "./AssignmentField";
import {Button} from "@material-ui/core";
import {ContentIssueListActionButtons} from "./BulkActionButtons";

export const Query = gqlm`
  query ContentIssueListQuery(
    $opts: PageOpts
  ) {
    contentIssues(
      opts: $opts,
      isResolved: false
    ) {
      count,
      edges {
        node {
          id,
          tags,
          location {
            ... on ContentIssueLocationLine {
              lineIndex
            },
            ... on ContentIssueLocationNode {
              nodeId
            },
            ... on ContentIssueLocationVocable {
              vocableId
            }
          },
          description,
          createdAt,
          createdBy {
            ... on User {
              id,
              name
            }
            ... on Editor {
              id,
              name
            }
          }
          assignedTo {
            id,
            name
          },          
          unit {
            id,
            name
          }
        }
      }
    }
  }
`;

const ListPagination = (props: any) => (
  <Pagination rowsPerPageOptions={[50, 100]} {...props} />
);

export const ContentIssueList = (props: any) => {
  const controllerProps = useApolloListController<ContentIssueListQuery>({
    ...props,
    query: Query,
    getData: (data) => data.contentIssues.edges?.map((e) => e.node),
    getId: "id",
    getTotal: (data) => data.contentIssues.count,
    getVariables: (params) => {
      return {
        opts: getIntPaginationVars(params),
      };
    },
  });

  const selectedRecords = controllerProps.selectedIds
    .map((id) => controllerProps.data[id])
    .filter((x) => !!x); // Sometimes this happens when changing pages, selectedIds lags the data.

  return (
    <ListView
      {...props}
      {...controllerProps}
      pagination={<ListPagination />}
      bulkActionButtons={
        <ContentIssueListActionButtons selectedRecords={selectedRecords} />
      }
    >
      <Datagrid rowClick="show">
        <TextField source="unit.name" label={"Unit"} />
        <LocationField source="location" />
        <TextField source="description" />
        <TagField label={"Tags"} />
        <AssignmentField label={"Assignee"} />
        <TextField source="createdBy.name" />
        <DateField source={"createdAt"} />
        <ActionButton />
      </Datagrid>
    </ListView>
  );
};

export const TagField = (props: {
  label?: string;
  record?: ContentIssueListQuery_contentIssues_edges_node;
}) => {
  const record = props.record!;

  return (
    <div>
      {record.tags.map((tag) => {
        return (
          <span
            style={{
              padding: "5px",
              margin: "2px",
              background: "red",
              color: "white",
            }}
            key={tag}
          >
            {tag}
          </span>
        );
      })}
    </div>
  );
};

export const LocationField = (props: {
  source: string;
  record?: ContentIssueListQuery_contentIssues_edges_node;
}) => {
  const record = props.record!;

  let text: string = "";
  if (record.location.__typename == "ContentIssueLocationLine") {
    text = `line ${record.location.lineIndex}`;
  }
  if (record.location.__typename == "ContentIssueLocationVocable") {
    text = `vocable ${record.location.vocableId}`;
  }
  if (record.location.__typename == "ContentIssueLocationNode") {
    text = `node ${record.location.nodeId}`;
  }
  return <div>{text}</div>;
};

function ActionButton(props: {
  record?: ContentIssueListQuery_contentIssues_edges_node;
}) {
  return (
    <Button
      component={"a"}
      href={`https://editor.languagetool.xyz/#/issues/${props.record!.id}`}
      target={"_blank"}
      onClick={(e: any) => {
        e.stopPropagation();
      }}
      variant="contained"
    >
      Open
    </Button>
  );
}
