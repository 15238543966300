import React, {Fragment, useState, useCallback} from "react";
import {Button} from "@material-ui/core";
import {TagSelector} from "languagetool-editor/src/js/integrations/Market/ContentIssuesScreen/TagSelector";

export function ContentIssueListActionButtons(props: any) {
  return (
    <Fragment>
      <ChangeTagsButton {...props} />
    </Fragment>
  );
}

function ChangeTagsButton(props: {
  selectedIds: string[];
  selectedRecords: any;
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Change Tags
      </Button>
      <TagSelector
        anchorEl={anchorEl}
        onRequestClose={handleClose}
        selectedRecords={props.selectedRecords}
      />
    </>
  );
}
