import {DisplayLine} from "./types";
import {Vocable} from "../../models";
import {useCaptionTrackContext, useUIState} from "../CaptionTrack/Manager";
import {useCurrentLineIndex} from "./useCurrentLineIndex";
import React, {useCallback, ReactElement} from "react";
import {ClickableSubtitleLine, TranslationLine} from "./Lines";
import { Platform } from "react-native";


export function isEmpty(time: number | null | undefined | false): time is Exclude<typeof time, number> {
  return time === null || time === undefined || time === false;
}


export type RenderedLineState = {
  lines: {rendered: ReactElement}[]
}


/**
 * Will give you the ability to render the current subtitle lines in one way or another.
 */
export function useSubtitleLines(props: {
  // It is possible to display multiple lines!
  lines: DisplayLine[],

  onElementClick?: (word: Vocable, target: any) => void,
  getLineStyle?: (lineDef: DisplayLine, opts: {
    fullSize?: boolean
  }) => unknown,

  isFullSize?: boolean
}): RenderedLineState {
  const uiState = useUIState();
  const {unit, captions, defaultLanguage: language, popupAPI} = useCaptionTrackContext();

  // We want to force a particular being shown whenever the playback logic says "this is the line we
  // are dealing with right now. Playback may actually have stopped slightly afterwards.
  const forceLineIndex = uiState.lockState.isLocked
    ? uiState.lockState.lineIndex
    : uiState.isAutoPaused ?
        uiState.currentPosition?.lineIndex
        : undefined;

  const currentLineIndex = useCurrentLineIndex();

  if (!captions || !language || !unit) {
    throw new Error("PlayerContext does not have language or captions set.")
  }

  const getNodeId = useCallback((el: Vocable) => {
    if (!popupAPI) {
      return null;
    }
    return popupAPI.getUniqueIdFor(el);
  }, [popupAPI]);

  const getNodeClickable = useCallback((el: Vocable) => {
    if (!popupAPI) {
      return false;
    }
    return popupAPI.isClickable(el);
  }, [popupAPI]);

  const handleClick = useCallback((e: any, element: Vocable) => {
    // All react events are really from a single handler installed at the document level.
    // The RootClose helper also registers an event at the document level.
    // We thus want to make sure that no sibling events will run. What this will enable is to be able to
    // select another word in the subtitles while the popup is still open. Without this, the "RootClose" handler
    // would act immediately after us here, closing the popup again, which from the user perspective looks like
    // they first have to close the popup via a backdrop-click before they can open another one.
    if (Platform.OS === 'web') {
      e.nativeEvent?.stopImmediatePropagation();
    }

    if (props.onElementClick) {
      props.onElementClick(element, e.target);
    } else if (popupAPI) {
      popupAPI.showFor(element, e.target);
    }
  }, [props.onElementClick, popupAPI]);

  let lineIndexToRender = !isEmpty(forceLineIndex)
    ? forceLineIndex
    : currentLineIndex;

  let firstTranslationLine = false;
  const lines = props.lines.map((lineDef, idx) => {
    if (isEmpty(lineIndexToRender)) {
      return null;
    }

    const lineStyle = props.getLineStyle?.(lineDef, {fullSize: props.isFullSize});

    if (lineDef.type === 'main') {
      return <ClickableSubtitleLine
        key={idx}
        lineGroup={captions.data.lines[lineIndexToRender]}
        clickable={true}
        language={language}
        onElementClick={handleClick}
        getElementNodeId={getNodeId}
        getElementClickable={getNodeClickable}
        style={lineStyle}
      />;
    }

    if (lineDef.type === 'alternate') {
      const forceLtr = lineDef.direction === 'ltr';
      return <ClickableSubtitleLine
        key={idx}
        lineGroup={captions!.data.lines[lineIndexToRender]}
        clickable={true}
        language={forceLtr ? 'en' : language}
        onElementClick={handleClick}
        getElementNodeId={getNodeId}
        getElementClickable={getNodeClickable}
        useAlternates={lineDef.alternates}
        fallbackToMain={lineDef.fallbackToMain}
        altFormatter={lineDef.formatter}
        prePostFormatter={lineDef.prePostFormatter}
        style={lineStyle}
      />;
    }

    if (lineDef.type === 'translation') {
      const translation = unit.getTranslation(lineDef.language);
      if (!translation) {
        return;
      }

      // This is kind of a hack, but because we do not want to support the use-case of putting the translation
      // lines anywhere but at the bottom, we can have a fixed logic where we say: before the first translation
      // line, we put some extra padding.
      let isFirst = false;
      if (!firstTranslationLine && idx > 0) {
        firstTranslationLine = true;
        isFirst = true;
      }

      return <TranslationLine
        key={idx}
        showLanguage={true}
        line={translation.data[lineIndexToRender]}
        language={translation.lang}
        style={lineStyle}
      />
    }
  });

  return {
    lines: lines.map(line => ({rendered: line!}))
  }
}