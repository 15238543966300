import {Button} from "@material-ui/core";
import * as React from "react";

export function OpenTranslationButton(props: {taskId: string; size?: any}) {
  return (
    <Button
      component={"a"}
      href={`https://editor.languagetool.xyz/#/translation/task/${props.taskId}`}
      target={"_blank"}
      onClick={(e: any) => {
        e.stopPropagation();
      }}
      size={props.size}
      variant="contained"
    >
      Translation
    </Button>
  );
}
