import {QueryResult} from "@apollo/client";
import {useMemo} from "react";

export function useRewriteQueryData<F, D = any>(
  query: QueryResult<D>,
  rewriter: (data: D) => F
): QueryResult<F> {
  return useMemo(() => {
    if (!query.data) {
      return query as QueryResult;
    }

    return {
      ...query,
      data: rewriter(query.data!),
    } as QueryResult;
  }, [query]);
}
