import * as React from "react";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    height: 48,
  },

  box: (props: any) => ({
    padding: ".5em",
    border: "1px solid transparent",
    color: "white",
    backgroundClip: "padding-box",
    flex: 1,
    textAlign: "center",
    flexShrink: 0,
  }),
});

export const StatusBox = (props: {
  inProgress?: boolean;
  warning?: boolean;
  complete: boolean;

  title?: string;
  children: any;
}) => {
  let status: "progress" | "complete" | "warning" | "incomplete";

  if (props.inProgress) {
    status = "progress";
  } else if (props.complete) {
    status = "complete";
  } else if (props.warning) {
    status = "warning";
  } else {
    status = "incomplete";
  }

  return (
    <StatusBoxNew
      title={props.title}
      children={props.children}
      status={status}
    />
  );
};

export const StatusBoxNew = (props: {
  status: "progress" | "complete" | "warning" | "incomplete";

  title?: string;
  children: any;
}) => {
  const classes = useStyles(props);

  let color = "white";
  let bgColor = "";

  if (props.status == "progress") {
    bgColor = "#FFEB3B";
    color = "#222222";
  } else if (props.status == "complete") {
    bgColor = "#4CAF50";
  } else if (props.status == "warning") {
    bgColor = "orange";
  } else if (props.status == "incomplete") {
    bgColor = "#f44336";
  }

  return (
    <div
      className={classes.box}
      title={props.title}
      style={{
        backgroundColor: bgColor,
        color,
        //fontWeight: props.exists ? 'bold' : null
      }}
    >
      {props.children}
    </div>
  );
};

export const StatusField = (props: any) => {
  const classes = useStyles();

  return <div className={classes.root}>{props.children}</div>;
};
