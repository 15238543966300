import {NoopController, UIControllerAPI, UIState, useMakeUIController} from "../UIController";
import {PlayerAPI, PlayerState} from "../Video";
import React, {useCallback, useImperativeHandle, useMemo, useReducer, useEffect, useRef} from "react";
import Bacon from 'baconjs';
import {Captions, Unit, Vocable} from "../../models";
import {PopupAPI, PopupState} from "../PlayerContext/Popup";
import {IAnnotationLoader} from "../../annotations/loader";
import {Chapters} from "../../models/Chapters";
import {useMakeCurrentLineIndexProperty} from "../Subtitles/useCurrentLineIndex";
import {PlayerContext, usePlayerContext} from "../PlayerContext/context";
import {DefaultState, IPlayerContext} from "../PlayerContext/Interface";
import {VocableId} from "../../models/formats/CaptionTrack";
import {StructureNode} from "../../models/Node";
import {NodeResolver} from "../WordPopup/PopupContext";
import loglevel from 'loglevel';
import {useMakeCurrentVocableIdsProperty} from "../Subtitles/useCurrentVocableIds";

const log = loglevel.getLogger("CaptionTrackContext")
log.setLevel("DEBUG")


// This is the state which we hold.
type State = {
  playerState?: null|PlayerState,
  playerAPI?: PlayerAPI;

  popupState: PopupState,
  popupAPI?: PopupAPI
}


// The full context exposed is the state + helpers to change it.
export interface ICaptionTrackContext extends State, IPlayerContext {
  uiState: UIState,
  uiController?: UIControllerAPI,

  // The player and popup components use this to publish it's state.
  setPlayerState: (newState: PlayerState) => void,
  // The player and popup publish their APIs through this once they are mounted.
  setPlayerAPI: (api: PlayerAPI|null) => void,

  currentLineIndex$: Bacon.Property<any, null|number>|null,
  currentVocableIds$: Bacon.Property<any, null|string[]>|null,

  // Needed by the WordPopup to access the word nodes, the UI controller to allow jumping between
  // the lines.
  captions?: Captions,
  chapters?: Chapters,

  // EXISTS for getTranslation, we might add this directly so to make it more generic
  unit?: Unit,

  // By components that want to load annotations will use this, such as the WordPopup.
  annotationsLoader?: IAnnotationLoader,
}

export function useCaptionTrackContext() {
  return usePlayerContext() as ICaptionTrackContext;
}


export const DefaultPlayerState: PlayerState = {
  isPlaying: false,
  isReady: false,
  playbackRate: 1,
  duration: 0
};

export function usePlayerState(): PlayerState {
  const context = useCaptionTrackContext();
  return context.playerState || DefaultPlayerState;
}

export function usePlayerAPI(): PlayerAPI|null {
  const context = useCaptionTrackContext();
  return context.playerAPI || null;
}


export const NoopPlayerAPI: PlayerAPI = {
  getState: function () { return DefaultPlayerState;},
  load$: Bacon.never<any, number>().toProperty(0),
  time$: Bacon.never<any, number>().toProperty(0),
  setPlaybackRate: function (p1: number) {},
  getCurrentTime(): number {
    return 0;
  },
  pause(): void {},
  play(): void {},
  async seekTo(time: number): Promise<void> {}
}

export function useSafePlayerAPI(): PlayerAPI {
  const context = useCaptionTrackContext();
  return context.playerAPI || NoopPlayerAPI;
}

export function useUIController(): UIControllerAPI {
  const ctx = useCaptionTrackContext();
  return ctx.uiController || NoopController;
}

export function useUIState() {
  const context = useCaptionTrackContext();
  return context.uiState;
}

export function usePopupState() {
  const context = useCaptionTrackContext();
  return context.popupState;
}


function reducer(state: State, action: any): State {
  switch (action.type) {
    case 'setPlayerAPI':
      return {...state, playerAPI: action.api};
    case 'setPlayerState':
      return {...state, playerState: action.state};
    case 'setPopupAPI':
      return {...state, popupAPI: action.api};
    case 'setPopupState':
      return {...state, popupState: action.state};

    default:
      throw new Error();
  }
}


function useNodeResolver(captions: Captions|undefined) {
  return useMemo<NodeResolver|undefined>(() => {
    if (!captions) {
      return;
    }
    return {
      resolveVocable: (vocableId: VocableId): Vocable => {
        const result = captions.getVocable(vocableId);
        if (!result) {
          throw new Error("Must not happen");
        }
        return result;
      },
      getNodesForVocable: (vocableId: VocableId) => {
        return captions.getNodesForVocable(vocableId);
      },
      resolveNode(nodeId: string): StructureNode {
        throw new Error("not implemented");
      }
    }
  }, [captions]);
}


function useAutoStopPlayOnPopup(props: {
  api: PopupAPI|undefined,
  playerAPI: PlayerAPI|undefined,
  playerState: PlayerState|undefined|null
}) {
  const stoppedBecauseOfPopupOpen = useRef(false);
  const {playerAPI, playerState, api} = props;

  const handleClose = useCallback(() => {
    log.info('useAutoStopPlayOnPopup(): close event received')
    // true = reason: stopped due to popup
    if (!playerState!.isPlaying && stoppedBecauseOfPopupOpen.current) {
      playerAPI!.play();
    }
    stoppedBecauseOfPopupOpen.current = false;
  }, [stoppedBecauseOfPopupOpen, playerState, playerAPI]);

  const Config = {
    'pauseOnWordPopup': true
  }

  const handleOpen = useCallback(() => {
    log.info('useAutoStopPlayOnPopup(): open event received')

    // In some cases, we now want to pause playback.
    // TODO: Could also be moved to the UI Controller, might make the code simpler.
    if (Config.pauseOnWordPopup && playerState && playerState.isPlaying) {
      stoppedBecauseOfPopupOpen.current = true;
      playerAPI!.pause();
    }
    // If a user is looking up multiple words in a row, disable the auto-play after popup close (TODO? Really?)
  }, [playerAPI, playerState, playerAPI]);

  useEffect(() => {
    if (!api) {
      return;
    }

    api.on('open', handleOpen);
    api.on('close', handleClose);

    return () => {
      api.off('open', handleOpen);
      api.off('close', handleClose);
    };
  }, [api?.on, api?.off,handleOpen, handleClose])
}


/**
 * Connects all the state of a player UI. The idea is that you can put this
 * at the root, put the UI components as you wish and do not have to worry
 * about passing props everywhere. This makes it very easy to build custom
 * player UIs.
 */
export function PlayerManager(props: {
  unit?: Unit,
  language?: string,
  annotationsLoader?: IAnnotationLoader,

  controlRef?: ((r: ICaptionTrackContext|undefined) => void),
  children?: any,
}) {
  const [state, dispatch] = useReducer(reducer, DefaultState);

  const unit = props.unit;
  const captions = props.unit ? props.unit.getCaptions(props.language)! : undefined;

  const chapters = useMemo(() => {
    if (!captions || !state.playerState || !state.playerState.duration) {
      return;
    }
    return new Chapters(captions, state.playerState.duration);
  }, [captions, state.playerState ? state.playerState.duration : null]);

  const setPopupState = useCallback((state: PopupState|null) => {
    dispatch({type: 'setPopupState', state: state})
  }, [dispatch]);
  const setPopupAPI = useCallback((api: PopupAPI|null) => {
    dispatch({type: 'setPopupAPI', api: api})
  }, [dispatch]);
  const setPlayerAPI = useCallback((api: PlayerAPI|null) => {
    dispatch({type: 'setPlayerAPI', api: api})
  }, [dispatch]);

  const currentLineIndex$ = useMakeCurrentLineIndexProperty(state.playerAPI, captions);
  const currentVocableIds$ = useMakeCurrentVocableIdsProperty(state.playerAPI, captions);

  const resolver = useNodeResolver(captions);

  useAutoStopPlayOnPopup({
    api: state.popupAPI,
    playerAPI: state.playerAPI,
    playerState: state.playerState
  });

  const {state: uiState, controller: uiController, playerAPI: wrappedPlayerAPI} = useMakeUIController({
    captions,
    playerAPI: state.playerAPI
  });

  const value: ICaptionTrackContext = useMemo(() => ({
    ...state,
    playerAPI: wrappedPlayerAPI,
    uiController,
    uiState,

    resolver,
    unit: unit,
    captions: captions,
    chapters,
    defaultLanguage: props.language,
    annotationsLoader: props.annotationsLoader,
    currentLineIndex$,
    currentVocableIds$,

    setPlayerAPI,
    setPlayerState: (state: PlayerState) => {
      dispatch({type: 'setPlayerState', state: state})
    },
    setPopupState,
    setPopupAPI,
  }), [dispatch, state, unit, captions, props.language, setPopupState, uiController, uiState]);

  useImperativeHandle(props.controlRef, () => value);

  return <PlayerContext.Provider value={value}>
    {props.children}
  </PlayerContext.Provider>
}