import React, {useCallback} from "react";
import {
  List,
  ListItem,
  ListItemText,
  Paper,
  InputBase,
  IconButton,
} from "@material-ui/core";
import {Search} from "@material-ui/icons";
import Downshift from "downshift";
import {QueryResult} from "@apollo/client";

const LOADING = {};

/**
 * Supports multiple steps!
 *
 * TODO:
 *   - Does not support the search bar yet.
 *   - Does not support the "go back" button to go back from season to series.
 */
export function GenericFilterDropdown<D, F extends {name: string}>(props: {
  items: F[];
  loading: boolean;
  selectedItem: D | null;
  onSelectedItemChange: (item: null | D) => void;
}) {
  const {selectedItem, onSelectedItemChange, items, loading} = props;

  const itemsToRender: F[] = loading ? [LOADING as any] : items;

  const handleChange = useCallback(
    (item) => {
      if (item === "back") {
        onSelectedItemChange(null);
      } else onSelectedItemChange(item);
    },
    [onSelectedItemChange]
  );

  return (
    <Downshift
      itemToString={(series) => (series ? series.name : null)}
      onChange={handleChange}
    >
      {(downshift) => {
        const {
          selectedItem,
          getToggleButtonProps,
          getLabelProps,
          getMenuProps,
          getInputProps,
          highlightedIndex,
          getItemProps,
        } = downshift;

        return (
          <div>
            <Paper>
              <InputBase
                placeholder="Search"
                inputProps={{
                  style: {
                    padding: "0 10px",
                  },
                  ...getInputProps(),
                }}
              />
              <IconButton>
                <Search />
              </IconButton>
            </Paper>

            <List {...getMenuProps()}>
              {itemsToRender.map((item, idx) => {
                if (item === LOADING) {
                  return (
                    <ListItem key={"loading"}>
                      <ListItemText primary={"Loading..."} />
                    </ListItem>
                  );
                }

                const isHighlighted = idx === highlightedIndex;
                return (
                  <ListItem
                    key={idx}
                    button
                    {...getItemProps({item: item, index: idx})}
                    style={{
                      background: isHighlighted ? "silver" : undefined,
                    }}
                  >
                    <ListItemText primary={item.name || "Default"} />
                  </ListItem>
                );
              })}
            </List>
          </div>
        );
      }}
    </Downshift>
  );
}
