import {gql} from "@apollo/client";
import {ApolloClient, InMemoryCache} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {createUploadLink} from 'apollo-upload-client';
// https://www.apollographql.com/docs/react/advanced/fragments/#fragment-matcher
import introspectionQueryResultData from './fragmentTypes.json';
import {TotalCostOnTaskFragment} from "../components/TaskTotalCost";
import {unitFragment} from "../ui/UnitList";


export class DataProvider {
  constructor(url, authKey) {
    // apollo-link-context is the right thing to have an async call to prepare the headers. See  more information
    // here on how I deduced this and how the links work:
    // https://www.apollographql.com/docs/link/overview/
    // https://github.com/apollographql/apollo-link/blob/c32e170b72ae1a94cea1c633f977d2dbfcada0e1/packages/apollo-link-http/src/httpLink.ts#L84
    // https://github.com/apollographql/apollo-client/issues/2441
    const httpLink = createUploadLink({uri: url});

    // This will resolve the auth token dynamically, then inject it.
    const injectAuthLink = setContext(async (request, {headers}) => {
      let authHeader;
      try {
        authHeader = await authKey();
      } catch (e) {
        console.log(e);
        authHeader = "";
      }

      return { headers: {
          'Authorization': `${authHeader}`,
          ...headers
        }};
    });

    // So then this is our full link: first auth header inject, then http.
    const link = injectAuthLink.concat(httpLink);

    this.client = new ApolloClient({
      link,
      cache: new InMemoryCache({
        possibleTypes: {
          Item: ["Unit", "Series"],
          Task: ["CaptioningTask", "GrammarTask", "TranslationTask"],
        }
      })
    });
  }

  reactAdmin = async (type, resource, params) => {
    try {
      if (resource === 'units') {
        return await handleUnits(this.client, type, params);
      }

      if (resource === 'tasks') {
        return await handleTasks(this.client, type, params);
      }

      if (resource === 'editors') {
        return await handleEditors(this.client, type, params);
      }

      raiseNotImplemented();
    }
    catch(e) {
      // react-admin often eats exceptions
      console.error(e);
      throw e;
    }
  }
}


const taskFragment = gql`
  fragment TaskProperties on Task {
    id,
    kind,
    description,
    assignedEditor { id },
    price {
      amount,
      currency,
      perWord
    },
    createdAt,
    reportedTime,
    status,
    statusChangedAt,
    
    wordCount,
    runtimeSeconds,

    unit { id }
    
    ...TotalCostOnTask
  }
  ${TotalCostOnTaskFragment}
`;

async function handleTasks(client, type, params) {
  if (type === 'GET_LIST') {
    const r = await client.query({
        query: gql`
          ${taskFragment}
          query(
            $status: TaskStatus, $first: Int, $after: Int, $assignedEditorId: Int, $types: [String!]
          ) {
            tasks(status: $status, page: {first: $first, after: $after}, hideCompleteDelayed: false,
                  assignedEditorId: $assignedEditorId, types: $types) {
              count,
              edges {
                node {
                  ...TaskProperties
                }
              }                    
            }
        }`,
      variables: {
        status: params.filter.status,
        assignedEditorId: params.filter.assignedEditor ? parseInt(params.filter.assignedEditor.id) : null,
        types: params.filter.kind ? [params.filter.kind] : null,
        ...getPaginationVarsInt(params)
      }
    });

    const nodes = (r.data?.tasks?.edges ?? []).map(e => e.node);
    return {
      data: nodes,
      total: r.data?.tasks?.count ?? 0
    }
  }

  else if (type === 'GET_ONE') {
    const r = await client.query({
      query: gql`
        ${taskFragment}
        query($id: ID!) {
          task(id: $id) {        
            ...TaskProperties
          }
        }`,
      variables: {
        id: params.id
      }
    });

    return {data: r.data.task};
    // return {
    //   data: nodes,
    //   total: nodes.length
    // }
  }

  else if (type === 'UPDATE' || type === 'CREATE') {
    const r = await client.mutate({
      mutation: gql`
        ${taskFragment}
        mutation($id: ID, $props: TaskProps!, $unitId: ID, $kind: TaskType) {
          applyTask(id: $id, props: $props, unitId: $unitId, kind: $kind) {
            task {
              ...TaskProperties
            }
          }
        }`,
      variables: {
        id: params.id,
        kind: params.data.kind,
        props: {
          status: params.data.status,
          reportedTime: params.data.reportedTime,
          description: params.data.description,
          assignedEditorId: params.data.assignedEditor ? parseInt(params.data.assignedEditor.id) : null
        },
        unitId: null
      }
    });

    return {data: r.data.applyTask.task};
  }

  throw new Error("Not implemented: " + type);
}

export function getDoneFilter(params, field, doneArg, taskArg) {
  if (!params.filter) {
    return {};
  }

  if (params.filter[field] === 'ready') {
    return {
      [doneArg]: true,
    };
  }
  if (params.filter[field] === 'not-ready-but-task') {
    return {
      [doneArg]: false,
      [taskArg]: true,
    };
  }
  if (params.filter[field] === 'not-ready') {
    if (taskArg) {
      return {
        [doneArg]: false,
        [taskArg]: false,
      };
    } else {
      return {
        [doneArg]: false
      };
    }
  }
  if (params.filter[field] === 'has-task') {
    return {
      [taskArg]: true,
    };
  }
  if (params.filter[field] === 'no-task') {
    return {
      [taskArg]: false,
    };
  }
}

async function handleUnits(client, type, params) {
  if (type === 'GET_LIST') {
    const r = await client.query({
      query: gql`
        ${unitFragment}
        
        query(
          $first: Int, $after: String, 
          $translationDone: Boolean,
          $captionsDone: Boolean,
          $grammarDone: Boolean,
          $mediaDone: Boolean,
          $hasCaptionsTask: Boolean,
          $hasTranslationTask: Boolean,
          $hasGrammarTask: Boolean,
          $belongsToSite: String,
          $belongsToAnySite: Boolean,
        ) {
          units(
            first: $first, after: $after, 
            translationDone: $translationDone, 
            captionsDone: $captionsDone,
            mediaDone: $mediaDone,
            grammarDone: $grammarDone,
            hasCaptionsTask: $hasCaptionsTask,
            hasTranslationTask: $hasTranslationTask,
            hasGrammarTask: $hasGrammarTask,
            belongsToAnySite: $belongsToAnySite,
            belongsToSite: $belongsToSite,
          ) {
            count,
            edges {
              node {
                ...UnitProperties
              }
            }        
          }
      }`,
      variables: {

      }
    });

    const nodes = r.data.units.edges.map(e => e.node);
    return {
      data: nodes,
      total: r.data.units.count
    }
  }

  else if (type === 'GET_ONE') {
    const r = await client.query({
      query: gql`
        ${unitFragment}
      
        query($id: ID!) {
          unit(id: $id) {        
            ...UnitProperties
          }
        }`,
      variables: {
        id: params.id
      }
    });

    return {data: r.data.unit};
  }

  else if (type === 'GET_MANY') {
    const r = await client.query({
      query: gql`
        ${unitFragment}
        
        query($ids: [ID]!) {
          units(ids: $ids) {        
            edges {
              node {
                ...UnitProperties
              }
            }                
          }
        }`,
      variables: {
        ids: params.ids
      }
    });

    const nodes = r.data.units.edges.map(e => e.node);
    return {
      data: nodes
    }
  }

  else if (type === 'DELETE') {
    const r = await client.mutate({
      mutation: gql`      
        mutation deleteUnit($id: ID!) {
          deleteUnit(unitId: $id) {        
            deletedId
          }
        }`,
      variables: {
        id: params.id
      }
    });

    return {data: {"foo": 1}};
  }

  raiseNotImplemented();
}


async function handleEditors(client, type, params) {
  if (type === 'GET_LIST') {
    const r = await client.query({
      query: gql`{
        editors {
          id,
          name                  
        }
    }`
    });

    const nodes = r.data.editors;
    return {
      data: nodes,
      total: nodes.length
    }
  }

  else if (type === 'GET_ONE') {
    const r = await client.query({
      query: gql`
        query($ids: [Int!]!) {
          editors(ids: $ids) {
            id,
            name
          }
        }`,
      variables: {
        ids: params.ids.filter(x => !!x).map(parseInt)
      }
    });

    return {data: r.data.editors[0]};
    // return {
    //   data: nodes,
    //   total: nodes.length
    // }
  }

  else if (type === 'GET_MANY') {
    const r = await client.query({
      query: gql`
        query($ids: [Int!]!) {
          editors(ids: $ids) {
            id,
            name
          }
        }`,
      variables: {
        ids: params.ids.filter(x => !!x).map(parseInt).filter(x => !!x)
      }
    });

    const nodes = r.data.editors;
    return {
      data: nodes
    }
  }

  throw new Error("Not implemented");
}


// params - as given to the dataProvider by
export function getPaginationVars(params) {
  return {
    after: (((params.pagination.page - 1) * params.pagination.perPage) - 1) + "",
    first: params.pagination.perPage
  }
}

export function getPaginationVarsInt(params) {
  return {
    after: (((params.pagination.page - 1) * params.pagination.perPage) - 1),
    first: params.pagination.perPage
  }
}

// this is the main one we are using now...
export function getPaginationVars2(params) {
  return {
    after: (((params.page - 1) * params.perPage) - 1) + "",
    first: params.perPage
  }
}

// This is for when the server expects all values to be ints.
export function getIntPaginationVars(params) {
  return {
    after: (((params.page - 1) * params.perPage) - 1),
    first: params.perPage
  }
}


function raiseNotImplemented() {
  console.error("Not Implemented"); // For cases where we react-admin hides promise exceptions.
  throw new Error("Not implemented");
}