import React, {useCallback, useState} from "react";
import {useParams} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {UnitForm} from "./UnitForm";
import {FormControlLabel, Checkbox} from "@material-ui/core";

export type ContentMediaTrack = {
  type: string;
  url: string;
  lang: string;
};

export type ContentUnit = {
  id: string;
  name: string;
  description: string;
  difficulty: number | null;
  metadata: {
    youtube?: string;
    aparat?: string;
  };
  mediaTrack: ContentMediaTrack;
};

type ContentAttachedSeries = {
  units: ContentUnit[];
  series: {
    id: string;
  };
};

function useTask(taskId?: string) {
  const {loading, data, error} = useQuery<{
    task: {
      id: string;
      status: string;
      units: ContentUnit[];
      series: ContentAttachedSeries[];
    };
  }>(
    gql`
      fragment unitData on Unit {
        id
        name
        description
        difficulty
        metadata {
          youtube
          aparat
        }
        mediaTrack {
          language
          type
          url
        }
      }

      query ContentTaskQuery($id: ID!) {
        task(id: $id) {
          id
          status
          ... on ContentTask {
            units {
              ...unitData
            }

            series {
              series {
                id
              }
              units {
                ...unitData
              }
            }
          }
        }
      }
    `,
    {
      skip: !taskId,
      variables: {
        id: taskId,
      },
    }
  );

  return {loading, data, error};
}

export function useSetTaskStatus(taskId: string) {
  const [mutation, state] = useMutation<any>(
    gql`
      mutation UseSetTaskStatus($taskId: ID!, $status: TaskStatus!) {
        setTaskStatus(taskId: $taskId, status: $status) {
          task {
            id
            status
          }
        }
      }
    `,
    {
      variables: {
        taskId,
      },
    }
  );
  return mutation;
}

export function ContentTaskView(props: {}) {
  const {id} = useParams();
  const taskQuery = useTask(id);
  const setTaskStatus = useSetTaskStatus(id!);
  const [toPlay, setToPlay] = useState<ContentMediaTrack | null>(null);

  const handlePlay = useCallback((track: ContentMediaTrack) => {
    setToPlay(track);
  }, []);

  if (taskQuery.loading) {
    return <div>...</div>;
  }
  if (taskQuery.error) {
    return <div>{JSON.stringify(taskQuery.error)}</div>;
  }
  if (!taskQuery.data || !taskQuery.data.task) {
    return <div>This task was not found.</div>;
  }

  return (
    <div style={{padding: "20px"}}>
      <h2
        style={{
          margin: "0 0 30px",
          borderBottom: "1px solid black",
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Task
        <FormControlLabel
          control={
            <Checkbox
              checked={taskQuery.data.task.status == "review"}
              onChange={(e) => {
                setTaskStatus({
                  variables: {
                    status: e.target.checked ? "review" : "pending",
                  },
                }).catch((e: any) => {
                  alert("Failed to change task status.");
                });
              }}
            />
          }
          label="Ready for Review"
        />
      </h2>

      {taskQuery.data.task.units.map((unit) => {
        return <UnitForm key={unit.id} unit={unit} onPlay={handlePlay} />;
      })}

      {taskQuery.data.task.series.map((series) => {
        return series.units.map((unit) => {
          return (
            <UnitForm
              key={`${series.series.id}-${unit.id}`}
              unit={unit}
              onPlay={handlePlay}
            />
          );
        });
      })}

      {/*<PlayWindow*/}
      {/*  isOpen={!!toPlay}*/}
      {/*  mediaTrack={toPlay}*/}
      {/*  onRequestClose={() => setToPlay(null)}*/}
      {/*/>*/}
    </div>
  );
}
