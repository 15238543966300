import React from "react";
import {useQuery} from "@apollo/client";
import {gql as gqlm} from "@apollo/client";
import {TranslationQuery} from "../../../types/TranslationQuery";
import {useMemo} from "react";
import {
  SimplePictureBook,
  SimplePictureBookTranslation,
  TextPart,
} from "languagetool-player/src/models/formats/SimplePictureBook";
import {Vocable} from "languagetool-player/src/models";

export function InspectTranslation(props: {translationId: string}) {
  const {data, error, loading} = useQuery<TranslationQuery>(
    gqlm`
      query TranslationQuery($id: ID!) {
        translation(id: $id) {
          id
          data,
          captionTrackVersion {
            id,
            data
          }
        }
      }
    `,
    {
      variables: {
        id: props.translationId,
      },
    }
  );

  const parsedTranslation: SimplePictureBookTranslation | null = useMemo(
    () =>
      data?.translation?.data ? JSON.parse(data?.translation?.data) : null,
    [data]
  );
  const parsedTextTrack: SimplePictureBook | null = useMemo(
    () => JSON.parse(data?.translation?.captionTrackVersion?.data ?? "null"),
    [data]
  );

  if (loading) {
    return <>Loading...</>;
  }
  if (error) {
    return <>{JSON.stringify(error)}</>;
  }
  if (!data?.translation) {
    return <>Translation not found.</>;
  }

  return (
    <div>
      <div>translation id: {props.translationId}</div>
      <div>
        # blobs in translation:{" "}
        {Object.keys(parsedTranslation?.blobs ?? {}).length}
      </div>

      {parsedTextTrack?.pages?.map((page) => {
        return (
          <Li>
            Page {page.id}
            {(page.parts.text as TextPart).paragraphs.map((para) => {
              return (
                <Li>
                  Para {para.id}
                  {para.blobs.map((blob) => {
                    return (
                      <Li>
                        <strong>Blob {blob.id}</strong>
                        <div>Text: {formatLine(blob.elements)}</div>
                        <div>
                          Translation: {parsedTranslation?.blobs[blob.id]}
                        </div>
                      </Li>
                    );
                  })}
                </Li>
              );
            })}
          </Li>
        );
      })}
    </div>
  );
}

function Li(props: {children: any}) {
  return (
    <div
      style={{
        padding: "10px",
        marginLeft: "15px",
        border: "1px solid silver",
      }}
    >
      {props.children}
    </div>
  );
}

function formatLine(elements: Vocable[]) {
  const result: string[] = [];
  for (const element of elements) {
    result.push(element.pre ?? "");
    result.push(element.text);
    result.push(element.post ?? "");
  }
  return result.join("");
}
