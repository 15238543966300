import React, {useCallback} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import {fade} from "@material-ui/core/styles/colorManipulator";
import ActionCheck from "@material-ui/icons/CheckCircle";
import AlertError from "@material-ui/icons/ErrorOutline";
import classnames from "classnames";
import {useTranslate} from "ra-core";

const useStyles = makeStyles((theme) => ({
  contentText: {
    minWidth: 400,
  },
  confirmPrimary: {
    color: theme.palette.primary.main,
  },
  confirmWarning: {
    color: theme.palette.error.main,
    "&:hover": {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  iconPaddingStyle: {
    paddingRight: "0.5em",
  },
}));

/**
 * Confirmation dialog
 *
 * @example
 * <Confirm
 *     isOpen={true}
 *     title="Delete Item"
 *     content="Are you sure you want to delete this item?"
 *     confirm="Yes"
 *     confirmColor="primary"
 *     cancel="Cancel"
 *     onConfirm={() => { // do something }}
 *     onClose={() => { // do something }}
 * />
 */
const Confirm = ({
  isOpen,
  loading,
  title,
  content,
  confirm,
  cancel,
  confirmColor,
  onClose,
  onConfirm,
  classes: classesOverride,
  translateOptions = {},
}: any) => {
  const classes = useStyles({classes: classesOverride});
  const translate = useTranslate();

  const handleConfirm = useCallback(
    (e) => {
      e.stopPropagation();
      onConfirm();
    },
    [onConfirm]
  );

  const handleClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      onClick={handleClick}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">
        {translate(title, {_: title, ...translateOptions})}
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.contentText}>
          {translate(content, {
            _: content,
            ...translateOptions,
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onClose}>
          <AlertError className={classes.iconPaddingStyle} />
          {translate(cancel, {_: cancel})}
        </Button>
        <Button
          disabled={loading}
          onClick={handleConfirm}
          className={classnames("ra-confirm", {
            [classes.confirmWarning]: confirmColor === "warning",
            [classes.confirmPrimary]: confirmColor === "primary",
          })}
          autoFocus
        >
          <ActionCheck className={classes.iconPaddingStyle} />
          {translate(confirm, {_: confirm})}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Confirm.defaultProps = {
  cancel: "ra.action.cancel",
  classes: {},
  confirm: "ra.action.confirm",
  confirmColor: "primary",
  isOpen: false,
};

export {Confirm};
