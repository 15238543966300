import React, {useCallback, useRef} from "react";
import {Cancel} from "@material-ui/icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import {useNotify} from "react-admin";
import {TaskEditForm} from "../TaskEdit";
import {FormSpy, FormSpyRenderProps} from "react-final-form";
import Button from "../../components/Button";
import {useCreateTask} from "../UnitShow/NewTaskPane";

export function CreateTaskDialog(props: {
  show: boolean;
  onRequestClose: any;
  selectedUnitIds: string[];
}) {
  const formAPI = useRef<any>(null);
  const handleSave = useCallback(() => {
    formAPI.current.submit();
  }, []);
  const notify = useNotify();
  const createTask = useCreateTask();

  return (
    <Dialog fullWidth open={props.show} onClose={props.onRequestClose}>
      <DialogTitle>Create Tasks</DialogTitle>
      <DialogContent>
        <TaskEditForm
          isCreate
          allowStatus={false}
          toolbar={false}
          allowReportedTime={false}
          save={async (values: any, redirect: any) => {
            let created = 0;
            let errors = 0;
            for (const unitId of props.selectedUnitIds) {
              try {
                await createTask({
                  variables: {
                    unitId,
                    kind: values.kind,
                    props: {
                      assignedEditorId: values.assignedEditor
                        ? parseInt(values.assignedEditor.id)
                        : null,
                      description: values.description,
                    },
                  },
                });
                created += 1;
              } catch (e) {
                console.error(e);
                errors += 1;
              }
            }

            notify(`${created} Tasks created!`);
            if (errors) {
              notify(`${errors} Tasks failed!`);
            }

            props.onRequestClose();
          }}
        >
          <FormSpy
            render={(props: FormSpyRenderProps) => {
              formAPI.current = props.form;
              return null;
            }}
          />
        </TaskEditForm>
      </DialogContent>
      <DialogActions>
        <Button
          label={"Save"}
          size={"medium"}
          variant={"contained"}
          onClick={handleSave}
        />
        <Button
          label="ra.action.cancel"
          onClick={props.onRequestClose}
          size={"medium"}
        >
          <Cancel />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
