import React from "react";
import {
  Filter,
  TextInput,
  Datagrid,
  ListView,
  SelectInput,
  ReferenceField,
  SelectField,
  List,
  Button,
  TextField,
  DateField,
  EditButton,
  ReferenceInput,
} from "react-admin";
import {TaskKind, TaskStatus} from "../TaskEdit";
import {DataGridWithTopSection} from "../../components/DataGridWithTopSection";
import gql from "graphql-tag";
import {Typography} from "@material-ui/core";
import {gql as gqlm} from "@apollo/client";
import {Query} from "@apollo/client/react/components";
import {OpenInEditorButton} from "../../components/OpenInEditorButton";
import {OpenTranslationButton} from "../../components/OpenTranslationButton";
import {TaskBulkActionButtons} from "./BulkActionButtons";
import {
  TaskTotalCost,
  TotalCostOnTaskFragment,
} from "../../components/TaskTotalCost";
import {useApolloListController} from "../../dataProvider/apolloControllers";
import {TaskListQuery} from "../../types/TaskListQuery";
import {getIntPaginationVars} from "../../dataProvider";

const TaskFilter = (props: any) => (
  <Filter {...props}>
    <SelectInput label="Status" choices={TaskStatus} source="status" alwaysOn />
    <SelectInput label="Kind" choices={TaskKind} source="kind" alwaysOn />
    <ReferenceInput
      label="Assigned Editor"
      source="assignedEditor.id"
      reference="editors"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const taskFragment = gqlm`
  fragment TaskProperties on Task {
    id,
    kind,
    description,
    assignedEditor { id },
    price {
      amount,
      currency,
      perWord
    },
    createdAt,
    reportedTime,
    status,
    statusChangedAt,

    wordCount,
    runtimeSeconds,

    unit { 
      id
    }

    ...TotalCostOnTask
  }
  ${TotalCostOnTaskFragment}
`;

export const QueryDef = gqlm`
  ${taskFragment}
  query TaskListQuery(
    $status: TaskStatus, $first: Int, $after: Int, $assignedEditorId: Int, $types: [String!]
  ) {
    tasks(status: $status, page: {first: $first, after: $after}, hideCompleteDelayed: false,
          assignedEditorId: $assignedEditorId, types: $types) {
      count,
      edges {
        node {
          ...TaskProperties
        }
      }                    
    }
  }
`;

export const TaskList = (props: any) => {
  const controllerProps = useApolloListController<TaskListQuery>({
    ...props,
    query: QueryDef,
    getVariables: (params) => {
      return {
        status: params.filter.status,
        assignedEditorId: params.filter.assignedEditor
          ? parseInt(params.filter.assignedEditor.id)
          : null,
        types: params.filter.kind ? [params.filter.kind] : null,
        ...getIntPaginationVars(params),
      };
    },
    getData: (data) => data.tasks!.edges?.map((edge) => edge!.node),
    getTotal: (data) => data.tasks!.count!,
    getId: "id",
  });

  return (
    <ListView
      {...props}
      {...controllerProps}
      filters={<TaskFilter />}
      bulkActionButtons={<TaskBulkActionButtons />}
    >
      <DataGridWithTopSection rowClick="show" sectionContent={<TaskStats />}>
        <SelectField source="kind" choices={TaskKind} />
        <SelectField source="status" choices={TaskStatus} />
        <TargetField label={"Target"} />
        <ReferenceField
          label="Editor"
          source="assignedEditor.id"
          reference="editors"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="reportedTime" />
        <TextField source="wordCount" />
        <TotalCostField label={"Total Cost"} />
        <DateField source="createdAt" />
        <DateField source="statusChangedAt" label={"Updated at"} />
        <EditButton />
        <OpenInEditor />
      </DataGridWithTopSection>
    </ListView>
  );
};

function TaskStats(props: any) {
  return (
    <Query
      query={gql`
        query {
          reviewTasks: tasks(status: review) {
            count
          }
          pendingTasks: tasks(status: pending) {
            count
          }
          startedTasks: tasks(status: started) {
            count
          }
        }
      `}
      variables={{
        unitId: props.record ? props.record.unit.id : "",
      }}
    >
      {({loading, error, data}: any) => {
        if (loading) return <div>Loading...</div>;
        if (error) return <div>{`Error! ${error.message}`}</div>;

        return (
          <div>
            <Typography variant="body1">
              # Review: {data.reviewTasks.count} &bull; # Pending:{" "}
              {data.pendingTasks.count} &bull; # Started:{" "}
              {data.startedTasks.count}
            </Typography>
          </div>
        );
      }}
    </Query>
  );
}

export function OpenInEditor(props: any) {
  if (!props.record || !props.record.unit) {
    return null;
  }

  if (props.record.kind === "translation") {
    return <OpenTranslationButton taskId={props.record.id} />;
  }
  return <OpenInEditorButton unitId={props.record.unit.id} />;
}

/**
 * Show the target of the task. This can be different based on the task type.
 */
export function TargetField(props: any) {
  const {record} = props;
  return (
    <ReferenceField
      label="Target"
      source="unit.id"
      reference="units"
      link={false}
      {...props}
    >
      <TextField source="name" />
    </ReferenceField>
  );
}

/**
 * Total cost field. Calculate the total cost based on minutes/words times rate.
 */
export function TotalCostField(props: any) {
  const {record} = props;
  return <TaskTotalCost task={record} />;
}
