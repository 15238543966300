import * as React from "react";
import {gql} from "@apollo/client";
import {useQuery} from "@apollo/client";
import {NewTaskPane} from "./NewTaskPane";
import {TaskKind, TaskStatus, TaskStatusIcon} from "../../components/Labels";
import {FormatDate} from "../../components/Date";
import {
  TaskTotalCost,
  TotalCostOnTaskFragment,
} from "../../components/TaskTotalCost";
import {Link} from "react-router-dom";

export function TaskList(props: {unitId: string}) {
  const {data, error, loading, refetch} = useQuery(
    gql`
      query TasksQuery($unitId: ID!) {
        tasks(unitId: $unitId, hideCompleteDelayed: false) {
          edges {
            node {
              id
              kind
              description
              status
              reportedTime
              createdAt
              assignedEditor {
                id
                name
              }
              ...TotalCostOnTask
            }
          }
        }
      }
      ${TotalCostOnTaskFragment}
    `,
    {
      variables: {
        unitId: props.unitId,
      },
    }
  );

  if (loading) {
    return null;
  }
  if (error) {
    return <>{JSON.stringify(error)}</>;
  }

  const tasks = data.tasks.edges.map((e: any) => e.node);

  // const redirect = useRedirect();
  //const [create, { loading: isSaving }] = useCreate(resource);
  //
  return (
    <div>
      <Table tasks={tasks} />

      <NewTaskPane unitId={props.unitId} refetch={refetch} />
    </div>
  );
}

function Table(props: {tasks: any[]}) {
  if (!props.tasks.length) {
    return <div>"No tasks"</div>;
  }

  return (
    <table style={{width: "100%", padding: "1em"}}>
      <thead>
        <tr>
          <th style={{textAlign: "left"}}>Date</th>
          <th style={{textAlign: "left"}}>Kind</th>
          <th style={{textAlign: "left"}}>Status</th>
          <th style={{textAlign: "left"}}>Assigned To</th>
          <th style={{textAlign: "left"}}>Cost</th>
          <th style={{textAlign: "left"}}>Actions</th>
        </tr>
      </thead>
      <tbody>
        {props.tasks.map((task) => {
          return (
            <tr key={task.id}>
              <td>
                <Link to={`/tasks/${task.id}/show`}>
                  <FormatDate date={task.createdAt} />
                </Link>
              </td>
              <td>
                <TaskKind kind={task.kind} />
                <div>
                  <em>{task.description}</em>
                </div>
              </td>
              <td>
                <TaskStatusIcon status={task.status} />
                <TaskStatus status={task.status} />
              </td>
              <td>{task.assignedEditor ? task.assignedEditor.name : null}</td>
              <td>
                <TaskTotalCost task={task} />
              </td>
              <td>
                {task.kind === "translation" ? (
                  <>
                    <a
                      href={`http://editor.languagetool.xyz/#/translation/task/${task.id}`}
                      target={"_blank"}
                    >
                      Link for Editor
                    </a>
                  </>
                ) : null}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
