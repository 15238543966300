import {Datagrid, Link, ListView} from "ra-ui-materialui";
import React, {Fragment} from "react";
import {gql as gqlm} from "@apollo/client";
import {UnitFilter} from "./Filters";
import {
  StatusBox,
  StatusBoxNew,
  StatusField,
} from "../../components/StatusField";
import {useApolloListController} from "../../dataProvider/apolloControllers";
import {
  UnitListQuery,
  UnitListQuery_units_edges_node,
} from "../../types/UnitListQuery";
import {getDoneFilter, getPaginationVars2} from "../../dataProvider";
import {UnitProperties} from "../../types/UnitProperties";
import {BulkActionButtons} from "./BulkActionButtons";
import {BookIcon} from "../../icons/book";

export const unitFragment = gqlm`
  fragment UnitProperties on Unit {
    id,
    name,
    type,
    isReady,
    isMetadataComplete,
    difficulty,
    season {
      id,
      series {
        id,
        name
      }
    }
    assignments {
      site {
        id,
        name
      }
    },
    contentIssues {
      count
    }
    mediaTrack { id },
    captionTrack { 
      id,
      isTextReady, 
      isGrammarReady,
      format,
      
      # For the translation status, and unassigned status, we look at whatever the very latest state is.
      # We do not care about what might be the user-visible version, because the "is in edit" status will
      # show if there is one, and if there is one, we want it to go online at some point anyway.
      #
      # In particular, the count of unset words is upposed to tell us if there is an action required, and 
      # there is not if the latest version already has all of them.
      latestVersion {
        id,
        numUnassignedVocables,
        translations(langs: ["en"]) {
          isReady
        }
      },
      editableVersion {
        id
      },
      currentVersion {
        id
      }      
    },
    hasGrammarTask: hasActiveTask(taskType: grammar),
    hasCaptionTask: hasActiveTask(taskType: captioning),
    hasTranslationTask: hasActiveTask(taskType: translation),
  }
`;

export const Query = gqlm`
  ${unitFragment}
  
  query UnitListQuery(
    $first: Int, 
    $after: String, 
    $search: String,
    $translationDone: Boolean,
    $captionsDone: Boolean,
    $grammarDone: Boolean,
    $mediaDone: Boolean,
    $isPublic: Boolean,
    $hasCaptionsTask: Boolean,
    $hasTranslationTask: Boolean,
    $hasGrammarTask: Boolean,
    $belongsToSite: String,
    $belongsToAnySite: Boolean,
    $belongsToAnySeries: Boolean,
    $types: [String!],
  ) {
    units(
      first: $first, 
      after: $after,
      search: $search, 
      translationDone: $translationDone, 
      captionsDone: $captionsDone,
      mediaDone: $mediaDone,
      grammarDone: $grammarDone,
      isPublic: $isPublic,
      hasCaptionsTask: $hasCaptionsTask,
      hasTranslationTask: $hasTranslationTask,
      hasGrammarTask: $hasGrammarTask,
      belongsToAnySite: $belongsToAnySite,
      belongsToSite: $belongsToSite,
      belongsToAnySeries: $belongsToAnySeries,
      types: $types
    ) {
      count,
      edges {
        node {
          ...UnitProperties
        }
      }        
    }
}`;

const NameField = (props: any) => {
  return (
    <Link to={`/units/${props.record.id}/show`}>
      {props.record.name} {props.record.type == "book" ? <BookIcon /> : null}
      {props.record.season?.series ? (
        <span style={{color: "gray"}}>
          {" "}
          [{props.record.season?.series.name}]{" "}
        </span>
      ) : null}
      {props.record.difficulty ? (
        <>({props.record.difficulty.toFixed(2)})</>
      ) : null}
    </Link>
  );
};

export const UnitList = (props: any) => {
  const controllerProps = useApolloListController<UnitListQuery>({
    ...props, // passes in resourceName, basePath
    query: Query,
    getVariables: (params) => {
      return {
        ...getPaginationVars2(params),
        ...getDoneFilter(
          params,
          "c_translation",
          "translationDone",
          "hasTranslationTask"
        ),
        ...getDoneFilter(
          params,
          "c_subtitles",
          "captionsDone",
          "hasCaptionsTask"
        ),
        ...getDoneFilter(params, "c_grammar", "grammarDone", "hasGrammarTask"),
        ...getDoneFilter(params, "c_media", "mediaDone"),
        ...getDoneFilter(params, "c_public", "isPublic"),
        search: params.filter.c_search,
        belongsToAnySite:
          params.filter.c_site === "has-site"
            ? true
            : params.filter.c_site === "has-no-site"
            ? false
            : null,
        belongsToAnySeries:
          params.filter.c_has_series === true
            ? true
            : params.filter.c_has_series === false
            ? false
            : null,
        types: params.filter.c_type ? [params.filter.c_type] : undefined,
      };
    },
    getData: (data) => data.units!.edges.map((edge) => edge!.node),
    getTotal: (data) => data.units!.count!,
    getId: "id",
  });

  return (
    <ListView
      {...props}
      {...controllerProps}
      filters={<UnitFilter />}
      exporter={null}
      bulkActionButtons={<BulkActionButtons />}
    >
      <Datagrid rowClick="show">
        <NameField />
        <UnitVersionStatusField />
        <UnitStatusField />
      </Datagrid>
    </ListView>
  );
};

export const UnitStatusField = (props: {record?: UnitProperties}) => {
  const {record} = props;

  const translations =
    record!.captionTrack && record!.captionTrack.latestVersion
      ? record!.captionTrack.latestVersion.translations
      : null;
  let hasTranslations = translations && translations.length;

  return (
    <StatusField>
      <StatusBox complete={record!.isReady}>Public</StatusBox>
      <StatusBox complete={record!.assignments.length > 0 || !!record!.season}>
        {record!.assignments.map((a) => a.site.name)}
        {record!.season ? "Season" : null}

        {!record!.assignments.length && !record!.season ? (
          <span>None</span>
        ) : null}
      </StatusBox>
      <StatusBox complete={record!.isMetadataComplete}>Metadata</StatusBox>
      <StatusBox
        complete={
          !!record!.mediaTrack ||
          record?.captionTrack?.format == "simple-picture-book"
        }
      >
        Media
      </StatusBox>
      <StatusBox
        complete={record!.captionTrack?.isTextReady ?? false}
        inProgress={record!.hasCaptionTask || false}
      >
        Subtitles
      </StatusBox>
      <StatusBox
        complete={record!.captionTrack?.isGrammarReady ?? false}
        inProgress={record!.hasGrammarTask}
      >
        Grammar
      </StatusBox>
      <StatusBox
        complete={translations?.[0]?.isReady ?? false}
        inProgress={record!.hasTranslationTask}
      >
        Translation
      </StatusBox>
    </StatusField>
  );
};

export const UnitVersionStatusField = (props: {
  record?: UnitListQuery_units_edges_node;
}) => {
  const {record} = props;

  const hasVersionNewerThanCurrent =
    record?.captionTrack?.latestVersion?.id !=
    record?.captionTrack?.currentVersion?.id;
  const hasEditableVersion = !!record?.captionTrack?.editableVersion;
  const numUnassignedVocables =
    record?.captionTrack?.latestVersion?.numUnassignedVocables || 0;

  const issues = [];
  if (numUnassignedVocables > 0) {
    issues.push(
      <Fragment key={"wrd"}>
        {numUnassignedVocables} <small>wrd</small>
      </Fragment>
    );
  }
  if (record?.contentIssues?.count) {
    issues.push(
      <Fragment key={"iss"}>
        {record?.contentIssues?.count} <small>iss</small>
      </Fragment>
    );
  }

  let status, message, title: string;
  if (hasVersionNewerThanCurrent) {
    status = "warning";
    message = "Newer";
    title = "There is a newer version that is not published.";
  } else if (hasEditableVersion) {
    status = "progress";
    message = "Unreviewed";
    title = "The latest version needs a review";
  } else {
    status = "complete";
    message = "✓";
    title = "The latest version is published and reviewed.";
  }

  return (
    <StatusField>
      <StatusBoxNew status={status as any} title={title}>
        <small>{message}</small>
      </StatusBoxNew>
      <StatusBox inProgress={!!issues.length} complete={!issues.length}>
        {issues.length > 0
          ? issues.reduce((p, c) => [p, ", ", c] as any)
          : "All Set"}
      </StatusBox>
    </StatusField>
  );
};
