import * as React from "react";
import {
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";

// Copied from Farsi.school, maybe we should query this from somewhere?
export const MainTagNames: {[key: string]: string} = {
  ads: "Ads",
  experience: "Experience",
  children: "Children",
  entertainment: "Entertainment",
  culture: "Culture",
  travel: "Travel",
  sport: "Sport",
  animation: "Animation",
  comedy: "Comedy",
  cooking: "Cooking",
  education: "Education",
  people: "People",
};

const TAGS = Object.entries(MainTagNames).map(([key, label]) => ({label, key}));

export function TagSelector(props: {
  selected: string[];
  onChange: (value: string[]) => void;
}) {
  const handleChange = (event: React.ChangeEvent<{value: unknown}>) => {
    props.onChange(event.target.value as string[]);
  };

  return (
    <FormControl fullWidth>
      <InputLabel>Tags</InputLabel>
      <Select
        multiple
        value={props.selected}
        onChange={handleChange}
        input={<Input />}
        renderValue={(selected) => (selected as string[]).join(", ")}
      >
        {TAGS.map(({key, label}) => (
          <MenuItem key={key} value={key}>
            <Checkbox checked={props.selected.indexOf(key) > -1} />
            <ListItemText primary={label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
