/**
 * Shows which series / sites this unit belongs to.
 *
 * Can belong to either
 **/

import * as React from "react";
import {
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Button,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import {Delete, Inbox, Drafts} from "@material-ui/icons";
import {useQuery, QueryResult} from "@apollo/client";
import {gql} from "@apollo/client";
import {AssignSeriesDialog} from "./AssignSeriesDialog";
import {useState} from "react";
import {useRewriteQueryData} from "../../../components/useRewriteQueryData";
import {AssignSiteDialog} from "./AssignSiteDialog";
import {useDeleteTagAssignment} from "./queries";

function useTagQuery(unitId: string) {
  return useQuery(
    gql`
      query TagQuery($unitId: ID!) {
        unit(id: $unitId) {
          id
          assignments {
            site {
              id
              name
            }
            tags
          }
          season {
            id
            name
            series {
              id
              name
            }
          }
        }
      }
    `,
    {
      variables: {
        unitId,
      },
    }
  );
}

export function BelongsToTab(props: {unitId: string}) {
  const tagQuery = useRewriteQueryData<BelongsToTabBaseData>(
    useTagQuery(props.unitId),
    (data) => {
      return {
        season: data.unit.season,
        assignments: data.unit.assignments,
      };
    }
  );

  return (
    <BelongsToTabBase
      tagQuery={tagQuery}
      unitId={props.unitId}
      allowSeriesAssign={true}
    />
  );
}

export interface BelongsToTabBaseData {
  season?: {
    series: any;
  };
  assignments: {
    site: {id: string; name: string};
    tags: string[];
  }[];
}

/**
 * You need to pass a query result to this with the data rewritten to match `BelongsToTabBaseData`.
 */
export function BelongsToTabBase(props: {
  // Pass either a unit or a series id
  unitId?: string;
  seriesId?: string;

  tagQuery: QueryResult<BelongsToTabBaseData>;
  allowSeriesAssign?: boolean;
}) {
  const {data, error, loading, refetch} = props.tagQuery;
  const [openDialog, setOpenDialog] = useState<null | "series" | "tags">(null);
  const [tagsDefaultState, setTagsDefaultState] = useState<any>(null);

  const [deleteTagAssignment, _] = useDeleteTagAssignment();

  if (loading) {
    return <div>...</div>;
  }
  if (error) {
    return <div>{"" + error}</div>;
  }
  if (!data) {
    return null;
  }

  let seriesItem;
  if (data.season) {
    seriesItem = <Item icon={<Inbox />} text={data.season.series.name} />;
  }

  let emptyMessage =
    !seriesItem && data.assignments.length === 0 ? (
      <div>Currently not assigned to any site.</div>
    ) : null;

  return (
    <div>
      <List>
        {seriesItem}
        {data.assignments.map((assignment, idx: number) => {
          return (
            <Item
              key={idx}
              icon={<Drafts />}
              text={`${assignment.site.name} - ${
                assignment.tags ? assignment.tags.join(", ") : ""
              }`}
              onClick={() => {
                setOpenDialog("tags");
                setTagsDefaultState({
                  siteId: assignment.site.id,
                  tags: assignment.tags,
                });
              }}
              hasDeleteButton={true}
              onDelete={async () => {
                await deleteTagAssignment({
                  variables: {
                    unitId: props.unitId,
                    seriesId: props.seriesId,
                    siteId: assignment.site.id,
                  },
                });
                refetch();
              }}
            />
          );
        })}
        {emptyMessage}
      </List>

      {props.allowSeriesAssign ? (
        <>
          <AssignSeriesDialog
            isOpen={openDialog === "series"}
            onRequestClose={() => setOpenDialog(null)}
            unitId={props.unitId!}
          />
          <Button color={"primary"} onClick={() => setOpenDialog("series")}>
            Assign To Series
          </Button>
        </>
      ) : null}

      <AssignSiteDialog
        isOpen={openDialog === "tags"}
        onRequestClose={() => setOpenDialog(null)}
        unitId={props.unitId}
        seriesId={props.seriesId}
        refetch={refetch}
        initialData={tagsDefaultState}
      />
      <Button
        color={"primary"}
        onClick={() => {
          setOpenDialog("tags");
          setTagsDefaultState(null);
        }}
      >
        Assign To Site
      </Button>
    </div>
  );
}

function Item(props: {
  icon: any;
  text: string;
  hasDeleteButton?: boolean;
  onDelete?: () => void;
  onClick?: () => void;
}) {
  return (
    <ListItem button onClick={props.onClick}>
      <ListItemIcon>{props.icon}</ListItemIcon>
      <ListItemText primary={props.text} />
      {props.hasDeleteButton ? (
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="delete" onClick={props.onDelete}>
            <Delete />
          </IconButton>
        </ListItemSecondaryAction>
      ) : null}
    </ListItem>
  );
}
