import React from "react";

export function MissingMetadataMessage(props: {children: any}) {
  return <div style={{color: "gray"}}>{props.children}</div>;
}

export function Links(props: {
  links: {
    type: string;
    value: string;
  }[];
}) {
  if (!props.links.length) {
    return (
      <MissingMetadataMessage>
        No links have been defined.
      </MissingMetadataMessage>
    );
  }
  return (
    <ul>
      {props.links.map((link, idx) => {
        const {icon: IconClass, title, urlGen, labelGen} = LinkTypes[
          link.type as LinkType
        ];

        return (
          <li key={idx}>
            <a href={urlGen(link.value)}>{title}</a>
          </li>
        );
      })}
    </ul>
  );
}

export type LinkType =
  | "url"
  | "youtube"
  | "youtube-channel"
  | "aparat"
  | "instagram";

export const LinkTypes: {
  [type in LinkType]: {
    icon?: React.ElementType<any>;
    title: any;
    urlGen: (val: string) => string;
    labelGen?: (val: string) => string;
  };
} = {
  instagram: {
    title: "Instagram",
    urlGen: (instaId) => `https://www.instagram.com/${instaId}/`,
    labelGen: (instaId) => `@${instaId}`,
  },
  youtube: {
    title: "Youtube",
    urlGen: (videoId) => `https://www.youtube.com/watch?v=${videoId}`,
  },
  "youtube-channel": {
    title: "Youtube",
    urlGen: (channelId) => `https://www.youtube.com/channel/${channelId}`,
    labelGen: (channelId) => `Channel`,
  },
  aparat: {
    title: "Aparat",
    urlGen: (aparatId) => `https://www.aparat.com/v/${aparatId}`,
    labelGen: (channelId) => `${channelId}`,
  },
  url: {
    title: "Website",
    urlGen: (url) => url,
    labelGen: () => `Visit`,
  },
};
