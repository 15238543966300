/**
 * Helpers to load subtitles from Youtube video, and so on.
 */


import {Captions, tokenizeAndTagForListOfLines} from "../models/Captions";
import {trim} from 'lodash';
import * as React from "react";


const decodeHtmlEntity = function(str: string) {
  return str.replace(/&#(\d+);/g, function(match, dec) {
    return String.fromCharCode(dec);
  });
};


/**
 * Youtube subtitles use a XML-format.
 */
function* parseYouTubeTimedText(text: string) {
  const node = (new (window as any).DOMParser()).parseFromString(text, "text/xml");
  const textNodes = node.childNodes[0].childNodes;

  for (let i=0; i<textNodes.length; i++) {
    let node = textNodes[i];
    let time = parseFloat(node.attributes.start.value);
    let duration = parseFloat(node.attributes.dur.value);

    // Ignore whitespace, such as included newlines, as an HTML/XML parser would/should do.
    let text = decodeHtmlEntity(node.textContent);
    text = text.replace('\n', " ");

    // Ignore empty lines
    if (!trim(text)) {
      continue;
    }

    yield {time, text, duration};
  }
}


export async function fetchCaptionFromYoutube(videoId: string, lang: string) {
  const response = await fetch(`https://video.google.com/timedtext?lang=${lang}&v=${videoId}`);
  const xmlText = await response.text();
  const trackAsLines = Array.from(parseYouTubeTimedText(xmlText));

  const linesOfText = trackAsLines.map(line => line.text);

  const linesOfWords = await tokenizeAndTagForListOfLines(lang, linesOfText);

  let i = 0;
  const getid = () => { i = i + 1; return ""+i; }

  // Add back the line durations
  const captionTrack = linesOfWords.map((line, idx) => {
    line.forEach(w => {
      if (!w.id) { w.id = getid(); }
    })
    return {
      elements: [line],
      time: trackAsLines[idx].time,
      duration: trackAsLines[idx].duration,
    }
  })
  return new Captions(lang, {
    lines: captionTrack,
    nodes: []
  });
}


/**
 * Fetch info about a YouTube video; can we parse it? Which languages
 * are available?
 */
export async function getYouTubeInfo(videoId: string) {
  // Fetch list of subtitles for this video
  let langs;
  try {
    langs = await fetch(`https://video.google.com/timedtext?type=list&v=${videoId}`);
    langs = await langs.text();
  } catch (e) {
    console.log(e);
    throw 'Failed to load the video; maybe the id is wrong?';
  }

  // Parse the response
  const rootNode = (new (window as any).DOMParser()).parseFromString(langs, "text/xml");

  let tracks: {[key: string]: {original: string, translated: string}} = {};
  let defaultTrack = null;
  for (let node of rootNode.getElementsByTagName('track')) {
    let langCode = node.attributes.lang_code.value;
    tracks[langCode] = {
      original: node.attributes.lang_original.value,
      translated: node.attributes.lang_translated.value
    };
    if (node.attributes.lang_default && node.attributes.lang_default.value) {
      defaultTrack = langCode;
    }
  };

  return {
    tracks,
    defaultTrack,
    videoId

  }
}


// Returns the video id
export function parseYouTubeURL(url: string): string|false {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return (match && match[7].length == 11) ? match[7] : (
    url.length == 11 ? url : false
  );
}


export function makeYoutubeUrl(videoId: string) {
  return `https://www.youtube.com/watch?v=${videoId}`;
}


