export function formatDuration(totalSeconds: number) {
  const hours = Math.round(totalSeconds / 3600);
  const minutes = Math.round(totalSeconds / 60);
  const seconds = Math.round(totalSeconds % 60);

  const parts: string[] = [];
  if (hours > 0) {
    parts.push(`${hours}h`);
  }
  if (minutes > 0) {
    parts.push(`${minutes}m`);
  }
  if (parts.length === 0 || seconds) {
    parts.push(`${seconds}s`);
  }

  return parts.join(" ");
}
