import React from "react";
import gql from "graphql-tag";
import {Query} from "@apollo/client/react/components";
import {
  Show,
  CardActions,
  DateField,
  ReferenceField,
  SimpleShowLayout,
  TextField,
  EditButton,
} from "react-admin";
import {OpenInEditor} from "../TaskList";

export const TaskShow = (props: any) => (
  <Show aside={<Aside />} actions={<PostShowActions />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="kind" />
      <TextField source="description" />
      <ReferenceField source="assignedEditor.id" reference={"editors"}>
        <TextField source="name" />
      </ReferenceField>
      <TextField source="reportedTime" />
      <DateField source="createdAt" />
      <TextField source="status" />
      <DateField source="statusChangedAt" />
      <ReferenceField
        label="Unit"
        source="unit.id"
        reference="units"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

const PostShowActions = ({basePath, data, resource}: any) => (
  <CardActions>
    <EditButton basePath={basePath} record={data} />
    <OpenInEditor record={data} />
  </CardActions>
);

const Aside = (props: any) => {
  if (!props.record) {
    return null;
  }
  if (!props.record.unit) {
    return null;
  }
  return (
    <Query
      query={gql`
        query($unitId: ID!) {
          unit(id: $unitId) {
            id
            mediaTrack {
              id
              runtimeSeconds
            }
            captionTrack {
              id
              latestVersion {
                id
                numWords
              }
            }
          }
        }
      `}
      variables={{
        unitId: props.record && props.record.unit ? props.record.unit.id : "",
      }}
    >
      {({loading, error, data}: any) => {
        if (loading) return <div>Loading...</div>;
        if (error) return <div>Error! {error.message}</div>;

        const minutesTaken = props.record.reportedTime;
        const runtimeSeconds: number | undefined =
          data.unit.mediaTrack?.runtimeSeconds;

        return (
          <div
            style={{
              width: 400,
              margin: "1em",
              display: "grid",
              gridTemplateColumns: "150px 1fr",
              alignSelf: "flex-start",
              gridRowGap: "10px",
            }}
          >
            <div>Reported Time:</div>
            <div>{minutesTaken} min</div>
            <div>Media Length:</div>
            {runtimeSeconds ? (
              <span>{runtimeSeconds}s</span>
            ) : (
              <em style={{color: "gray"}}>(no media)</em>
            )}
            <div># Words:</div>
            <span>{data.unit.captionTrack.latestVersion.numWords}</span>
            <div>Seconds Work / word:</div>
            <span>
              {(minutesTaken * 60) /
                data.unit.captionTrack.latestVersion.numWords}
            </span>
            <div>Minutes Work / video minute:</div>
            {runtimeSeconds ? (
              <span>{minutesTaken / (runtimeSeconds % 60)}</span>
            ) : (
              <em style={{color: "gray"}}>(no media)</em>
            )}
          </div>
        );
      }}
    </Query>
  );
};
