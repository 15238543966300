import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  InputBase,
  makeStyles,
  fade,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import {Search as SearchIcon} from "@material-ui/icons";
import {FixedSizeList} from "react-window";
import {gql as gqlm} from "@apollo/client";
import {useQuery} from "multi-apollo";
import {useDebounce} from "react-use";

export const Query = gqlm`  
  query SelectUnitDialogQuery(
    $first: Int, 
    $after: String, 
    $search: String,    
  ) {
    units(
      first: $first, 
      after: $after,
      search: $search,       
    ) {
      count,
      edges {
        node {
          id,
          name,
          season {
            id,
            series { 
              id,
              name
            }
          }
        }
      }        
    }
}`;

export function SelectUnitDialog(props: {
  onSelect: (unitId: string) => void;
  onRequestClose: () => void;
  isOpen: boolean;
}) {
  return (
    <Dialog
      onClose={props.onRequestClose}
      open={props.isOpen}
      style={{minWidth: "400px"}}
    >
      <DialogTitle>Pick a unit</DialogTitle>

      <SelectUnitDialogContent onClick={(unitId) => props.onSelect(unitId)} />

      <DialogActions>
        <Button onClick={props.onRequestClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

export function SelectUnitDialogContent(props: {
  onClick?: (unitId: string) => void;
}) {
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = React.useState("");
  const [debouncedQuery, setDebouncedQuery] = React.useState("");

  const [, cancel] = useDebounce(
    () => {
      setDebouncedQuery(searchQuery);
    },
    800,
    [searchQuery]
  );

  const {data, loading, error} = useQuery(Query, {
    variables: {
      first: 500,
      search: debouncedQuery,
    },
    skip: !searchQuery,
  });

  return (
    <div style={{border: "1px solid silver"}}>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{"aria-label": "search"}}
          value={searchQuery}
          onChange={(e: any) => {
            setSearchQuery(e.target.value);
          }}
        />
      </div>

      <div className={classes.listRoot}>
        <FixedSizeList height={400} itemSize={46} itemCount={data?.units.count}>
          {(p: any) => {
            const {index, style} = p;

            const unit = data?.units.edges[index].node;
            return (
              <ListItem
                button
                style={style}
                key={index}
                onClick={() => props.onClick?.(unit.id)}
              >
                <ListItemText
                  primary={unit.name}
                  secondary={unit?.season?.series?.name}
                />
              </ListItem>
            );
          }}
        </FixedSizeList>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  listRoot: {
    width: "100%",
    height: 400,
    maxWidth: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));
