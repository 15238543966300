import React, {useEffect, useMemo} from "react";
import {Route, Switch} from "react-router-dom";
import {Admin, Resource, Layout, AppBar} from "react-admin";
import {DataProvider} from "./dataProvider/index";
import {UnitShow} from "./ui/UnitShow";
import {TaskList} from "./ui/TaskList";
import {TaskEdit} from "./ui/TaskEdit";
import {TaskCreate} from "./ui/TaskCreate";
import {TaskShow} from "./ui/TaskShow";
import {DataProviderRoot} from "./dataProvider/context";
import {UnitList} from "./ui/UnitList";
import {EditorList} from "./ui/EditorList";
import {EditorEdit} from "./ui/EditorEdit";
import {EditorCreate} from "./ui/EditorCreate";
import {SeriesList} from "./ui/SeriesList";
import {SeriesShow} from "./ui/SeriesShow";
import {SeasonEdit} from "./ui/SeasonEdit";
import {SeasonCreate} from "./ui/SeasonCreate";
import {Auth0Provider, useAuth0} from "./components/Auth0Provider";
import {MenuItem, ListItemIcon, Button} from "@material-ui/core";
import {ExitToApp} from "@material-ui/icons";
import {SeriesCreate} from "./ui/SeriesCreate";
import {SeriesEdit} from "./ui/SeriesEdit";
import {UnitEdit} from "./ui/UnitEdit";
import {ContentTaskView} from "./ui/ContentTaskView";
import {UserMenu} from "./components/UserMenu";
import {ContentIssueList} from "./ui/ContentIssueList";
import {UnitCreate} from "./ui/UnitCreate";
import {RenderVideo} from "./ui/RenderVideo";
import {useURLQuery} from "./components/utils/useQuery";

const config = {
  domain: "languagetool.eu.auth0.com",
  clientId: "3EaMGhI3F2ovNfJQc7jCI0ftb78bpR7D",
};

// This must be the one configured in Auth0 as the callback url for login and logout.
function getRootUrl() {
  // Also see https://stackoverflow.com/a/1368295
  const parsedUrl = new URL(window.location.href);
  parsedUrl.search = "";
  parsedUrl.pathname = "";
  parsedUrl.hash = "";
  return parsedUrl.toString();
}

export function useRequireLogin() {
  const {
    isAuthenticated,
    loginWithRedirect,
    loading,
    getTokenSilently,
  } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated && !loading) {
      loginWithRedirect();
    }
  }, [isAuthenticated, loginWithRedirect, loading]);

  return [isAuthenticated, getTokenSilently];
}

const LockedApp = () => {
  const {
    isAuthenticated,
    loginWithRedirect,
    loading,
    getTokenSilently,
  } = useAuth0();

  // See if a manual auth token was passed as an url param
  const query = useURLQuery();
  const tokenFromQuery = useMemo(() => {
    if (query.has("token")) {
      return query.get("token");
    }
  }, [query]);

  const p = useMemo(
    () =>
      new DataProvider(process.env.REACT_APP_MARKET_API_URL, async () => {
        if (tokenFromQuery) {
          return `Bearer ${tokenFromQuery}`;
        }

        return `Bearer ${await getTokenSilently()}`;
      }),
    [getTokenSilently, tokenFromQuery]
  );

  if (loading) {
    return null;
  }

  if (!isAuthenticated && !tokenFromQuery) {
    return (
      <div style={{padding: "20px"}}>
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={loginWithRedirect}
        >
          Login
        </Button>
      </div>
    );
  }

  return (
    <DataProviderRoot value={p}>
      <Admin
        dataProvider={p.reactAdmin}
        layout={MyLayout}
        customRoutes={[
          <Route exact path="/task/:id" component={ContentTaskView} noLayout />,
          <Route exact path="/render" component={RenderVideo} noLayout />,
        ]}
      >
        <Resource
          name="units"
          list={UnitList}
          show={UnitShow}
          edit={UnitEdit}
          create={UnitCreate}
        />
        <Resource
          name="contentIssues"
          label={"Content Issues"}
          list={ContentIssueList}
        />
        <Resource
          name="series"
          list={SeriesList}
          show={SeriesShow}
          edit={SeriesEdit}
          create={SeriesCreate}
        />
        <Resource
          name="tasks"
          list={TaskList}
          edit={TaskEdit}
          create={TaskCreate}
          show={TaskShow}
        />
        <Resource
          name="editors"
          list={EditorList}
          edit={EditorEdit}
          create={EditorCreate}
        />
        <Resource name="seasons" edit={SeasonEdit} create={SeasonCreate} />
      </Admin>
    </DataProviderRoot>
  );
};

const MyUserMenu = (props: any) => {
  const {isAuthenticated, logout} = useAuth0();

  if (!isAuthenticated) {
    return null;
  }

  return (
    <UserMenu {...props}>
      <MenuItem
        onClick={() => {
          logout();
        }}
      >
        <ListItemIcon>
          <ExitToApp />
        </ListItemIcon>
        Logout
      </MenuItem>
    </UserMenu>
  );
};

const MyAppBar = (props: any) => (
  <AppBar {...props} userMenu={<MyUserMenu />} />
);

const MyLayout = (props: any) => <Layout {...props} appBar={MyAppBar} />;

const App = () => (
  <Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    getRedirectUri={getRootUrl}
    audience={"https://api.languagetool.xyz"}
  >
    <LockedApp />
  </Auth0Provider>
);

export default App;
