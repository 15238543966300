import {Button} from "@material-ui/core";
import * as React from "react";

export function OpenInEditorButton(props: {unitId: string; size?: any}) {
  return (
    <Button
      component={"a"}
      href={`https://editor.languagetool.xyz?load=${props.unitId}`}
      target={"_blank"}
      onClick={(e: any) => {
        e.stopPropagation();
      }}
      size={props.size}
      variant="contained"
    >
      Editor
    </Button>
  );
}
