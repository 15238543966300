import {useCallback, useEffect} from "react";
import {usePrevious} from "react-use";

export function useResetOnOpen(
  isOpen: boolean,
  reset: () => void,
  deps: any[]
) {
  const doReset = useCallback(reset, deps);

  const prevIsOpen = usePrevious(isOpen);
  useEffect(() => {
    if (!prevIsOpen && isOpen) {
      doReset();
    }
  }, [prevIsOpen, isOpen, doReset]);
}
