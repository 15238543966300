// Or maybe just use mousetrap? Or maybe use https://github.com/greena13/react-hotkeys?

import * as React from "react";
import {PlayerContext} from "../PlayerContext/context";
import {ICaptionTrackContext} from "./Manager";
import {Platform} from 'react-native';


type Props = {};


function useProcessKeyEvent() {
  const contextOrg = React.useContext(PlayerContext) as ICaptionTrackContext;

  const contextRef = React.useRef<ICaptionTrackContext>();
  React.useEffect(() => {
    contextRef.current = contextOrg;
  }, [contextRef, contextOrg])

  return React.useCallback((e: KeyboardEvent) => {
    // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
    if (e.key == 'ArrowRight' || e.key == 'Right') {
      if (contextRef.current!.uiController) {
        contextRef.current!.uiController.next();
      }
      return;
    }

    if (e.key == 'ArrowLeft' || e.key == 'Left') {
      if (contextRef.current!.uiController) {
        contextRef.current!.uiController.prev();
      }
      return;
    }

    if (e.key == 'ArrowUp' || e.key == 'Up') {
      if (contextRef.current!.uiController) {

      }
      return;
    }

    if (e.key == 'ArrowDown' || e.key == 'Down') {
      if (contextRef.current!.uiController) {}
      return;
    }

    if (e.key == 'MediaPause') {
      if (contextRef.current!.playerAPI) {
        contextRef.current!.playerAPI.pause();
      }
      return;
    }

    if (e.key == ' ') {
      if (contextRef.current!.playerState) {
        if (contextRef.current!.playerState.isPlaying) {
          contextRef.current!.playerAPI!.pause();
        } else {
          contextRef.current!.playerAPI!.play();
        }
      }
      return;
    }

    if ((e.key == 'r' && !e.altKey && !e.shiftKey && !e.ctrlKey && !e.metaKey) || e.key == 'Enter') {
      if (contextRef.current!.uiController) {
        if (contextRef.current!.uiState.lockState.isLocked) {
          contextRef.current!.uiController.unlock();
        } else {
          contextRef.current!.uiController.lock();
        }
      }
      return;
    }

    return true;
  }, [contextRef]);
}


export function KeyboardShortcuts(props: {
  children?: any
}) {
  const processKeyEvent = useProcessKeyEvent();
  const ref = React.useRef<any>();

  const handleKeyDown = React.useCallback((e: KeyboardEvent) => {
    const wasNotProcessed = processKeyEvent(e);
    if (wasNotProcessed !== true) {
      // make sure nothing else processes them
      e.preventDefault();
      e.stopPropagation();
    }
  }, [processKeyEvent]);

  React.useEffect(() => {
    if (!ref.current) { return; }
    
    ref.current.addEventListener('keydown', handleKeyDown);
    return () => {
      ref.current.removeEventListener('keydown', handleKeyDown);
    }
  }, [ref.current, handleKeyDown]);

  if (Platform.OS !== 'web') {
    return props.children;
  }

  return <div ref={ref} tabIndex={0}>{props.children}</div>
}
