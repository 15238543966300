import React from "react";
import {BelongsToTabBase, BelongsToTabBaseData} from "../UnitShow/BelongsToTab";
import {useQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {useRewriteQueryData} from "../../components/useRewriteQueryData";

/**
 *
 */
function useTagQuery(seriesId: string) {
  return useQuery(
    gql`
      query TagQuery($seriesId: ID!) {
        series(id: $seriesId) {
          id
          assignments {
            site {
              id
              name
            }
            tags
          }
        }
      }
    `,
    {
      variables: {
        seriesId,
      },
    }
  );
}

export function BelongsToTab(props: {seriesId: string}) {
  const tagQuery = useRewriteQueryData<BelongsToTabBaseData>(
    useTagQuery(props.seriesId),
    (data) => {
      return {
        assignments: data.series.assignments,
      };
    }
  );
  return <BelongsToTabBase tagQuery={tagQuery} seriesId={props.seriesId} />;
}
