import React, {useContext} from "react";
import {CreateView} from "react-admin";
import {SeriesEditForm, useSeriesEditCreate} from "./SeriesEdit";

export const SeriesCreate = () => {
  const controllerProps = useSeriesEditCreate();

  return (
    <CreateView {...controllerProps}>
      <SeriesEditForm isCreate />
    </CreateView>
  );
};
