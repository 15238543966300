import React from "react";
import { Datagrid } from 'react-admin';


export function DataGridWithTopSection(props) {
  const {sectionContent, ...datagridProps} = props;
  return <div>
    <div style={{padding: '20px'}}>
      {sectionContent}
    </div>
    <Datagrid {...datagridProps} />
  </div>
}