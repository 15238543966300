import React, {useState, useCallback, useEffect} from "react";
import {Dialog, DialogTitle, DialogActions, Button} from "@material-ui/core";
import {gql} from "@apollo/client";
import {useMutation, useQuery} from "@apollo/client";
import {usePrevious} from "react-use";
import {GenericFilterDropdown} from "../../../components/GenericFilterDropdown";

function useSeriesQuery(opts: {skip: boolean}) {
  const {loading, data, error} = useQuery(
    gql`
      query SeriesQuery {
        seriesCollection {
          id
          name
        }
      }
    `,
    {
      skip: opts.skip,
      variables: {},
    }
  );

  return {loading, data, error};
}

function useSeasonQuery(seriesId?: string) {
  const {loading, data, error} = useQuery(
    gql`
      query SeriesQuery($seriesId: ID!) {
        series(id: $seriesId) {
          id
          seasons {
            id
            name
          }
        }
      }
    `,
    {
      skip: !seriesId,
      variables: {
        seriesId,
      },
    }
  );

  return {loading, data, error};
}

function useSeriesOrSeasonQuery(opts: {selectedSeriesId?: string | null}) {
  const seriesQuery = useSeriesQuery({skip: !!opts.selectedSeriesId});
  const seasonQuery = useSeasonQuery(opts.selectedSeriesId || undefined);

  if (opts.selectedSeriesId) {
    return {
      ...seasonQuery,
      items: seasonQuery.data ? seasonQuery.data.series.seasons : null,
    };
  }

  return {
    ...seriesQuery,
    items: seriesQuery.data ? seriesQuery.data.seriesCollection : null,
  };
}

export function useAssignUnitToSeason() {
  return useMutation(
    gql`
      mutation SetSeason($unitId: ID!, $seasonId: ID!) {
        assignUnitToSeason(unitId: $unitId, seasonId: $seasonId) {
          unit {
            id
            season {
              id
              name
              series {
                id
                name
              }
            }
          }
        }
      }
    `,
    {
      variables: {},
    }
  );
}

type SelectedItem = {seriesId: string; seasonId?: string};

export function AssignSeriesDialog(props: {
  onRequestClose: () => void;
  isOpen: boolean;
  unitId: string;
}) {
  const [selectedItem, setSelectedItem] = useState<SelectedItem | null>(null);
  const [assignUnit, _] = useAssignUnitToSeason();
  const handleSubmit = useCallback(async () => {
    if (!selectedItem || selectedItem.seasonId === null) {
      return;
    }
    await assignUnit({
      variables: {
        unitId: props.unitId,
        seasonId: selectedItem.seasonId,
      },
    });
    props.onRequestClose();
  }, [props.unitId, selectedItem]);

  const prevIsOpen = usePrevious(props.isOpen);
  useEffect(() => {
    if (!prevIsOpen && props.isOpen) {
      setSelectedItem(null);
    }
  }, [prevIsOpen, props.isOpen, setSelectedItem]);

  return (
    <Dialog onClose={props.onRequestClose} open={props.isOpen}>
      <DialogTitle>Assign a series</DialogTitle>

      <DialogContent
        selectedItem={selectedItem}
        onSelectedItemChange={setSelectedItem}
      />

      <DialogActions>
        <Button onClick={props.onRequestClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={!selectedItem || selectedItem.seriesId === null}
        >
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function DialogContent(props: {
  selectedItem: SelectedItem | null;
  onSelectedItemChange: (item: null | SelectedItem) => void;
}) {
  const {selectedItem, onSelectedItemChange} = props;
  const selectedSeriesId = selectedItem ? selectedItem.seriesId : null;
  const query = useSeriesOrSeasonQuery({
    selectedSeriesId,
  });

  const handleChange = useCallback(
    (item) => {
      if (item === "back") {
        onSelectedItemChange(null);
      } else if (item.__typename == "Series") {
        onSelectedItemChange({seriesId: item.id});
      } else {
        onSelectedItemChange({...selectedItem!, seasonId: item.id});
      }
    },
    [selectedItem, onSelectedItemChange]
  );

  let items: any[] = query.items;
  if (!selectedSeriesId && items) {
    items = [
      {
        name: "Remove",
        id: "",
      },
      ...items,
    ];
  }

  return (
    <GenericFilterDropdown<SelectedItem, any>
      selectedItem={selectedItem}
      onSelectedItemChange={handleChange}
      loading={query.loading}
      items={items}
    />
  );
}
