import React, {useContext} from "react";
import {ApolloProvider} from "multi-apollo";
import {ApolloProvider as OrgApolloProvider} from "@apollo/client";
import {ApolloClient, createHttpLink, InMemoryCache} from "@apollo/client";

export const DataProviderContext = React.createContext(null);

export function DataProviderRoot(props: any) {
  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_HOSTING_URL}/api/`,
  });
  const cache = new InMemoryCache({});

  const hostingClient = React.useMemo(
    () => new ApolloClient({link: httpLink, cache}),
    []
  );

  return (
    <DataProviderContext.Provider value={props.value}>
      <ApolloProvider
        clients={{
          market: props.value.client,
          hosting: hostingClient,
        }}
        defaultClient={props.value.client}
      >
        <OrgApolloProvider client={props.value.client}>
          {props.children}
        </OrgApolloProvider>
      </ApolloProvider>
    </DataProviderContext.Provider>
  );
}

export function useDataProvider() {
  return useContext(DataProviderContext);
}
