import {useQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {default as React, useState} from "react";
import MuiDownshift from "mui-downshift";
import {ListItem, ListItemText} from "@material-ui/core";

function useSiteQuery() {
  const query = useQuery(
    gql`
      query SiteQuery {
        sites {
          id
          name
        }
      }
    `,
    {
      variables: {},
    }
  );

  return {
    ...query,
    items: query.data ? query.data.sites : null,
  };
}

export function SiteSelector(props: {
  label?: string;
  description?: string;
  selectedId: string;
  onChange: (value: string) => void;
}) {
  const {loading, items} = useSiteQuery();
  const [search, setSearch] = useState("");

  const showError = false;
  const fieldError = "";

  const selectedItem = React.useMemo(() => {
    return {
      label: props.selectedId,
      value: props.selectedId,
    };
  }, [props.selectedId]);

  return (
    <MuiDownshift
      selectedItem={selectedItem}
      items={items}
      getListItem={({getItemProps, item}: any) =>
        item ? (
          <ListItem button {...getItemProps()}>
            <ListItemText primary={item.name} />
          </ListItem>
        ) : loading ? (
          <ListItem button disabled>
            <ListItemText
              primary={<span style={{fontStyle: "italic"}}>Loading...</span>}
            />
          </ListItem>
        ) : items === null ? (
          <ListItem button disabled>
            <ListItemText
              primary={
                <span style={{fontStyle: "italic"}}>Type to search</span>
              }
            />
          </ListItem>
        ) : (
          <ListItem button disabled>
            <ListItemText
              primary={
                <span style={{fontStyle: "italic"}}>No words found</span>
              }
            />
          </ListItem>
        )
      }
      getInputProps={() => {
        return {
          label: props.label || "Select Site",
          error: showError,
          helperText: showError ? fieldError : props.description,
        };
      }}
      showEmpty
      includeFooter={loading}
      loading={loading}
      onStateChange={(changes: any) => {
        if (changes.selectedItem !== undefined) {
          props.onChange(changes.selectedItem ? changes.selectedItem.id : "");
        }
        // if (changes.isOpen) {
        //   props.form.setFieldTouched(props.field.name, true);
        // }
        if (typeof changes.inputValue === "string") {
          setSearch(changes.inputValue);
        } else if (changes.isOpen !== undefined && items == null) {
        }
      }}
    />
  );
}
