import {OpenInEditorButton} from "../../components/OpenInEditorButton";
import * as React from "react";
import {EditButton} from "react-admin";
import {UnitShowQuery_unit} from "../../types/UnitShowQuery";
import {formatDuration} from "../../components/utils/formatDuration";
import {PlayButton} from "./PlayButton";

export function Header(props: {record: UnitShowQuery_unit} & any) {
  if (!props.record) {
    return null;
  }

  // Remove any props that DeleteButton does not support (and would pass to the DOM)
  const {isLoading, defaultTitle, loaded, loading, ...otherProps} = props;

  return (
    <div
      style={{
        paddingTop: "15px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {props.record.mediaTrack ? (
        <div>
          <strong>Runtime:</strong>{" "}
          {formatDuration(props.record.mediaTrack?.runtimeSeconds)}
        </div>
      ) : null}

      {props.record.difficulty ? (
        <div>
          <strong>Difficulty:</strong> {props.record.difficulty.toFixed(2)}
        </div>
      ) : null}

      <div>
        <PlayButton unitId={props.record.id} />
        <OpenInEditorButton unitId={props.record.id} />
        <EditButton {...otherProps} />
        <span style={{fontSize: "smaller", marginLeft: "20px"}}>
          To delete, use the database manually for now.
        </span>
      </div>
    </div>
  );
}
