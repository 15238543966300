import React, {Children, cloneElement, isValidElement} from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";

export class UserMenu extends React.Component<any> {
  static defaultProps = {
    label: "ra.auth.user_menu",
    icon: <AccountCircle />,
  };

  state = {
    auth: true,
    anchorEl: null,
  };

  handleChange = (event: any, checked: any) => {
    this.setState({auth: checked});
  };

  handleMenu = (event: any) => {
    this.setState({anchorEl: event.currentTarget});
  };

  handleClose = () => {
    this.setState({anchorEl: null});
  };

  render() {
    const {children, label, icon, logout} = this.props;
    if (!logout && !children) return null;
    const {anchorEl} = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        <Tooltip title={label}>
          <IconButton
            aria-label={label}
            aria-owns={open ? "menu-appbar" : undefined}
            aria-haspopup={true}
            color="inherit"
            onClick={this.handleMenu}
          >
            {icon}
          </IconButton>
        </Tooltip>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={this.handleClose}
        >
          {Children.map(children, (menuItem) =>
            isValidElement(menuItem)
              ? cloneElement(menuItem, {
                  onClick: () => {
                    if (menuItem.props.onClick) {
                      menuItem.props.onClick();
                    }
                    this.handleClose();
                  },
                })
              : null
          )}
          {logout}
        </Menu>
      </div>
    );
  }
}
