import {useMutation} from "@apollo/client";
import {gql} from "@apollo/client";

export function useAssignToSite() {
  return useMutation(
    gql`
      mutation AssignTag(
        $seriesId: ID
        $unitId: ID
        $siteId: ID!
        $tags: [String]
      ) {
        assignToSite(
          seriesId: $seriesId
          unitId: $unitId
          siteId: $siteId
          tags: $tags
        ) {
          assignment {
            tags
          }
        }
      }
    `,
    {}
  );
}

export function useDeleteTagAssignment() {
  return useMutation(
    gql`
      mutation DeleteTag($seriesId: ID, $unitId: ID, $siteId: ID!) {
        removeSiteAssignment(
          seriesId: $seriesId
          unitId: $unitId
          siteId: $siteId
        ) {
          site {
            id
          }
        }
      }
    `,
    {}
  );
}
