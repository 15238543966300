import React from "react";
import {List, Datagrid, TextField, ListView} from "react-admin";
import {gql as gqlm} from "@apollo/client";
import {useApolloListController} from "../dataProvider/apolloControllers";

export const Query = gqlm`
  query EditorListQuery {
    editors {      
      id,
      name      
    }
  }
`;

export const EditorList = (props: any) => {
  const controllerProps = useApolloListController({
    ...props,
    query: Query,
    getData: (data) => data.seriesCollection,
    getId: "id",
  });

  return (
    <ListView {...props} {...controllerProps}>
      <Datagrid rowClick="show">
        <TextField source="name" />
      </Datagrid>
    </ListView>
  );
};
