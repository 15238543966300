/**
 * A unit is a "lesson" - one song, video, poem.
 *
 * A unit has the following parts:
 *
 * 1) Metadata that describes it (such as name, author, description in multiple languages).
 *
 * 2) At least one caption track, possibly versions in multiple languages.
 *
 * 3) Optionally, one or more media tracks, such as audio and video, in multiple languages.
 *    For each language that has a video track, a caption track should also exist.
 */


import {CUSTOM_MEDIA_SOURCE_TYPES, MediaSource} from "./MediaSource";
import {Captions} from "./Captions";
import {splitLangCode} from "../i18n";
import {CaptionTrackTranslation} from "./formats/CaptionTrackTranslation";
import {makeYoutubeUrl} from "../contrib/Youtube";


// What is really the point of this being a class rather than simple data structure?
export class Unit {
  private sources: Map<string, MediaSource>
  private captions: Map<string, Captions>
  private translations: Map<string, CaptionTrackTranslation>

  constructor(opts: {
    sources?: MediaSource[],
    captions?: (Captions|{lang: string, data: any})[],
    translations?: CaptionTrackTranslation[]
  } = {}) {
    this.sources = new Map();
    this.captions = new Map();
    this.translations = new Map();

    if (opts.sources) {
      for (let source of opts.sources) {
        this.addSource(source);
      }
    }
    if (opts.captions) {
      for (let source of opts.captions) {
        this.addCaptionTrack(source);
      }
    }
    if (opts.translations) {
      for (let translation of opts.translations) {
        this.addTranslation(translation);
      }
    }
  }

  // TODO: Move to single language per unit model.
  get language(): string {
    return Array.from(this.sources.values())?.[0]?.lang ?? "";
  }


  addSource(source: MediaSource) {
    this.sources.set(source.lang, source);
  }

  addTranslation(translation: CaptionTrackTranslation) {
    this.translations.set(translation.lang, translation);
  }

  addCaptionTrack(captionTack: Captions|{lang: string, data: any}) {
    if (!(captionTack instanceof Captions)) {
      captionTack = new Captions(captionTack.lang, captionTack.data);
    }
    const lang = splitLangCode(captionTack.language);
    this.captions.set(lang.complete, captionTack);
    this.captions.set(lang.main, captionTack);
  }

  getMediaTrack(lang?: string): MediaSource|null {
    return this.sources.get(lang || this.language) || null
  }

  getMediaTracks(): MediaSource[] {
    return Array.from(this.sources.values());
  }

  getCaptions(lang?: string) {
    if (!lang) {
      lang = this.language;
    }
    return getFromLangMap(this.captions, lang);
  }

  hasCaptionsForLang(lang: string) {
    return this.captions.has(lang);
  }

  hasTranslationsForLang(lang: string) {
    return this.translations.has(lang);
  }

  getTranslation(lang: string): CaptionTrackTranslation|null {
    return this.translations.get(lang) || null;
  }
}


function getFromLangMap<T>(map: Map<string, T>, lang: string) {
  if (map.has(lang)) {
    return map.get(lang);
  }
  const code = splitLangCode(lang);
  return  map.get(code.main);
}
