import * as React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import {useQuery} from "@apollo/client";
import {gql as gqlm} from "@apollo/client";
import {
  PublicMarker,
  ReviewedCheckMark,
  TrackVersionList,
} from "./TrackVersionList";
import {useMutation} from "multi-apollo";
import {useAuth0} from "../../components/Auth0Provider";

const useTracks = (unitId: string) => {
  const {data, error, loading, refetch} = useQuery(
    gqlm`
      query TracksQuery($unitId: ID!) {
        unit(id: $unitId) {
          id
          captionTracks {
            id
            language
          }
        }
      }
    `,
    {
      variables: {
        unitId: unitId,
      },
    }
  );

  return {tracks: data && data.unit ? data.unit.captionTracks : null, loading};
};

export function TrackList(props: {unitId: string}) {
  const {tracks, loading} = useTracks(props.unitId);
  const createCaptionTrack = useCreateCaptionTrack();

  if (loading) {
    return <div>...</div>;
  }

  return (
    <div style={{paddingTop: "20px"}}>
      <div style={{marginBottom: "20px"}}>
        Meaning of flags and states:
        <li>
          <ReviewedCheckMark />
          <strong>Reviewed</strong>: Once reviewed, the track cannot be changed
          anymore and must be copied to a new version. This is the base of our
          versioning system; it is essentially the distinction between old
          versions and new ones.
        </li>
        <li>
          <PublicMarker />: The version currently visible by the user. Should be
          reviewed, but does not have to be.
        </li>
        <li>
          <strong>Text Ready</strong>: An internal flag to indicate that the
          subtitle work has been finished. If the work was been finished
          initially, but we then want to do further changes, the flag can be
          removed from newer versions. In the future, this might move to the
          track itself, not the unit.
        </li>
        <li>
          <strong>Grammar Ready</strong>: Like text ready, but for the grammar.
        </li>
      </div>

      {!tracks.length ? (
        <div>
          <Button
            onClick={async () => {
              const {trackId, versionId} = await createCaptionTrack(
                props.unitId,
                "fa"
              );
              alert("created!");
            }}
          >
            New Track
          </Button>
        </div>
      ) : null}

      {tracks.map((track: any) => {
        return (
          <Card key={track.id}>
            <CardHeader title={track.language} />
            <CardContent>
              <TrackVersionList trackId={track.id} />
            </CardContent>
          </Card>
        );
      })}

      <VideoExport unitId={props.unitId} />
    </div>
  );
}

export function useCreateCaptionTrack() {
  const query = gqlm`
    mutation CreateCaptionTrack($unitId: String!, $language: String!) {
      createCaptionTrack(unitId: $unitId, language: $language) {
        captionTrack {
          id,
          latestVersion {
            id
          }
        } 
      }
    }
  `;

  const [createCaptionTrack, {data: mutationData}] = useMutation(query);

  return async (unitId: string, language: string) => {
    const response = await createCaptionTrack({
      variables: {
        unitId: unitId,
        language,
      },
    });
    return {
      trackId: response.data.createCaptionTrack.captionTrack.id,
      versionId: response.data.createCaptionTrack.captionTrack.latestVersion.id,
    };
  };
}

export function VideoExport(props: {unitId: string}) {
  const AEHost = "https://ae.srvpl.de";
  const videoRenderServiceKey =
    "daeg3zai6iaregau6eeghoodeeyeiqu9ieW2piedi4Aechie6oreedie3quakasa";
  const wordsBasedUrl = "https://market-admin.languagetool.xyz";
  // const AEHost = "http://localhost:3090";
  // const videoRenderServiceKey = "foobar";
  // const wordsBasedUrl = "http://localhost:3000";

  const {getTokenSilently} = useAuth0();
  const [active, setActive] = React.useState(false);
  const [fileId, setFileId] = React.useState("");
  const [firstLine, setFirstLine] = React.useState<number | null>(null);
  const [lastLine, setLastLine] = React.useState<number | null>(null);
  const [useTranslation, setUseTranslation] = React.useState<boolean>(false);
  const getUrl = (auth?: string) => {
    const anchor = `#/render?unitId=${props.unitId}&start=${
      firstLine ?? ""
    }&end=${lastLine ?? ""}&useTranslation=${useTranslation}`;
    if (auth) {
      return `${wordsBasedUrl}/?token=${auth}${anchor}`;
    }
    return `${wordsBasedUrl}/${anchor}`;
  };

  return (
    <div
      style={{
        flexDirection: "column",
        padding: "20px",
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={useTranslation}
            onChange={(e: any) => {
              setUseTranslation(e.target.checked);
            }}
            name="useTranslation"
          />
        }
        label="Use Translation"
      />
      <div>
        First Line:{" "}
        <TextField
          value={firstLine}
          onChange={(e: any) => {
            setFirstLine(parseInt(e.target.value));
          }}
        />
      </div>
      <div>
        Last Line:{" "}
        <TextField
          value={lastLine}
          onChange={(e: any) => {
            setLastLine(parseInt(e.target.value));
          }}
        />
      </div>

      <div>
        <Button
          onClick={async () => {
            setActive(true);
            try {
              const token = await getTokenSilently();
              const url = getUrl(token);
              const result = await fetch(
                `${AEHost}/api/generate?url=${encodeURIComponent(url)}`,
                {
                  headers: {Authorization: videoRenderServiceKey},
                }
              );
              const data = await result.json();

              while (true) {
                try {
                  let result2 = await fetch(
                    `${AEHost}/api/status?id=${data.id}`,
                    {
                      headers: {Authorization: videoRenderServiceKey},
                    }
                  );
                  break;
                } catch (e) {}
              }

              setFileId(data.id);
            } catch (e) {
              console.log(e);
              alert("An error occurred");
            } finally {
              setActive(false);
            }
          }}
          disabled={active}
        >
          Create Video
        </Button>

        <a href={getUrl()} target={"_blank"}>
          Try First
        </a>

        {active ? <div>generating...</div> : null}

        {fileId ? (
          <div>
            Download:{" "}
            <a href={`${AEHost}/api/download?id=${fileId}`}>{fileId}</a>
          </div>
        ) : null}
      </div>
    </div>
  );
}
