/**
 * To be flexible in how we build our UIs we use the context + smart components that can then
 * be arranged at will. Based on the media type, different things are required in the context.
 *
 * This is a "shared, basic" context type that is always available, regardless of the  media type.
 */

import {PopupAPI, PopupState} from "./Popup";
import {IAnnotationLoader} from "../../annotations/loader";
import {NodeResolver} from "../WordPopup/PopupContext";


export interface IUIController {
  next: () => void,
  prev: () => void
}

export interface IPlayerContext {
  // For components that want to load annotations will use this, such as the WordPopup.
  annotationsLoader?: IAnnotationLoader,
  resolver?: NodeResolver,

  // Setting a default language is necessary when
  defaultLanguage?: string,

  popupState: PopupState,
  popupAPI?: PopupAPI,
  setPopupState: (newState: PopupState) => void,
  setPopupAPI: (controller: PopupAPI|null) => void,

  uiController?: IUIController,
}

export const DefaultState = {
  popupState: {
    isOpen: false,
    clickedVocable: null,
    clickedNodes: null,
    clickedSubtitleElement: null,
  }
}