/**
 * The player itself can seek to particular seconds.
 *
 * This on top of it can seek on a line-by-line basis.
 * It also controls the playback in more advanced ways:
 *
 * - You can lock a line.
 */

import * as React from "react";
import {useMemo} from 'react';
import {PlayerAPI} from "./Video";
import {Captions, LineTimeBoundary} from "../models/Captions";
import update from 'immutability-helper';
import { useState, useCallback } from "react";
import * as loglevel from 'loglevel';

const log = loglevel.getLogger("UIController")
log.setLevel("DEBUG")

type LineLockState = {
  // If there is a single line that should be played extra-slow
  slowedDownLineIndex: number|null,

  // The current section the user is repeating
  isLocked: boolean,
  whenBeyond: number,
  startOverAt: number,
  pauseFor: number,

  // The repeating area is stored as timestamps, but this is the line that we should lock in the subtitle view.
  lineIndex: number,
};


// This is like the config.
export type UIState = {
  // How much the user wants to slow down the play speed during normal play.
  slowdownFactor: number,

  // continuous = we just keep playing
  // manual = we play only one line. the user has to move on to the next.
  advanceMode: 'continuous'|'manual',
  currentPosition?: {
    lineIndex: number
  },

  // If playback is controlled (a certain number of automatic repeats etc,
  // this collects all the state information regarding it).
  lockState: LineLockState,

  // Various pieces of logic may automatically pause the playback.
  isAutoPaused?: boolean,
  // They may also schedule a resume of playback.
  scheduledPlay?: {
    from: number,
    timerId: number,
  }
};


export interface UIControllerAPI {
  next: () => void,
  prev: () => void,
  goToLine: (idx: number) => void,
  lock: () => void,
  unlock: () => void,
  setSlowdownFactor: (factor: number) => void,
  setManualMode: (isOn: boolean) => void,
}


export const NoopController: UIControllerAPI = {
  lock: function () {},
  setManualMode: function() {},
  next: function () {},
  prev: function () {},
  goToLine: function (idx: number) {},
  setSlowdownFactor: function (p1: number) {},
  unlock: function () {}
}


type Props = {
  playerAPI?: PlayerAPI,
  captions?: Captions,
};


function useKeepRefUpToDate<D>(val: D) {
  const ref = React.useRef<D>(val);
  React.useEffect(() => {
    ref.current = val;
  });
  return ref;
}


const defaultState: UIState = {
  slowdownFactor: 0,
  advanceMode: 'continuous',

  // We keep this separate for now because it is generic enough to allow repeat anything,
  // but the normal "repeat by line" mode seems very clear to me that it should be moved
  // to a sub,pde of the "continuous" advance mode.
  lockState: {
    isLocked: false,
    lineIndex: 0,
    whenBeyond: 0,
    startOverAt: 0,
    pauseFor: 2,
    slowedDownLineIndex: null
  }
};


type SetStateFunc = (s: (s: UIState) => UIState) => void;


function useSchedulePlay(props: {
  playerAPI?: PlayerAPI,
  state: UIState,
  setState: SetStateFunc,
}) {
  const {state, setState} = props;

  const cancelScheduledPlay = React.useCallback(() => {
    if (state.scheduledPlay) {
      window.clearTimeout(state.scheduledPlay.timerId);
      setState(state => update(state, {$unset: ['scheduledPlay']}));
    }
  }, [setState, state.scheduledPlay?.timerId]);

  // This will schedule that we begin to play at a certain point in the future.
  const schedulePlay = React.useCallback((opts: {
    from: number,
    in: number
  }) => {
    cancelScheduledPlay();
    const event = window.setTimeout(() => {
      setState(state => update(state, {$unset: ['scheduledPlay']}));
      props.playerAPI!.seekTo(opts.from);
      props.playerAPI!.play();
    }, opts.in);
    setState(state => update(state, {
      scheduledPlay: {$set: {
          timerId: event,
          from: opts.in
        }}
    }));
  }, [setState, props.playerAPI, cancelScheduledPlay]);

  return {
    cancelScheduledPlay,
    schedulePlay
  }
}

/**
 * This controls the audio play.
 *
 * - It subscribes to the player time.
 * - It exposes a "controller" interface via a ref.
 *
 * Calling that interface you can do certain things such as "jump back", "jump forward", "lock subtitle".
 */
export function useMakeUIController(props: Props) {
  const [state, setState] = useState<UIState>(defaultState);
  const time = React.useRef<number>(0);
  const {playerAPI} = props;

  // Helpers
  const setAutoPaused = useSetAutoPaused({setState});
  const autoPause = useAutoPause({
    setAutoPaused,
    playerAPI
  })

  // Manage scheduling auto-pause/auto-play.
  const {cancelScheduledPlay, schedulePlay} = useSchedulePlay({
    playerAPI: props.playerAPI,
    state,
    setState
  });

  // Not sure if this is a problem now that we made this
  const setJumpToTimeTarget = useCallback(async (targetTime: number) => {
    cancelScheduledPlay();

    // xxx if in manual advance mode, re-init here
    const lineIndex = props.captions?.getLineIndexForTime(targetTime);
    log.info('UIController: setState "currentPosition" to ' + JSON.stringify(lineIndex));
    setState(state => update(state, {
      currentPosition: {$set: {
          lineIndex: lineIndex?.current ?? lineIndex?.previous ?? 0
        }}
    }));

    log.info('UIController: Asking playerAPI to seekTo ' + targetTime);
    await props.playerAPI!.seekTo(targetTime);
  }, [props.playerAPI, cancelScheduledPlay, time]);

  const lockIntoBoundary = React.useCallback((lineIndex: number, onlyIfLocked: boolean) => {
    const boundary = props.captions!.getLineTimeBoundary(lineIndex);

    setState(state => {
      if (onlyIfLocked && !state.lockState.isLocked) {
        return state;
      }

      // Jump back as soon as possible.
      // const pauseAfter = (currentLine.nextStart ? currentLine.nextStart - currentLine.end : 0);
      // const endPoint = currentLine.end - pauseAfter;
      const endPoint = boundary.end;

      // But give a sensible amount of pause before the start point of the repeat.
      const pauseBefore = Math.max(boundary.start - (boundary.prevEnd || 0), 0);
      const startPoint = boundary.start - Math.min(pauseBefore, 1);

      return update(state, {
        lockState: {$merge: {
            whenBeyond: endPoint,
            startOverAt: startPoint,
            isLocked: true,
            lineIndex: lineIndex,
          } as Partial<LineLockState>}
      })
    });

    return boundary;
  }, [setState, props.captions]);

  const goToLine = React.useCallback(async (lineIndex: number) => {
    if (!props.captions) {
      return;
    }

    // Move the lock to this boundary, if there is a lock.
    const timeBoundary = lockIntoBoundary(lineIndex, true);

    // Jump to the new location
    await setJumpToTimeTarget(timeBoundary.start);

    log.info('goToLine(): Forgetting auto-paused.')
    setAutoPaused(false);
  }, [props.captions, lockIntoBoundary, setJumpToTimeTarget, setAutoPaused]);

  // We do not want to resubscribe every time something happens, so we'd rather
  // keep the state in a local instance variable.
  const stateRef = useKeepRefUpToDate<UIState>(state);

  const base: Base = {
    goToLine,
    time,
    lockIntoBoundary,
    setState,
    stateRef
  }

  const handleUnlock = React.useCallback(() => {
    setState(state => update(state, {
      lockState: {$merge: {
          isLocked: false
        } as Partial<LineLockState>}
    }));
  }, [setState]);

  const instrumentedPlayerAPI = useInstrumentedPlayerAPI({
    cancelScheduledPlay,
    captions: props.captions,
    handleUnlock,
    lockIntoBoundary,
    setState,
    stateRef,
    time,
    playerAPI: props.playerAPI,
    setAutoPaused
  });

  const handleMoveBackward = useMoveBackward(props, base);
  const handleMoveForward = useMoveForward({
    captions: props.captions,
    goToLine,
    instrumentedPlay: instrumentedPlayerAPI?.play,
    stateRef,
    time

  });
  const handleEnableLock = useEnableLock(props.captions, base);

  const handleSetSlowdown = React.useCallback((factor: number) => {
    setState(state => update(state, {
      slowdownFactor: {$set: factor}
    }));
  }, [setState]);

  const setManualMode = React.useCallback((isOn: boolean) => {
    if (isOn) {
      const lineIndex = props.captions?.getLineIndexForTime(time.current);
      setState(state => update(state, {
        currentPosition: {$set: {
          lineIndex: lineIndex?.current ?? lineIndex?.previous ?? 0,
        }}
      }));
    }

    setState(state => update(state, {
      advanceMode: {$set: isOn ? 'manual' : 'continuous'}
    }));
  }, [setState]);

  //////////////////////////////////////////////////////////////////////////

  const controllerObj: UIControllerAPI = {
    next: handleMoveForward,
    prev: handleMoveBackward,
    lock: handleEnableLock,
    unlock: handleUnlock,
    goToLine,
    setSlowdownFactor: handleSetSlowdown,
    setManualMode
  };

  React.useEffect(() => {
    if (props.playerAPI) {
      return props.playerAPI.time$.onValue(
        timestamp => {
          time.current = timestamp;

          // Handle any locks for the time.
          handleTimeChanged({
            captions: props.captions,
            playerAPI: props.playerAPI,
            state: stateRef.current,
            setAutoPaused,
            autoPause,
            setState,
            schedulePlay
          }, timestamp);
        });
    }
  }, [props.playerAPI, stateRef.current, time, handleTimeChanged, setState, setAutoPaused, autoPause]);

  //////////////////////////////////////////////////////////////////////////
  // The stuff below uses the actual live props, not the cached ones.

  // If the playback speed is a prop this would be easier, we could just do the math in render.
  React.useEffect(() => {
    if (!props.playerAPI || !props.captions) {
      return;
    }
    let newSpeed = (1 - state.slowdownFactor * 0.2);
    if (isCurrentLineSlowedDown(state, props.captions, time.current)) {
      newSpeed = newSpeed / 1.5;
    }
    props.playerAPI.setPlaybackRate(newSpeed);
  }, [props.playerAPI, state.slowdownFactor]);

  return {
    state,
    controller: controllerObj,
    playerAPI: instrumentedPlayerAPI
  };
}

/**
 * This overrides some members of the player API, which allows us to hook into when those actions happen.
 *
 * A better way might be for the player API to expose those things as events.
 */
function useInstrumentedPlayerAPI(props: {
  playerAPI: PlayerAPI|undefined,
  handleUnlock: () => void,
  cancelScheduledPlay: () => void,
  captions: Captions|undefined,
  time: React.MutableRefObject<number>,
  setState: SetStateFunc,
  stateRef: React.MutableRefObject<UIState>,
  lockIntoBoundary: any,
  setAutoPaused: ReturnType<typeof useSetAutoPaused>
}) {
  const {cancelScheduledPlay, handleUnlock, lockIntoBoundary, captions, time, setState, stateRef, playerAPI,
    setAutoPaused} = props;

  const seekTo = useCallback(async (time: number) => {
    log.info("Manual seek, unlocking and forgetting auto-pause.")
    handleUnlock();
    setAutoPaused(false);
    playerAPI?.seekTo(time);
  }, [playerAPI?.seekTo, handleUnlock, setAutoPaused]);

  const play = useCallback(() => {
    // "Play" while at the end of the current section in manual mode means, advance to the next section.
    if (stateRef.current.advanceMode === 'manual') {
      const lineIndex = stateRef.current.currentPosition?.lineIndex;
      if (lineIndex !== undefined && captions) {
        const timeBoundary = captions.getLineTimeBoundary(lineIndex);
        if (time.current >= timeBoundary.end) {
          log.info("play(): Play time is after current position boundary, move to next position.")
          const idx = captions.getLineIndexForTime(time.current)
          setState(state => {
            return update(state, {
              isAutoPaused: {$set: false},
              currentPosition: {$set: {
                  lineIndex: idx?.current ?? idx?.next ?? 0
                }}
            })
          })
        }
      }
    }

    // If the user manually requests to play while we are waiting for a repeat of the currently
    // locked line, we take this as a sign to break the repeat by moving the lock to the next line.
    if (isOutOfBoundary(stateRef.current, time.current)) {
      const lineIndex = captions!.getLineIndexForTime(time.current);
      log.info("play(): Breaking lock of current line and moving on.");
      cancelScheduledPlay();
      if (lineIndex.next !== null) {
        log.info(`play(): Moving on means locking to the next line, which is ${lineIndex.next}`)
        lockIntoBoundary(lineIndex.next, true);
      } else {
        log.info("play(): Moving on means unlocking, since there is no next line.")
        handleUnlock();
      }
    }

    playerAPI?.play();
  }, [
    props.playerAPI,
    stateRef,
    setState,
    time,
    captions,
    lockIntoBoundary,
    cancelScheduledPlay,
    handleUnlock,
    playerAPI?.play
  ]);

  return useMemo<PlayerAPI|undefined>(() => {
    if (!props.playerAPI) {
      return;
    }

    return {
      ...props.playerAPI,
      seekTo,
      play,
    }
  }, [props.playerAPI, seekTo, play]);
}


function useSetAutoPaused(props: {
  setState: SetStateFunc
}) {
  return useCallback((isAutoPaused?: boolean) => {
    return props.setState(state => update(state, {
      isAutoPaused: {$set: isAutoPaused}
    }));
  }, [props.setState]);
}

function useAutoPause(props: {
  setAutoPaused: ReturnType<typeof useSetAutoPaused>,
  playerAPI: PlayerAPI|undefined
}) {
  return useCallback(() => {
    props.setAutoPaused(true);
    props.playerAPI?.pause();
  }, [props.setAutoPaused, props.playerAPI]);
}

type Base = {
  time: React.MutableRefObject<number>,
  goToLine: (n: number) => void,
  lockIntoBoundary: (lineIndex: number, onlyIfLocked: boolean) => void,
  setState: SetStateFunc,
  stateRef: React.MutableRefObject<UIState>
}

function useMoveBackward(props: Props, base: Base) {
  const {time, goToLine, stateRef} = base;

  return React.useCallback(async () => {
    if (!props.captions) {
      return;
    }

    // Get the current line
    const lineIndex = props.captions.getLineIndexForTime(time.current);

    let lineIndexToGoTo: number;
    let slowDown: boolean = false;

    // If there is no current, jump to the beginning of prev
    if (lineIndex.current == null && lineIndex.previous !== null) {
      lineIndexToGoTo = lineIndex.previous;
    }
    else if (lineIndex.current !== null) {
      // If there is a current one, how close are we do it?
      const currentBoundary = props.captions.getLineTimeBoundary(lineIndex.current);
      const isCloseToStart = time.current - currentBoundary.start < 0.9;
      if (isCloseToStart && lineIndex.previous !== null) {
        lineIndexToGoTo = lineIndex.previous;
      }
      else {
        // Go to the beginning of the current line
        lineIndexToGoTo = lineIndex.current;
        slowDown = true;
      }
    }
    else {
      return;
    }

    if (slowDown) {
      base.setState((state) => update(state, {
        lockState: {
          $merge: {
            slowedDownLineIndex: lineIndexToGoTo
          }
        }
      }));
    }

    const wasAutoPaused = stateRef.current.isAutoPaused;

    await goToLine(lineIndexToGoTo);

    if (wasAutoPaused) {
      log.info('moveBackward(): Auto-play due to auto-pause enabled.')
      props.playerAPI?.play();
    }
  }, [time, props.captions, goToLine, base.setState, props.playerAPI, stateRef]);
}

/**
 * "Jump forward" command.
 *
 * Jumps lines, but could conceivably jump sentences.
 */
function useMoveForward(props: {
  time: React.MutableRefObject<number>,
  goToLine: (n: number) => void,
  stateRef: React.MutableRefObject<UIState>,
  captions: Captions|undefined,
  instrumentedPlay: undefined|(() => void)
}) {
  const {time, goToLine, stateRef, captions, instrumentedPlay} = props;

  return React.useCallback(() => {
    if (!captions) {
      return;
    }

    if (stateRef.current.isAutoPaused) {
      log.info("moveForward(): Acting as play button due to auto-pause being on.")
      instrumentedPlay?.();
      return;
    }

    // Get the current line
    const lineIndex = captions.getLineIndexForTime(time.current);

    // If there is no next line, we are done.
    if (lineIndex.next === null) {
      return;
    }

    goToLine(lineIndex.next);
  }, [goToLine, time, stateRef, instrumentedPlay, captions]);
}


function useEnableLock(captions: Captions|undefined, base: Base) {
  const {time, lockIntoBoundary} = base;
  return React.useCallback(() => {
    if (!captions) {
      return;
    }
    const lineIndex = captions.getLineIndexForTime(time.current);
    const lineToLock = lineIndex.current !== null ? lineIndex.current : lineIndex.next;
    if (lineToLock === null) {
      return;
    }
    lockIntoBoundary(lineToLock, false);
  }, [captions, time, lockIntoBoundary]);
}


/**
 * As we play, execute the required actions.
 */
function handleTimeChanged(
  opts: {
    captions: Captions|undefined,
    playerAPI: undefined|PlayerAPI,
    state: UIState,
    setAutoPaused: (paused?: boolean) => void,
    autoPause: ReturnType<typeof useAutoPause>,
    setState: (s: (s: UIState) => UIState) => void,
    schedulePlay: any
  },
  time: number)
{
  const {state, playerAPI, schedulePlay, captions, setState} = opts
  const {lockState} = state;

  // Do not see how this can even happen
  if (!playerAPI || !captions) { return; }

  // Test if the new time is still within the locked boundary, if any. If so, we jump to the beginning.
  const desiredJumpTarget = isOutOfBoundary(state, time);
  if (desiredJumpTarget !== false) {
    if (lockState.pauseFor > 0) {
      opts.autoPause();

      // NB: This may easily call the scheduler multiple times until the state really updates.
      if (!state.scheduledPlay) {
        schedulePlay({
          from: desiredJumpTarget,
          in: lockState.pauseFor * 1000
        })
      }
    }
    else {
      playerAPI!.seekTo(desiredJumpTarget);
    }
  }

  // Test if we are outside of the line that was targeted for slowdown play specifically. if so, go back
  // to normal speed.
  if (lockState.slowedDownLineIndex !== null && captions) {
    const lineIndex = captions.getLineIndexForTime(playerAPI!.getCurrentTime());
    if (!lineIndex.current || lineIndex.current != lockState.slowedDownLineIndex) {
      setState(state => update(state, {
        lockState: {
          $merge: {
            slowedDownLineIndex: null
          }
        }
      }));
    }
  }

  autoPauseInManualModeIfEndOfCurrentLineReached({
    state,
    captions,
    time,
    autoPause: opts.autoPause
  })
}


function autoPauseInManualModeIfEndOfCurrentLineReached(props: {
  state: UIState,
  time: number,
  captions: Captions,
  autoPause: ReturnType<typeof useAutoPause>,
}) {
  const {state, time, captions} = props;

  if (state.advanceMode === 'manual') {
    const lineIndex = state.currentPosition?.lineIndex;
    if (lineIndex !== undefined) {
      const timeBoundary = captions.getLineTimeBoundary(lineIndex);
      if (time >= timeBoundary.end) {
        log.info("Play time is after current position boundary, auto-pause.")
        props.autoPause();
      }
    }
  }
}


/**
 * If there is a boundary lock and `time` is not of it, return the jump-to point, otherwise false.
 */
function isOutOfBoundary(state: UIState, time: number): number|false {
  const controlledPlay = state.lockState;

  if (!controlledPlay.isLocked) {
    return false;
  }

  if (time > controlledPlay.whenBeyond) {
    return controlledPlay.startOverAt;
  }

  return false;
}


function isCurrentLineSlowedDown(state: UIState, captions: Captions, time: number) {
  const index = state.lockState.slowedDownLineIndex;
  if (index === null) {
    return false;
  }
  const lineIndex = captions.getLineIndexForTime(time);
  return (index === lineIndex.current);
}