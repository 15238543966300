import React, {useContext, useCallback} from "react";
import {__RouterContext} from "react-router";

type WithEvent = (e: any) => void;
type WithoutEvent = (url: string) => void;

export function useRedirect(): WithoutEvent;
export function useRedirect(url: string): WithEvent;
export function useRedirect(url?: any): WithEvent {
  const router = useContext(__RouterContext);

  return useCallback(
    (arg1?: any, arg2?: any) => {
      let path;
      if (url) {
        path = url;
      } else {
        path = arg1;
      }

      router.history.push(path);
    },
    [url]
  );
}
