import {registerCustomMediaSourceType} from "../models/MediaSource";


/**
 * For a given internal video url, return the video url.
 */
export async function getVideoInfo(id: string, opts: {url: string}): Promise<{
  url: string
  audioOnly?: boolean
}> {
  const query = `
    query {
      upload(id: "${id}") {
        url,
        type
      }
    }
  `;

  let {url, type} = (await graphqlQuery({query, endpoint: opts.url})).upload;
  return {url, audioOnly: type.startsWith('audio')};
}


/**
 * Call this once to make sure we can handle market videos.
 */
export function register(opts: {
  hostingApiUrl: string
}) {
  registerCustomMediaSourceType('internal', async (id) => {
    return {
      type: 'url',
      ...(await getVideoInfo(id, {
        url: opts.hostingApiUrl
      }))
    }
  });
}


type Opts = {
  query: string,
  args?: any,
  endpoint: string,
  credentials?: string,
  headers?: any
};


export async function graphqlQuery(opts: Opts) {
  const response = await fetch(opts.endpoint, {
    method: 'POST',
    credentials: opts.credentials || ('include' as any),
    headers: {
      'Content-Type': 'application/json',
      ...opts?.headers
    },
    body: JSON.stringify({
      query: opts.query,
      variables: opts.args
    })
  });

  const json = await response.json();

  if (json.errors) {
    throw new Error(JSON.stringify(json.errors));
  }
  return json.data;
}


export function parseLanguagetoolHostingUrl(url: string): string|false {
  const regExp = /^internal:\/\/(\w+)$/;
  const match = url.match(regExp);
  if (match && match[1]) {
    return match[1];
  }
  return false;
}