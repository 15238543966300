import React, {useCallback} from "react";
import {EditView, TextInput, SimpleForm} from "react-admin";
import {useQuery, useMutation} from "@apollo/client";
import {useApolloEditController} from "../dataProvider/apolloControllers";
import {gql} from "@apollo/client";
import {gql as gqlm} from "@apollo/client";
import {ToolbarWithCustomDelete} from "../components/ToolbarWithCustomDelete";

const useSeason = (seasonId?: string) => {
  return useQuery(
    gql`
      query SeasonQuery($seasonId: ID!) {
        season(id: $seasonId) {
          id
          name
          difficulty
        }
      }
    `,
    {
      skip: !seasonId,
      variables: {
        seasonId,
      },
    }
  );
};

const GET_SEASONS = gql`
  query GetSeries($seriesId: ID!) {
    series(id: $seriesId) {
      __typename
      seasons(includeNotReady: true) {
        __typename
        id
        name
        difficulty
      }
    }
  }
`;

const useSaveSeason = (seriesId?: string) => {
  return useMutation<any, any>(
    gqlm`
    mutation saveSeason($id: ID, $properties: SeasonProperties!, $seriesId: ID) {
      saveSeason(id: $id, properties: $properties, seriesId: $seriesId) {
        season {
          __typename,
          id,
          name,
          difficulty
        }
      }
    }
  `,
    {
      variables: {
        seriesId,
      },
      update(cache, {data}: any) {
        // @ts-ignore
        const result: any = cache.readQuery({
          query: GET_SEASONS,
          variables: {
            seriesId,
          },
        });

        cache.writeQuery({
          query: GET_SEASONS,
          data: {
            series: {
              ...result.series,
              seasons: (result.series.seasons ?? []).concat([
                data.saveSeason.season,
              ]),
            },
          },
        });
      },
    }
  );
};

const useDeleteSeason = (id: string) => {
  return useMutation<any, any>(
    gql`
      mutation deleteSeason($id: ID!) {
        deleteSeason(id: $id) {
          deletedId
        }
      }
    `,
    {
      variables: {
        id,
      },
    }
  );
};

export const useSeasonEditCreate = (props: {
  id?: string;
  seriesId?: string;
}) => {
  const query = useSeason(props.id);
  const [save, saveOp] = useSaveSeason(props.seriesId);

  const controllerProps = useApolloEditController({
    query,
    saveQuery: saveOp,
    id: props.id,
    resourceName: "seasons",
    getRecord: (data) => data.season,
    onSave: async (data) => {
      await save({
        variables: {
          id: props.id,
          properties: {
            name: data.name,
          },
        },
      });
      return {id: "replace"};
    },
  });
  return controllerProps;
};

export const SeasonEdit = (props: any) => {
  return (
    <EditView {...useSeasonEditCreate(props)}>
      <SeasonEditForm />
    </EditView>
  );
};

export const SeasonEditForm = (props: any) => {
  const {allowStatus, allowReportedTime, isCreate, ...otherProps} = props;

  const [deleteSeason, _] = useDeleteSeason(
    props.record ? props.record.id : null
  );

  return (
    <SimpleForm
      {...otherProps}
      toolbar={<ToolbarWithCustomDelete onDelete={deleteSeason} />}
    >
      <TextInput source="name" />
    </SimpleForm>
  );
};
