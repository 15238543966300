import * as React from "react";
import {Card, CardHeader, CardContent, Button} from "@material-ui/core";
import {useQuery} from "@apollo/client";
import {gql as gqlm} from "@apollo/client";
import {MediaTracksQuery} from "../../../types/MediaTracksQuery";
import {NewUploadArea} from "./UploadArea/NewUploadArea";
import {useMutation} from "multi-apollo";

const useTracks = (unitId: string) => {
  const {data, error, loading, refetch} = useQuery<MediaTracksQuery>(
    gqlm`
      query MediaTracksQuery($unitId: ID!) {
        unit(id: $unitId) {
          id
          mediaTrack {
            id
          }
        }
      }
    `,
    {
      variables: {
        unitId: unitId,
      },
    }
  );

  return {track: data && data.unit ? data.unit.mediaTrack : null, loading};
};

export function MediaTrackTab(props: {unitId: string}) {
  const {track, loading} = useTracks(props.unitId);

  if (loading) {
    return <div>...</div>;
  }

  return (
    <div style={{padding: "20px 0"}}>
      <Card>
        <CardHeader title={"Track"} />
        {track ? (
          <CardContent>This unit has a media track.</CardContent>
        ) : (
          <CardContent>
            <NewTrackContent unitId={props.unitId} />
          </CardContent>
        )}
      </Card>
    </div>
  );
}

function NewTrackContent(props: {unitId: string}) {
  const createMediaTrack = useCreateMediaTrack();

  async function handleUploadComplete(uploadId: string) {
    await createMediaTrack(props.unitId, {
      language: "fa",
      type: "internal",
      url: uploadId,
    });
    alert("Created!");
  }

  return (
    <div>
      <NewUploadArea onUploadComplete={handleUploadComplete} />
    </div>
  );

  return (
    <div>
      <Button onClick={() => {}}>New Media Track</Button>
    </div>
  );
}

export function useCreateMediaTrack() {
  const query = gqlm`
    mutation CreateMediaTrack ($unitId: String!, $options: CreateMediaTrackOptions!) {
      createMediaTrack(unitId: $unitId, options: $options) {
        mediaTrack {
          id
        } 
      }
    }
  `;

  const [createCaptionTrack, {data: mutationData}] = useMutation(query);

  return async (
    unitId: string,
    options: {language: string; type: string; url: string}
  ) => {
    const response = await createCaptionTrack({
      variables: {
        unitId: unitId,
        options,
      },
    });
    return response.data.createMediaTrack.mediaTrack.id;
  };
}
