import {useBaconObservable} from "../utils";
import Bacon from 'baconjs';
import {useMemo} from "react";
import {PlayerAPI} from "../Video";
import {Captions} from "../../models";
import {useCaptionTrackContext} from "../CaptionTrack/Manager";
import {useMakeCurrentLineIndexProperty} from "./useCurrentLineIndex";
import { isEqual } from "lodash";


/**
 * Return the current line index as published by the player context.
 */
export function useCurrentVocableIds() {
  const {currentVocableIds$} = useCaptionTrackContext();
  return useBaconObservable(currentVocableIds$, null);
}


/**
 * Return a Bacon property that exposes the current vocable ids based on the given player
 * state and captions. We will put this into the the player context, so the calculation
 * has to run only once even when used in multiple places.
 */
export function useMakeCurrentVocableIdsProperty(api: PlayerAPI|undefined, captions: Captions|undefined) {
  const currentLineIndex$ = useMakeCurrentLineIndexProperty(api, captions);
  return useMemo<Bacon.Property<any, null|string[]>|null>(() => {
    if (!captions) {
      return null;
    }
    if (!api) {
      return null;
    }
    return Bacon.combineAsArray(api.time$, currentLineIndex$).map(([time, currentLineIndex]) => {
      if (!currentLineIndex || time === null) {
        return null;
      }
      const line = captions.getLine(currentLineIndex);
      const timeRelToLine = time - line.time;
      return line.elements.flatMap(x => x).filter(vocable => {
        if (typeof vocable.time == 'number' && typeof vocable.duration == 'number') {
          if (timeRelToLine > vocable.time && timeRelToLine < (vocable.time + vocable.duration)) {
            return true;
          }
        }

        return false;
      }).map(v => v['id'])
    })
      .skipDuplicates(isEqual);

  }, [api ? api.time$ : null, captions]);
}