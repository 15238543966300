import React, {useContext} from "react";
import {IPlayerContext} from "./Interface";

export const PlayerContext = React.createContext<IPlayerContext | null>(null);

export function usePlayerContext<T extends IPlayerContext>(): T {
  const context = useContext(PlayerContext);
  if (!context) {
    throw Error("PlayerContext context not in the stack. Use the correct <Context> component for your unit type.");
  }
  return context as T;
}