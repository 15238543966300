import React, {useContext} from "react";
import {EditView, SimpleForm, TextInput} from "react-admin";
import {useMutation, useQuery} from "@apollo/client";
import {useApolloEditController} from "../dataProvider/apolloControllers";
import {gql as gqlm} from "@apollo/client";
import {__RouterContext} from "react-router";

const useUnit = (unitId?: string) => {
  return useQuery(
    gqlm`
      query UnitQuery($unitId: ID!) {
        unit(id: $unitId) {
          id
          name
          metadata { youtube, aparat, instagram }
          description
        }
      }
    `,
    {
      skip: !unitId,
      variables: {
        unitId: unitId,
      },
    }
  );
};

const useCreateUnit = () => {
  return useMutation<any, any>(
    gqlm`
      mutation createUnit($properties: UnitProperties!) {
        createUnit(properties: $properties) {
          unit {
            __typename
            id
            name
            metadata { youtube, aparat, instagram }
            difficulty
          }
        }
      }
    `,
    {
      variables: {},
    }
  );
};

const useSaveUnit = () => {
  return useMutation<any, any>(
    gqlm`
      mutation saveUnit($id: ID!, $properties: UnitProperties!) {
        saveUnit(id: $id, properties: $properties) {
          unit {
            __typename
            id
            name
            metadata { youtube, aparat, instagram }
            difficulty
          }
        }
      }
    `,
    {
      variables: {},
      // update(cache, {data}: any) {
      //   let result: any;
      //   try {
      //     // @ts-ignore
      //     result = cache.readQuery({
      //       query: SeriesQuery,
      //     });
      //   } catch(e) {
      //     return;
      //   }
      //
      //   cache.writeQuery({
      //     query: SeriesQuery,
      //     data: {
      //       seriesCollection: [
      //         ...result.seriesCollection,
      //         data.saveSeries.series
      //       ]
      //     },
      //   });
      // }
    }
  );
};

export const useUnitEditCreate = (props?: {id?: string}) => {
  const id = props ? props.id : undefined;
  const isCreate = id === undefined;
  const query = useUnit(id);
  const [save, saveOp] = useSaveUnit();
  const [create, createOp] = useCreateUnit();

  return useApolloEditController({
    query,
    saveQuery: isCreate ? createOp : saveOp,
    getRecord: (data) => data.unit,
    onSave: async (data) => {
      const f = isCreate ? create : save;
      const result = await f({
        variables: {
          id: id,
          properties: {
            name: data.name,
            description: data.description,
            data: JSON.stringify({
              aparat: data.metadata?.aparat,
              youtube: data.metadata?.youtube,
              instagram: data.metadata?.instagram,
            }),
          },
        },
      });
      return {id: (result.data.createUnit || result.data.saveUnit).unit.id};
    },

    id: id,
    resourceName: "units",
  });
};

export const UnitEdit = (props: {isCreate?: boolean; id?: string}) => {
  const router = useContext(__RouterContext);
  const urlParams = new URLSearchParams(router.location.search);

  const returnToSeries = urlParams.get("returnToSeries");
  const redirect = returnToSeries ? `/series/${returnToSeries}/show` : "show";

  return (
    <EditView {...useUnitEditCreate(props)}>
      <UnitEditForm redirect={redirect} />
    </EditView>
  );
};

export const UnitEditForm = (props: any) => {
  const {isCreate, ...otherProps} = props;

  return (
    <SimpleForm {...otherProps}>
      <TextInput source="name" />
      <TextInput multiline source="description" />
      <TextInput source="metadata.aparat" label={"Aparat"} />
      <TextInput source="metadata.youtube" label={"Youtube"} />
      <TextInput source="metadata.instagram" label={"Instagram"} />
    </SimpleForm>
  );
};
