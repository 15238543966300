import {Button} from "@material-ui/core";
import * as React from "react";
import {
  convertMarketUnitDataToPlayerUnit,
  PlayWindow,
} from "../ContentTaskView/PlayWindow";
import {gql as gqlm} from "@apollo/client";
import {useApolloClient} from "@apollo/client";
import {
  UnitPlayerScreenQuery,
  UnitPlayerScreenQuery_unit,
} from "../../types/UnitPlayerScreenQuery";

export const playQuery = gqlm`
  query UnitPlayerScreenQuery($id: ID!) {
    unit(id: $id) {
      id
      name
      mediaTrack {
        id
        language
        type
        url
        background {
          url
          color
        }
      }
      captionTrack {
        id
        language
        currentVersion {
          id
          data
          translations {
            language
            data
          }
        }
      }
    }
  }
`;

export function PlayButton(props: {unitId: string; size?: any}) {
  const [isVisible, setVisible] = React.useState(false);
  const [unit, setUnit] = React.useState<UnitPlayerScreenQuery_unit | null>(
    null
  );
  const client = useApolloClient();

  const convertedUnit = React.useMemo(() => {
    if (!unit) {
      return null;
    }
    return convertMarketUnitDataToPlayerUnit(unit);
  }, [unit]);

  return (
    <>
      <Button
        onClick={async (e: any) => {
          e.stopPropagation();

          const result = await client.query<UnitPlayerScreenQuery>({
            query: playQuery,
            variables: {
              id: props.unitId,
            },
          });
          console.log(result);

          setUnit(result.data.unit);
          setVisible(true);
        }}
        size={props.size}
        variant="contained"
      >
        Play
      </Button>

      <PlayWindow
        isOpen={isVisible}
        unit={convertedUnit}
        onRequestClose={() => setVisible(false)}
      />
    </>
  );
}
