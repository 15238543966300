import {ProgressBar} from "@uppy/react";
import React, {useCallback} from "react";
import {Button} from "@material-ui/core";

export function UploadProgress(props: {uppy: any}) {
  const handleAbort = useCallback(() => {
    props.uppy.cancelAll();
    props.uppy.reset();
  }, [props.uppy]);

  return (
    <div>
      <ProgressBar uppy={props.uppy} />

      <Button
        variant="contained"
        onClick={handleAbort}
        style={{marginTop: "20px"}}
      >
        Abort
      </Button>
    </div>
  );
}
