import React, {useState, useEffect, useMemo, Fragment} from "react";
import {useQuery} from "multi-apollo";
import {gql as gqlm} from "@apollo/client";
import {CaptionTrack, Captions, Line} from "languagetool-player/src/models";
import {
  getVocableText,
  VocableFormat,
} from "languagetool-player/src/models/getVocableText";
import {
  RenderVideoQuery,
  RenderVideoQuery_unit,
} from "../../types/RenderVideoQuery";
import {TranslationData} from "languagetool-editor/src/js/integrations/Market/Translations/format";
import {smoothHolesBetweenLines} from "languagetool-player/src/ui/Subtitles/useCurrentLineIndex";
import {getUploadUrl} from "languagetool-editor/src/js/integrations/Hosting";
import {useAsync} from "react-use";
import {useRouterURLQuery, useURLQuery} from "../../components/utils/useQuery";
import {useFarsiTransliterationLine} from "languagetool-player/src/langpacks/fa/TransliterationSubtitleLine";
import {convertDisplayLineToVocableFormat} from "languagetool-player/src/ui/Subtitles/types";

// TODO: include correct font ttf file

function useRenderPromise() {
  const currentPromiseResolveRef = React.useRef<any>(null);

  // If a promise was requested, resolve it.
  React.useEffect(() => {
    if (currentPromiseResolveRef.current) {
      console.log("resolve promise after render");
      currentPromiseResolveRef.current();
      currentPromiseResolveRef.current = null;
    }
  });

  return React.useCallback(() => {
    return new Promise((resolve, _) => {
      currentPromiseResolveRef.current = resolve;
    });
  }, [currentPromiseResolveRef]);
}

export const renderVideoQuery = gqlm`
  query RenderVideoQuery($id: ID!) {
    unit(id: $id) {
      id
      name
      mediaTrack {
        id
        type
        url,
        runtimeSeconds
      }
      captionTrack {
        id
        language
        currentVersion {
          id
          data
          translations {
            language
            data
          }
        }
      }
    }
  }
`;

export function useRenderVideoQuery(id: string, skip: boolean) {
  const [unit, setUnit] = useState<RenderVideoQuery_unit | null>(null);

  const {data, loading, error} = useQuery<RenderVideoQuery>(renderVideoQuery, {
    variables: {
      id,
    },
    skip,
  });

  useEffect(() => {
    if (!data || !data.unit) {
      return;
    }
    setUnit(data.unit);
  }, [data, setUnit]);

  return {data: unit, loading, error};
}

export function RenderVideo(props: {}) {
  const params = useRouterURLQuery();
  const {data, loading, error} = useRenderVideoQuery(
    params.get("unitId") ?? "",
    false
  );
  const captions = useMemo(() => {
    if (!data) {
      return;
    }
    return new Captions(
      "fa",
      JSON.parse(data.captionTrack?.currentVersion?.data ?? "")
    );
  }, [data]);

  const translation = useMemo(() => {
    const trans = data?.captionTrack?.currentVersion?.translations?.[0];
    return trans ? JSON.parse(trans.data) : null;
  }, [data?.captionTrack]);

  const url =
    data?.mediaTrack?.type === "internal" ? data?.mediaTrack.url : null;
  const audioState = useAsync(
    async () =>
      url
        ? getUploadUrl(url, {
            endpoint: "https://market.languagetool.xyz/hosting/api/",
          })
        : Promise.resolve(null),
    [url]
  );
  const audioOnlyUrl = audioState.value?.audioOnlyUrl ?? undefined;

  if (error) {
    console.log(error);
    return <div>error</div>;
  }
  if (loading || audioState.loading) {
    return <div>loading...</div>;
  }
  if (!captions || !audioOnlyUrl) {
    return <div>data empty</div>;
  }
  return (
    <RenderVideoContent
      track={captions}
      audioUrl={audioOnlyUrl}
      translation={translation}
      duration={data?.mediaTrack?.runtimeSeconds ?? 0}
      mode={
        params.get("useTranslation") == "true" ? "translation" : "subtitles"
      }
      startLine={
        params.get("start") ? parseInt(params.get("start")!) : undefined
      }
      endLine={params.get("end") ? parseInt(params.get("end")!) : undefined}
    />
  );
}

export function RenderVideoContent(props: {
  track: Captions;
  audioUrl: string | undefined;
  duration: number;
  translation: any;
  mode?: "translation" | "subtitles";
  startLine?: number;
  endLine?: number;
}) {
  const framesPerSecond = 30;
  const [currentTime, setCurrentTime] = useState<number | null>(null);
  const requestRenderPromise = useRenderPromise();

  // Calculate the real range.
  let startLine: number;
  let startTime: number;
  let endLine: number;
  let endTime: number;
  if (props.startLine === undefined) {
    startTime = 0;
  } else {
    startLine = props.startLine;
    startTime = props.track.getLineTimeBoundary(startLine).start;
  }
  if (props.endLine === undefined) {
    endTime = props.duration;
  } else {
    endLine = props.endLine;
    endTime = props.track.getLineTimeBoundary(endLine).end;
  }

  const realDuration = endTime - startTime;
  const timeWithinFile = Math.max(
    startTime,
    Math.min(endTime, startTime + (currentTime ?? 0))
  );

  async function goToFrame(frame: number) {
    const renderDone = requestRenderPromise();
    setCurrentTime(frame / framesPerSecond);
    await renderDone;
  }

  useSetupVideoAPI({
    seekToFrame: goToFrame,
    getInfo: () => {
      return {
        fps: framesPerSecond,
        numberOfFrames: framesPerSecond * realDuration,
        soundEffects: props.audioUrl
          ? [
              {
                url: props.audioUrl,
                time: -startTime,
              },
            ]
          : [],
      };
    },
  });

  const width = 1200;
  const height = 1200;

  return (
    <div>
      <div
        id={"scene"}
        style={{
          width,
          height,
          boxSizing: "border-box",
          overflow: "hidden",
          contain: "strict",

          backgroundColor: "#fcfcfc",
          //border: '1px solid silver',
        }}
      >
        <RenderScene
          time={timeWithinFile}
          track={props.track}
          translation={props.translation}
          mode={props.mode}
          width={width}
        />
      </div>

      <ProgressControl
        goToFrame={goToFrame}
        frameCount={framesPerSecond * realDuration}
      />
    </div>
  );
}

function ProgressControl(props: {frameCount: number; goToFrame: any}) {
  return (
    <div>
      <input
        type={"range"}
        min={0}
        max={props.frameCount}
        step={1}
        defaultValue={0}
        onChange={(e) => {
          props.goToFrame(parseInt(e.target.value));
        }}
      />
      {/*<button onClick={() => props.timeline.play()}>play</button>*/}
    </div>
  );
}

function useSetupVideoAPI(opts: {getInfo: any; seekToFrame: any}) {
  const {getInfo, seekToFrame} = opts;

  React.useEffect(() => {
    // As soon as we install the `getInfo`, the browser will start frameshotting.
    (window as any).getInfo = getInfo;

    (window as any).seekToFrame = seekToFrame;
  }, [getInfo, seekToFrame]);
}

const COLORS = ["rgb(194, 49, 49)", "rgb(57, 88, 112)"];

const HIGHLIGHT = ["rgb(59, 40, 39)", "rgb(228, 193, 108)"];

export function RenderScene(props: {
  time: number;
  track: Captions;
  translation: TranslationData;
  mode?: "translation" | "subtitles";
  width: number;
}) {
  const translitFormat = convertDisplayLineToVocableFormat(
    useFarsiTransliterationLine("wiktionary")
  );
  const defaultFormat: VocableFormat = {};

  const lineIdxResult = props.track.getLineIndexForTime(props.time);
  const lineIdx = smoothHolesBetweenLines(
    lineIdxResult,
    props.time,
    props.track
  );
  const line = lineIdx !== null ? props.track.getLine(lineIdx) : null;

  let currentVocableIds: string[] = [];
  if (line) {
    const timeRelToLine = props.time - line.time;
    currentVocableIds = line.elements
      .flatMap((x) => x)
      .filter((vocable) => {
        if (
          typeof vocable.time == "number" &&
          typeof vocable.duration == "number"
        ) {
          if (
            timeRelToLine > vocable.time &&
            timeRelToLine < vocable.time + vocable.duration
          ) {
            return true;
          }
        }

        return false;
      })
      .map((v) => v["id"]);
  }

  const translationText =
    lineIdx !== null ? props.translation[lineIdx][0] : null;

  const translationStyle: React.CSSProperties = {
    direction: "ltr",
    fontFamily: "Noto Sans",
    fontWeight: 700,
  };
  const persianStyle: React.CSSProperties = {
    direction: "rtl",
    fontFamily: "Noto Sans Arabic",
    fontWeight: 700,
  };

  const content = line ? (
    <>
      <div style={persianStyle}>
        <FormatLine
          line={line}
          format={defaultFormat}
          currentVocableIds={currentVocableIds}
        />
      </div>
      <div style={{...translationStyle, marginTop: "1em"}}>
        <FormatLine
          line={line}
          format={translitFormat}
          currentVocableIds={currentVocableIds}
        />
      </div>
      <div style={{...translationStyle, marginTop: "1em", fontSize: "0.8em"}}>
        {translationText}
      </div>
    </>
  ) : null;

  const speakerId = line?.speakers?.[0] ?? 0;
  return (
    <div
      style={
        {
          width: "100%",
          height: "100%",
          padding: 0.05 * props.width,
          boxSizing: "border-box",
          fontSize: `${0.065 * props.width}px`,
          lineHeight: 1.4,
          background: COLORS[speakerId],
          color: "white",
        } as any
      }
    >
      {content}
    </div>
  );
}

function FormatLine(props: {
  line: Line;
  format: VocableFormat;
  currentVocableIds: string[];
}) {
  const {line, format, currentVocableIds} = props;
  const prePostFormatter = format?.prePostFormatter || ((x?: string) => x);

  const subtitleText = line?.elements[0].map((vocable, idx) => {
    const text = getVocableText(vocable, format);

    return (
      <Fragment key={`${vocable.id || idx}`}>
        {prePostFormatter(vocable.pre)}
        <span
          style={{
            color:
              currentVocableIds.indexOf(vocable.id) > -1
                ? "#ffc107"
                : undefined,
          }}
        >
          {text}
        </span>
        {prePostFormatter(vocable.post)}
      </Fragment>
    );
  });

  return <>{subtitleText}</>;
}
