import React, {useState, useContext} from "react";
import {
  Card,
  Tabs,
  Tab,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import {BelongsToTab} from "./BelongsToTab";
import {__RouterContext} from "react-router";
import {useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {Links, MissingMetadataMessage} from "../../components/Links";
import {SeasonQuery, SeasonQuery_series} from "../../types/SeasonQuery";
import {useDropzone} from "react-dropzone";
import {useSaveSeries} from "../SeriesEdit";

export function InfoTabs(props: {series: SeasonQuery_series}) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const router = useContext(__RouterContext);

  return (
    <div>
      <div
        style={{display: "flex", flexDirection: "row", marginBottom: "10px"}}
      >
        <Button
          variant={"contained"}
          onClick={() => {
            router.history.push(`/series/${props.series.id}/edit`);
          }}
        >
          Edit Series
        </Button>
        &nbsp;&nbsp;&nbsp;
        <ToggleSeriesPublicCheckbox record={props.series} />
      </div>

      <Tabs value={value} onChange={handleChange}>
        <Tab label="Metadata" />
        <Tab label="Belongs to" />
      </Tabs>

      <Card
        style={{
          padding: "1em",
        }}
      >
        <TabPanel value={value} index={0}>
          <MetadataSection title={"Links"}>
            <Links links={props.series.links} />
          </MetadataSection>
          <MetadataSection title={"Description"}>
            {!props.series.description ? (
              <MissingMetadataMessage>
                No description written.
              </MissingMetadataMessage>
            ) : (
              props.series.description
            )}
          </MetadataSection>
          <MetadataSection title={"Images"}>
            <ImageSection series={props.series} />
          </MetadataSection>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <BelongsToTab seriesId={props.series.id} />
        </TabPanel>
      </Card>
    </div>
  );
}

function ImageSection(props: {series: SeasonQuery_series}) {
  const [save, saveOp] = useSaveSeries();
  const currentUploadMode = React.useRef<"image" | "bannerImage" | "">("");
  const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
    noClick: true,
    noKeyboard: true,
  });

  React.useEffect(() => {
    if (!acceptedFiles.length) {
      return;
    }
    save({
      variables: {
        id: props.series.id,
        properties: {
          [currentUploadMode.current]: acceptedFiles[0],
        },
      },
    });
  }, [acceptedFiles]);

  function upload(e: any, type: "image" | "bannerImage") {
    e.preventDefault();
    currentUploadMode.current = type;
    const r = open();
  }

  return (
    <div
      style={{display: "flex", flexDirection: "row"}}
      {...getRootProps({className: "dropzone"})}
    >
      <input {...getInputProps()} />
      <div>
        <div>
          <strong>Image</strong>{" "}
          <a href={"#"} onClick={(e) => upload(e, "image")}>
            Upload
          </a>
        </div>
        <img src={props.series.image?.url} height={"200px"} />
      </div>
      <div>
        <div>
          <strong>Banner Image (optional)</strong>
          <a href={"#"} onClick={(e) => upload(e, "image")}>
            Upload
          </a>
        </div>
        <img src={props.series.bannerImage?.url} height={"200px"} />
      </div>
    </div>
  );
}

function MetadataSection(props: {children?: any; title: string}) {
  return (
    <div style={{marginBottom: "15px"}}>
      <Typography variant="subtitle2">{props.title}</Typography>
      {props.children}
    </div>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {children}
    </Typography>
  );
}

function useMarkSeriesPublic(seriesId: string) {
  return useMutation<any>(
    gql`
      mutation($isPublic: Boolean!, $seriesId: String!) {
        markSeriesPublic(isPublic: $isPublic, seriesId: $seriesId) {
          series {
            id
            isReady
          }
        }
      }
    `,
    {
      variables: {
        seriesId: seriesId,
      },
    }
  );
}

function ToggleSeriesPublicCheckbox(props: {
  record: {id: string; isReady: boolean};
}) {
  const [markSeriesPublic] = useMarkSeriesPublic(props.record.id);

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={props.record.isReady}
          onChange={(e) => {
            markSeriesPublic({variables: {isPublic: e.target.checked}});
          }}
        />
      }
      label="Public & Published"
      style={{
        fontWeight: "bold",
      }}
    />
  );
}
