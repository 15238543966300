import React from "react";
import {
  Edit,
  TextInput,
  ReferenceInput,
  SimpleForm,
  SelectInput,
  NumberInput,
} from "react-admin";

export const TaskEdit = (props: any) => (
  <Edit {...props}>
    <TaskEditForm allowStatus={true} allowReportedTime={true} />
  </Edit>
);

export const TaskStatus = [
  {id: "pending", name: "Pending"},
  {id: "started", name: "Started"},
  {id: "review", name: "Review"},
  {id: "rejected", name: "Rejected"},
  {id: "complete", name: "Complete"},
];

export const TaskKind = [
  {id: "grammar", name: "Grammar"},
  {id: "captioning", name: "Creating Subtitles"},
  {id: "translation", name: "Translation"},
  {id: "content", name: "Content"},
];

export const TaskEditForm = (props: any) => {
  const {allowStatus, allowReportedTime, isCreate, ...otherProps} = props;

  return (
    <SimpleForm {...otherProps}>
      {isCreate ? <SelectInput source="kind" choices={TaskKind} /> : null}
      <TextInput multiline source="description" />
      <ReferenceInput
        label="Assigned Editor"
        source="assignedEditor.id"
        reference="editors"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      {allowReportedTime ? <NumberInput source="reportedTime" /> : null}
      {allowStatus ? (
        <SelectInput source="status" choices={TaskStatus} />
      ) : null}

      {props.children}
    </SimpleForm>
  );
};
