/**
 * A unit may be broken into chapters, smaller pieces.
 *
 * Note that there may be a higher-level "chapter" structure. An end-user site may choose to split a piece of content
 * into multiple units, and call that a chapter. So this concept here is only at play if a single unit should be
 * separated further.
 *
 * A unit is a media file + text. We allow for the idea that we might have a media file specifically for each
 * individually chapter, to improve loading speed. However, the caption text itself will remain one single piece.
 */

import {Captions} from "./Captions";
import {Line} from "./formats/CaptionTrack";


export interface Chapter {
  idx: number,
  initialLineIndex: number,
  fromTime: number,
  toTime: number,
  duration: number,
  mapLines<T>(callback: (line: Line, idx: number) => T): T[]
}


export function createChapter(captions: Captions, opts: {idx: number, fromTime: number, toTime: number}): Chapter {
  const {fromTime, toTime, idx} = opts;

  let initialLineIndex = captions.getLineIndexForTime(fromTime).next;
  if (initialLineIndex === null) {
    // This can happen in rare cases where the captions are empty, and even fromTime = 0 has no 'next' line.
    initialLineIndex = 0;
  }

  return {
    idx,
    fromTime,
    toTime,
    initialLineIndex,
    duration: toTime - fromTime,
    mapLines<T>(callback: (line: Line, idx: number) => T): T[] {
      return captions.data.lines.filter(
        line => {
          if (line.time + line.duration < fromTime) {
            return false;
          }
          if (line.time + line.duration > toTime) {
            return false;
          }
          return true;
        }
      ).map(callback);
    }
  }
}


// Holds all chapters.
export class Chapters {
  captions: Captions;
  mediaDuration: number;
  chapters: Chapter[];

  constructor(captions: Captions, mediaDuration: number) {
    this.captions = captions;
    this.mediaDuration = mediaDuration;
    this.chapters = this.calculate();
  }

  /**
   * This is an algorithm to automatically split a caption file into chapters, which can be used if no
   * chapters have been defined manually (at the time of this writing, that is not even possible).
   *
   * This algorithm defines a fixed number of chapters based on the length. Then within a certain range
   * around each split division, we pick a chapter split.
   *
   * A better algorithm would be like a rolling hash.  It would, around a certain point, pick the best
   * boundary. It would then continue from there, again picking the best point within the next target range.
   *
   * Any algorithm used will show the phenomena that if the chapter length is 5 mins, and the video is 4 min,
   * there will a single 4 min chapter, but if the video is 6 min, each chapter will be 3 min. In essence, if
   * the max chapter length is 5 min, the min chapter length is half, and as the video grows longer, this
   * distance disappears slowly, as the "extra parts" can be spread over the existing chapters.
   */
  calculate() {
    const splitSegmentsAfter = 5 * 60;

    // If the total length is more than a fixed maximum value, we start to split into chapters. We then
    // target two chapters of roughly equal length. So consider that if you configure the max length
    // to be 5 minutes, your minimum  length is automatically at least 2.5 minutes, after a split.
    // If the media length is 9.9 minutes, there will be two chapters, and one of them may turn out to be
    // longer than the 5 minute maximum that was configured, because there is a variance factor.
    const chaptersRequired = Math.ceil(this.mediaDuration / splitSegmentsAfter);

    // For the ideal split point, divide the file into equal parts.
    const desiredTargetLength = this.mediaDuration / chaptersRequired;

    // Of course, the ideal split point might not be available, or might indeed not be ideal. There is
    // more to a perfect chapter boundary than equal length parts.
    const threshold = 0.3;

    const splitPoints: number[] = [];
    for (let i=1; i<chaptersRequired; i++) {
      const searchStartTime = i*desiredTargetLength - (threshold * desiredTargetLength);
      const searchEndTime = i*desiredTargetLength + (threshold * desiredTargetLength);

      const firstPossibleIndex = this.captions.getLineIndexForTime(searchStartTime).next;
      const lastPossibleIndex = this.captions.getLineIndexForTime(searchEndTime).previous;

      // This should never happen unless the chapters are grossly misconfigured with a very long line time.
      if (firstPossibleIndex === null || lastPossibleIndex === null || firstPossibleIndex > lastPossibleIndex) {
        continue;
      }

      let bestResult: {duration: number, idx: number, splitTime: number}|null = null;
      for (let idx=firstPossibleIndex; idx <lastPossibleIndex; idx ++) {
        const boundary = this.captions.getLineTimeBoundary(idx);
        if (!boundary.nextStart) {
          break;
        }
        const duration = boundary.nextStart - boundary.end;
        const splitTime = boundary.nextStart - (duration / 2);
        if (!bestResult) {
          bestResult = {idx, duration, splitTime};
        }
        else if (duration > bestResult.duration) {
          bestResult = {idx, duration, splitTime};
        }
      }

      // This should never happen unless the chapters are grossly misconfigured with a very long line time.
      if (!bestResult) {
        continue;
      }

      splitPoints.push(bestResult.splitTime)
    }

    // Add first and last chapter points.
    splitPoints.splice(0, 0, 0);
    splitPoints.push(this.mediaDuration);

    let chapters: Chapter[] = [];
    for (let i=0; i<splitPoints.length-1; i++) {
      const fromTime = splitPoints[i];
      const toTime = splitPoints[i+1];

      chapters.push(createChapter(
        this.captions,
        {
          idx: i,
          fromTime: fromTime,
          toTime: toTime,
        })
      );
    }

    return chapters;
  }

  getForTime(time:  number): Chapter|null {
    for (const chapter of this.chapters) {
      if (time >= chapter.fromTime && time <= chapter.toTime) {
        return chapter;
      }
    }
    return null;
  }
}