import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import {useFormik} from "formik";
import {SiteSelector} from "./SiteSelector";
import {TagSelector} from "./TagSelector";
import {useResetOnOpen} from "../../../components/useResetOnOpen";
import {useAssignToSite} from "./queries";

type FormikType = ReturnType<typeof useFormik>;

/**
 * Assign something to a site - either a series or a unit - with tags.
 */
export function AssignSiteDialog(props: {
  // Either one or the other
  unitId?: string;
  seriesId?: string;

  initialData?: {
    siteId: string;
    tags: string[];
  };

  onRequestClose: () => void;
  isOpen: boolean;
  refetch: () => void;
}) {
  const [assignToSite, _] = useAssignToSite();

  const formik = useFormik<any>({
    initialValues: props.initialData ?? {
      siteId: "",
      tags: [],
    },
    onSubmit: async (values, actions) => {
      await assignToSite({
        variables: {
          unitId: props.unitId,
          seriesId: props.seriesId,
          tags: values.tags,
          siteId: values.siteId,
        },
      });
      actions.setSubmitting(false);
      props.onRequestClose();
      props.refetch();
    },
  });

  useResetOnOpen(props.isOpen, () => formik.resetForm(), [formik]);

  return (
    <Dialog onClose={props.onRequestClose} open={props.isOpen}>
      <DialogTitle>Assign to a site</DialogTitle>

      <AssignDialogContent formik={formik} />

      <DialogActions>
        <Button onClick={props.onRequestClose}>Cancel</Button>
        <Button onClick={formik.submitForm} color="primary">
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function AssignDialogContent(props: {formik: FormikType}) {
  return (
    <DialogContent>
      <SiteSelector
        selectedId={props.formik.values.siteId}
        onChange={(x) => props.formik.setFieldValue("siteId", x)}
      />

      <TagSelector
        selected={props.formik.values.tags}
        onChange={(tags) => props.formik.setFieldValue("tags", tags)}
      />
    </DialogContent>
  );
}
