import React, {useCallback, useEffect} from "react";
import {
  Paper,
  TextField,
  Button,
  Link,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import {useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {useFormik} from "formik";
import {ContentMediaTrack, ContentUnit} from "./index";

export function useSaveUnit() {
  return useMutation<
    any,
    Partial<{
      id: string;
      name: string;
      description: string;
      difficulty: number | null;
    }>
  >(
    gql`
      mutation changeUnit(
        $id: ID!
        $name: String!
        $description: String!
        $difficulty: Int
      ) {
        changeUnitDescription(
          id: $id
          name: $name
          description: $description
          difficulty: $difficulty
        ) {
          unit {
            id
            name
            description
            difficulty
          }
        }
      }
    `,
    {
      variables: {},
    }
  );
}

export const UnitForm = React.memo(
  (props: {unit: ContentUnit; onPlay: (track: ContentMediaTrack) => void}) => {
    const [saveUnit, _] = useSaveUnit();

    const formik = useFormik<{
      name: string;
      description: string;
      difficulty: string;
    }>({
      enableReinitialize: true,
      initialValues: {
        name: props.unit.name,
        description: props.unit.description,
        difficulty: props.unit.difficulty ? "" + props.unit.difficulty : "",
      },
      onSubmit: async (values, actions) => {
        try {
          await saveUnit({
            variables: {
              id: props.unit.id,
              name: values.name,
              description: values.description,
              difficulty: values.difficulty
                ? parseInt(values.difficulty)
                : null,
            },
          });
        } catch (e) {
          console.log(e);
          alert("ERROR! Changes were not saved. Try again.");
        } finally {
          actions.setSubmitting(false);
        }
      },
    });

    const handlePlayClick = useCallback(
      (e: any) => {
        e.preventDefault();
        props.onPlay(props.unit.mediaTrack);
      },
      [props.onPlay]
    );

    const {youtube, aparat} = props.unit.metadata;

    return (
      <Paper style={{padding: "20px", marginBottom: "20px"}}>
        <div
          style={{display: "flex", flexDirection: "row", alignItems: "center"}}
        >
          <h3 style={{margin: "0 20px 0 0"}}>Video</h3>
          {formik.dirty ? (
            <Button
              color={"primary"}
              onClick={formik.submitForm}
              variant={"contained"}
            >
              Save
            </Button>
          ) : null}
        </div>

        <p>
          {youtube ? <YoutubeLink id={youtube} /> : null}
          {aparat ? <AparatLink id={aparat} /> : null}

          <Button onClick={handlePlayClick} color={"primary"}>
            Play
          </Button>
        </p>

        <TextField
          label={"Title"}
          fullWidth
          {...formik.getFieldProps({name: "name", type: "text"})}
        />

        <TextField
          label={"Description"}
          multiline={true}
          fullWidth
          style={{marginTop: "1em"}}
          helperText={`${countWords(formik.values.description || "")} words`}
          {...formik.getFieldProps({name: "description", type: "text"})}
        />

        <FormControl style={{width: "300px", marginTop: "1em"}}>
          <InputLabel>Difficulty</InputLabel>
          <Select {...formik.getFieldProps({name: "difficulty", type: "text"})}>
            <MenuItem value=""></MenuItem>
            <MenuItem value={3}>Beginner</MenuItem>
            <MenuItem value={6}>Intermediate</MenuItem>
            <MenuItem value={9}>Advanced</MenuItem>
          </Select>
        </FormControl>
      </Paper>
    );
  }
);

export function YoutubeLink(props: {id: string}) {
  return (
    <Button
      component={Link}
      href={`https://www.youtube.com/watch?v=${props.id}`}
      target={"_blank"}
    >
      Youtube
    </Button>
  );
}

export function AparatLink(props: {id: string}) {
  return (
    <Button
      component={Link}
      href={`https://www.aparat.com/v/${props.id}`}
      target={"_blank"}
    >
      Aparat
    </Button>
  );
}

function countWords(str: string) {
  return str.trim().split(/\s+/).length;
}
