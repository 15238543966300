import React from "react";
import {Datagrid, EditButton, ListView, TextField} from "react-admin";
import {gql as gqlm} from "@apollo/client";
import {
  ListProps,
  useApolloListController,
} from "../dataProvider/apolloControllers";
import {StatusField, StatusBox} from "../components/StatusField";
import {SeriesListQuery_seriesCollection} from "../types/SeriesListQuery";

export const Query = gqlm`
  query SeriesListQuery {
    seriesCollection {
      id,
      name,
      isReady,
      assignments {
        site {
          id,
          name
        }
      }
    }
  }
`;

export const SeriesList = (props: ListProps) => {
  const controllerProps = useApolloListController({
    ...props,
    query: Query,
    getData: (data) => data.seriesCollection,
    getId: "id",
  });

  return (
    <ListView {...props} {...controllerProps}>
      <Datagrid rowClick="show">
        <TextField source="name" />
        <SeriesStatusField label="Ready" />
        <EditButton />
      </Datagrid>
    </ListView>
  );
};

export const SeriesStatusField = (props: {
  record?: SeriesListQuery_seriesCollection;
  label: string;
}) => {
  const {record} = props;

  return (
    <StatusField>
      <StatusBox complete={record!.isReady}>Public</StatusBox>
      <StatusBox complete={record!.assignments.length > 0}>
        {record?.assignments.map((a) => a.site.name)}
        {!record?.assignments.length ? <span>None</span> : null}
      </StatusBox>
    </StatusField>
  );
};
