import * as React from "react";
import {Button} from "@material-ui/core";
import {useQuery, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {gql as gqlm} from "@apollo/client";
import {format, parseISO} from "date-fns";
import {useState} from "react";
import {CaptionTrackDialog} from "../../components/CaptionTrackPopup";
import {Check, Public} from "@material-ui/icons";
import {TrackVersionQuery} from "../../types/TrackVersionQuery";

const useTrackVersions = (trackId: string) => {
  const {data, error, loading, refetch} = useQuery<TrackVersionQuery>(
    gqlm`
    query TrackVersionQuery($trackId: ID!) {
      captionTrack(id: $trackId) {
        id,
        isTextReady,
        isGrammarReady,
        currentVersion {
          id
        }
        versions {
          id,
          createdAt,
          updatedAt,
          deletedAt,
          isReviewed,
          numWords,
          numUnassignedVocables,
          numLines,
          
          translations {
            id,
            language,
            numLines,
            isReady
          }          
        }
      }
    }
  `,
    {
      variables: {
        trackId,
      },
    }
  );

  return {
    versions: data && data.captionTrack ? data.captionTrack.versions : null,
    currentVersionId:
      data && data.captionTrack && data.captionTrack.currentVersion
        ? data.captionTrack.currentVersion.id
        : null,
    loading,
    refetch,
  };
};

const useCopyVersion = () => {
  const [copy, {data: mutationData}] = useMutation(gql`
    mutation CopyVersion($trackVersionId: String!) {
      copyCaptionTrackVersion(trackVersionId: $trackVersionId) {
        newVersion {
          id
        }
      }
    }
  `);
  return copy;
};

const useMarkAsReviewed = () => {
  const [copy, {data: mutationData}] = useMutation(gql`
    mutation MarkVersionReviewed($trackVersionId: String!) {
      markCaptionTrackVersionAsReviewed(trackVersionId: $trackVersionId) {
        version {
          id
          isReviewed
        }
      }
    }
  `);
  return copy;
};

const useSetAsDefault = () => {
  const [copy, {data: mutationData}] = useMutation(gql`
    mutation MarkVersionDefault($trackVersionId: String!) {
      setCaptionTrackVersionAsDefault(trackVersionId: $trackVersionId) {
        captionTrack {
          id
          currentVersion {
            id
          }
        }
      }
    }
  `);
  return copy;
};

function useLocalDialog(Klass: any) {
  const [dialogVisible, setDialogVisible] = useState(false);
  const [props, setProps] = useState({});

  const dialog = (
    <Klass
      isOpen={dialogVisible}
      onRequestClose={() => setDialogVisible(false)}
      {...props}
    />
  );

  return {
    dialog,
    show: (props: any) => {
      setProps(props);
      setDialogVisible(true);
    },
  };
}

export function ReviewedCheckMark() {
  return <Check titleAccess={"Reviewed"} htmlColor={"green"} />;
}

export function PublicMarker() {
  return (
    <span
      style={{
        display: "inline-flex",
        alignItems: "center",
        fontWeight: "bold",
      }}
    >
      <Public htmlColor={"blue"} /> Current
    </span>
  );
}

export function TrackVersionList(props: {trackId: string}) {
  const {versions, currentVersionId, loading, refetch} = useTrackVersions(
    props.trackId
  );
  const copyVersion = useCopyVersion();
  const markReviewed = useMarkAsReviewed();
  const setAsDefault = useSetAsDefault();

  const {dialog, show} = useLocalDialog(CaptionTrackDialog);

  if (loading) {
    return <div>...</div>;
  }

  const sortedVersions = [...versions!].sort(
    (a: any, b: any) =>
      (new Date(b.createdAt) as any) - (new Date(a.createdAt) as any)
  );

  return (
    <div>
      {dialog}

      <table style={{width: "100%"}}>
        <thead>
          <tr>
            <th />
            <th>Date</th>
            <th>Updated</th>
            <th>Content</th>
            <th>Translations</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {sortedVersions.map((version) => {
            if (!version) {
              return;
            }
            return (
              <tr
                key={version.id}
                style={{
                  textDecoration: version.deletedAt ? "line-through" : "none",
                }}
              >
                <td>
                  {version.isReviewed ? <ReviewedCheckMark /> : null}
                  {currentVersionId == version.id ? <PublicMarker /> : null}
                </td>
                <td>
                  {format(parseISO(version.createdAt), "yyyy-MM-dd HH:mm")}
                </td>
                <td>
                  {version.updatedAt
                    ? format(parseISO(version.updatedAt), "yyyy-MM-dd HH:mm")
                    : null}
                </td>
                <td>
                  {version.numLines} lines, {version.numWords} words.{" "}
                  {version?.numUnassignedVocables ? (
                    <>{version?.numUnassignedVocables} not set.</>
                  ) : null}
                </td>
                <td>
                  {version.translations?.map((trans) => {
                    return (
                      <span key={trans?.language}>
                        {trans?.language} ({trans?.numLines}l)
                      </span>
                    );
                  })}
                </td>
                <td>
                  <Button
                    onClick={async () => {
                      await copyVersion({
                        variables: {
                          trackVersionId: version.id,
                        },
                      });
                      await refetch();
                    }}
                  >
                    Copy
                  </Button>
                  {!version.deletedAt ? (
                    <Button
                      onClick={async () => {
                        await setAsDefault({
                          variables: {
                            trackVersionId: version.id,
                          },
                        });
                      }}
                    >
                      Set As Current
                    </Button>
                  ) : null}

                  {!version.isReviewed && !version.deletedAt ? (
                    <Button
                      onClick={async () => {
                        await markReviewed({
                          variables: {
                            trackVersionId: version.id,
                          },
                        });
                      }}
                    >
                      Mark As Reviewed
                    </Button>
                  ) : null}

                  <Button
                    onClick={async () => {
                      show({
                        trackVersionId: version.id,
                      });
                    }}
                  >
                    Inspect
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
