// Preconfigured for your convenience.
// TODO: Maybe this is more at home at a "market-library"? Since it uses our specific data style? Then again,
// a lot of other things are specific to us, even the subtitle format.

import {romanizeSounds} from "./transliterate";
import {useCallback} from "react";
import {AlternateLine, DisplayLine} from "../../ui/Subtitles/types";
import {VocableFormat} from "../../models/getVocableText";


function prePostFormatter(s?: string) {
  if (!s) {
    return s;
  }

  // Convert punctuation from arabic to latin.
  return s
    .replace('؟', '?')
    .replace('،', ',')
    .replace('؛', ';')
}


export function useTransliterator(style: string) {
  return useCallback((sounds: string[] | null | undefined): string => {
    if (!sounds) {
      return "____";
    }
    if (typeof (sounds as any) === 'string') {
      return (sounds as any) as string;
    }
    return romanizeSounds(sounds, {style});
  }, [style]);
}


// See also convertDisplayLineToVocableFormat
export function useFarsiTransliterationLine(style: string): AlternateLine {
  const format = useFarsiTransliterationFormat(style);
  return {
    type: 'alternate',
    alternates: format.useAlternates!,
    direction: format.direction,
    formatter: format.altFormatter,
    prePostFormatter: format.prePostFormatter
  };
}


export function useFarsiTransliterationFormat(style: string) {
  const transliterate = useTransliterator(style);

  return {
    useAlternates: ['sounds', 'roman'],
    direction: 'ltr',
    altFormatter: transliterate,
    prePostFormatter: prePostFormatter
  } as VocableFormat;
}