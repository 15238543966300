import * as React from "react";
import {useState} from "react";
import {TaskEditForm} from "../TaskEdit";
import {Button} from "@material-ui/core";
import {useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {useNotify} from "ra-core";

export function NewTaskPane(props: {unitId: string; refetch: () => any}) {
  const notify = useNotify();
  const createTask = useCreateTask();
  const [formVisible, setFormVisible] = useState(false);

  return (
    <div>
      <Button
        onClick={() => {
          setFormVisible(true);
        }}
      >
        New Task
      </Button>

      {formVisible ? (
        <TaskEditForm
          isCreate
          allowStatus={false}
          allowReportedTime={false}
          save={async (values: any, redirect: any) => {
            await createTask({
              variables: {
                unitId: props.unitId,
                kind: values.kind,
                props: {
                  assignedEditorId: values.assignedEditor
                    ? parseInt(values.assignedEditor.id)
                    : null,
                  description: values.description,
                },
              },
            });
            notify("Task created!");
            setFormVisible(false);
            props.refetch();
          }}
        />
      ) : null}
    </div>
  );
}

export function useCreateTask() {
  const [createTask] = useMutation<any, any>(
    gql`
      mutation CreateTask($props: TaskProps, $unitId: ID!, $kind: TaskType!) {
        applyTask(unitId: $unitId, props: $props, kind: $kind) {
          task {
            id
          }
        }
      }
    `,
    {
      variables: {},
    }
  );

  return createTask;
}
