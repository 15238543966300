import {keyBy} from "lodash";
import inflection from "inflection";
import {
  useRecordSelection,
  useTranslate,
  useRedirect,
  Sort,
  ListParams,
  RedirectionSideEffect,
} from "ra-core";
import {useMemo, ReactElement} from "react";
import {EditControllerProps} from "ra-core/esm/controller/useEditController";
import {ListControllerProps} from "ra-core/esm/controller/useListController";
import useListParams from "ra-core/esm/controller/useListParams";
import {useLocation} from "react-router-dom";
import {QueryHookOptions} from "@apollo/client";
import {DocumentNode} from "graphql";
import {useQuery} from "@apollo/client";

/**
 * This is a way to use the react-admin UI system and controls, but skip it's dataProvider logic, and instead provide
 * the data directly.
 *
 * For example, the react-admin <List> component is literally this line:
 *
 * const List = props => <ListView {...props} {...useListController(props)} />;
 *
 * `useListController()` provides the logic, including data loading. So if we replace that one, with one that loads
 * data directly from the Apollo, we can make it nice.
 *
 */

// From https://github.com/marmelab/react-admin/blob/1ff41bad637b651c1cfba37afad7ee328c822790/packages/ra-core/src/controller/useListController.ts
export interface ListProps {
  // the props you can change
  filter?: object;
  filters?: ReactElement<any>;
  filterDefaultValues?: object;
  pagination?: ReactElement<any>;
  perPage?: number;
  sort?: Sort;
  // the props managed by react-admin
  basePath: string;
  debounce?: number;
  hasCreate?: boolean;
  hasEdit?: boolean;
  hasList?: boolean;
  hasShow?: boolean;
  location?: Location;
  path?: string;
  //query: ListParams;
  resource: string;
  //[key: string]: any;
}

export const SORT_ASC = "ASC";

const defaultSort = {
  field: "id",
  order: SORT_ASC,
};

// Also see:
// - https://github.com/marmelab/react-admin/blob/next/packages/ra-ui-materialui/src/list/Datagrid.js
// - https://github.com/marmelab/react-admin/blob/next/packages/ra-ui-materialui/src/list/List.js
// - https://github.com/marmelab/react-admin/blob/next/packages/ra-core/src/dataProvider/useGetList.ts
// - https://github.com/marmelab/react-admin/blob/1ff41bad637b651c1cfba37afad7ee328c822790/packages/ra-core/src/controller/useListController.ts
type ListControllerOpts<TData> = {
  query: DocumentNode;
  getData: (data: TData) => any[];
  getTotal?: (data: TData) => number;
  getId: any;
  getVariables?: (params: ListParams) => any;
} & ListProps &
  QueryHookOptions;
export function useApolloListController<TData = any>(
  opts: ListControllerOpts<TData>
): ListControllerProps {
  const {query: apolloQuery, getData, getId} = opts;

  const {
    basePath,
    resource,
    hasCreate,
    filterDefaultValues,
    sort = defaultSort,
    perPage = 10,
    filter,
    debounce = 500,
  } = opts;

  const location = useLocation();

  const [query, queryModifiers] = useListParams({
    resource,
    location,
    filterDefaultValues,
    sort,
    perPage,
    debounce,
  });

  const variables = opts.getVariables
    ? opts.getVariables(query)
    : opts.variables;
  const {data, loading} = useQuery<TData>(opts.query, {...opts, variables});
  const [selectedIds, selectionModifiers] = useRecordSelection(opts.resource);

  const translate = useTranslate();

  const resourceName = translate(`resources.${resource}.name`, {
    smart_count: 2,
    _: inflection.humanize(inflection.pluralize(resource)),
  });
  const defaultTitle = translate("ra.page.list", {
    name: resourceName,
  });

  // Not actually sure what we do here...?
  const [groupedData, ids] = useMemo(() => {
    if (!data) {
      return [null, null];
    }

    let groupedData = null;
    let ids: string[] = [];

    let collection = getData(data);
    if (data) {
      groupedData = keyBy(collection, getId);
      ids = Object.keys(groupedData)!;
    }
    return [groupedData, ids];
  }, [data, getData, getId]);

  const total = data ? (opts.getTotal ? opts.getTotal(data) : ids!.length) : 0;

  const currentSort = useMemo(
    () => ({
      field: query.sort,
      order: query.order,
    }),
    [query.sort, query.order]
  );

  // https://github.com/marmelab/react-admin/blob/next/packages/ra-core/src/controller/useListController.ts
  return {
    currentSort,

    selectedIds,
    onSelect: selectionModifiers.select,
    onToggleItem: selectionModifiers.toggle,
    onUnselectItems: selectionModifiers.clearSelection,

    loading: loading,
    loaded: !loading && !!data,
    data: groupedData as any,
    ids: ids || [],

    basePath,
    defaultTitle,
    displayedFilters: query.displayedFilters,
    filterValues: query.filterValues,
    hasCreate: hasCreate!,
    page: query.page,
    perPage: query.perPage,
    resource,
    setFilters: queryModifiers.setFilters,
    hideFilter: queryModifiers.hideFilter,
    showFilter: queryModifiers.showFilter,
    setPage: queryModifiers.setPage,
    setPerPage: queryModifiers.setPerPage,
    setSort: queryModifiers.setSort,
    // total might be null if the resource has not been initialized yet (custom routes for example)
    total,
    version: 1,
  };
}

function useRecord(data: any, getRecord: any) {
  return useMemo(() => {
    if (!data) {
      return null;
    }

    return getRecord(data);
  }, [data, getRecord]);
}

export function useStaticShowController(record: any) {
  return {
    loading: false,
    loaded: true,
    record,
  };
}

export function useApolloShowController(
  query: any,
  getRecord: (data: any) => any,
  opts?: {
    resourceName?: string;
  }
) {
  const {data, loading} = query;

  const record = useRecord(data, getRecord);
  const basePath = `/${opts?.resourceName}`;

  return {
    loading: loading,
    loaded: !loading && !!data,
    record: record,
    basePath,
  };
}

/**
 * Needs access to:
 *
 * - The query loading the data.
 * - A function to get the data from the query.
 * - The saving query state.
 *
 * See:
 * https://github.com/marmelab/react-admin/blob/next/packages/ra-core/src/controller/useEditController.ts
 */
export function useApolloEditController(opts: {
  query: any;
  getRecord: (data: any) => any;
  onSave: (data: any) => Promise<{id: string}>;
  saveQuery: any;

  id?: string;
  resourceName: string;
}): EditControllerProps {
  const {data, loading, loaded} = opts.query;
  const redirect = useRedirect();

  const record = useRecord(data, opts.getRecord);

  const translate = useTranslate();
  const defaultTitle = translate("ra.page.edit", {
    name: `${opts.resourceName}`,
    id: opts.id,
    record,
  });
  const basePath = `/${opts.resourceName}`;

  return {
    loading,
    loaded,
    saving: opts.saveQuery.loading,
    defaultTitle,
    redirect: DefaultRedirect,
    save: async (
      data: any,
      redirectTo: RedirectionSideEffect = DefaultRedirect
    ) => {
      const newRecord = await opts.onSave(data);
      // @ts-ignore: Two different direct types inside react-admin here!
      redirect(redirectTo, basePath, newRecord.id, newRecord);
    },
    resource: opts.resourceName,
    basePath,
    record,
    version: 1,
  };
}

const DefaultRedirect = "list";
