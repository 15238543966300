import * as React from "react";
import {Dialog, DialogActions, Button, DialogContent} from "@material-ui/core";
import {PlayerManager} from "languagetool-player/src/ui/CaptionTrack/Manager";
import {ContextAwarePlayer} from "languagetool-player/src/ui/CaptionTrack/ContextAwarePlayer";
import {Unit} from "languagetool-player";
import {register} from "languagetool-player/src/contrib/Internal";
import {KeyboardShortcuts} from "languagetool-player/src/ui/CaptionTrack/KeyboardShortcuts";
import {useSubtitleLines} from "languagetool-player/src/ui/Subtitles/useSubtitleLines";
import {DisplayLine} from "languagetool-player/src/ui/Subtitles/types";

register({hostingApiUrl: `${process.env.REACT_APP_HOSTING_URL}/api/`});

export function PlayWindow(props: {
  unit: Unit | null;
  isOpen: boolean;
  onRequestClose: () => void;
}) {
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"lg"}
      open={props.isOpen}
      onClose={props.onRequestClose}
    >
      <DialogContent>
        {props.unit ? (
          <PlayerManager unit={props.unit} language={"fa"}>
            <KeyboardShortcuts />
            <ContextAwarePlayer
              track={props.unit.getMediaTrack("fa") as any}
              style={{width: "100%"}}
              nativeControls={true}
            />
            <Subtitles />
          </PlayerManager>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onRequestClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function Subtitles() {
  // TODO: Because we don't import the yaml files properly this does not work
  //const line = useFarsiTransliterationLine("wiktionary");
  const renderedLines = useSubtitleLines({
    lines: [
      {type: "main"},
      //line,
      {type: "translation", language: "en"},
    ] as DisplayLine[],
  });
  const lines = renderedLines.lines.map((line, idx) => {
    return <div key={idx}>{line.rendered}</div>;
  });
  return <div>{lines}</div>;
}

// Returns something that can be serialized to JSON.
export function convertMarketUnitDataToPlayerUnitData(marketUnit: {
  captionTrack: {
    language: string;
    currentVersion: {
      data: string;
      translations: {
        language: string;
        data: string;
      }[];
    };
  };
  mediaTrack: {
    type: string;
    url: string;
    language: string;
    background?: any;
  };
}) {
  const captionTrack = marketUnit.captionTrack;
  const lang = captionTrack.language;

  const mediaTrack = marketUnit.mediaTrack;

  return {
    sources: [
      {
        type: mediaTrack.type as any,
        url: mediaTrack.url,
        lang: mediaTrack.language,
        background: mediaTrack.background,
      },
    ],
    captions: [
      {
        lang: lang!,
        data: JSON.parse(captionTrack.currentVersion.data),
      },
    ],
    translations: captionTrack.currentVersion.translations.map((trans) => {
      return {
        lang: trans.language,
        data: JSON.parse(trans.data),
      };
    }),
  };
}

export type PlayerUnitData = ReturnType<
  typeof convertMarketUnitDataToPlayerUnitData
>;

export function convertMarketUnitDataToPlayerUnit(marketUnit: any) {
  const data = convertMarketUnitDataToPlayerUnitData(marketUnit);
  return new Unit(data);
}
