import React from "react";
import styles from "./Box.cssm";

export function Box(props: any) {
  return (
    <div
      style={{
        width: "500px",
      }}
      className={styles.box}
    >
      {props.children}
    </div>
  );
}
