import React, {Fragment, useState, useCallback} from "react";
import {BulkDeleteButton, BulkExportButton, Button} from "react-admin";
import {ChangeStatusDialog} from "./ChangeStatusDialog";

export function TaskBulkActionButtons(props: any) {
  return (
    <Fragment>
      <ChangeStatusButton label="Reset Views" {...props} />
      <BulkExportButton {...props} />
      <BulkDeleteButton {...props} />
    </Fragment>
  );
}

function ChangeStatusButton(props: any) {
  const {selectedIds} = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  return (
    <>
      <Button label="Change Status" onClick={handleClick} />
      <ChangeStatusDialog
        show={isOpen}
        onRequestClose={() => setIsOpen(false)}
        selectedIds={selectedIds}
      />
    </>
  );
}
