import React, {useCallback, useRef} from "react";
import {Cancel} from "@material-ui/icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import {SelectInput, SimpleForm, useNotify, ReferenceInput} from "react-admin";
import {FormSpy, FormSpyRenderProps} from "react-final-form";
import {gql as gqlm} from "@apollo/client";
import {useMutation} from "@apollo/client";
import Button from "../../components/Button";
import {ChangeContentIssueAssignment} from "../../types/ChangeContentIssueAssignment";

export function ChangeIssueAssignmentDialog(props: {
  show: boolean;
  onRequestClose: any;
  selectedIds: string[];
}) {
  const formAPI = useRef<any>(null);
  const handleSave = useCallback(() => {
    formAPI.current.submit();
  }, []);
  const notify = useNotify();

  const [changeAssignment, {data: mutationData}] = useMutation<
    ChangeContentIssueAssignment
  >(gqlm`
    mutation ChangeContentIssueAssignment($id: ID!, $props: ChangeContentIssueAssignmentProps!) {
      changeContentIssueAssignment(issueId: $id, props: $props) {
        contentIssue {
          id,
          assignedTo { id, name }
        }
      }
    }
  `);

  return (
    <Dialog fullWidth open={props.show} onClose={props.onRequestClose}>
      <DialogTitle>Change Assignment</DialogTitle>
      <DialogContent>
        <SimpleForm
          toolbar={null}
          save={async (data: any) => {
            await changeAssignment({
              variables: {
                id: props.selectedIds[0],
                props: {
                  assignedTo: data.assignedEditorId,
                },
              },
            });
            notify("Content Issue Assigned!");
            props.onRequestClose();
          }}
        >
          <FormSpy
            render={(props: FormSpyRenderProps) => {
              formAPI.current = props.form;
              return null;
            }}
          />

          <ReferenceInput
            label="Assigned Editor"
            source="assignedEditorId"
            reference="editors"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        </SimpleForm>
      </DialogContent>
      <DialogActions>
        <Button
          label={"Save"}
          size={"medium"}
          variant={"contained"}
          onClick={handleSave}
        />
        <Button
          label="ra.action.cancel"
          onClick={props.onRequestClose}
          size={"medium"}
        >
          <Cancel />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
