import {useBaconObservable} from "../utils";
import {useMemo} from "react";
import {PlayerAPI} from "../Video";
import {Captions, LineIndexResult} from "../../models";
import {useCaptionTrackContext} from "../CaptionTrack/Manager";


/**
 * Return the current line index as published by the player context.
 */
export function useCurrentLineIndex() {
  const {currentLineIndex$} = useCaptionTrackContext();
  return useBaconObservable(currentLineIndex$, null);
}


/**
 * Return a Bacon property that exposes the current line index based on the given player
 * state and captions. We will put this into the the player context, so the calculation
 * has to run only once even when used in multiple places.
 */
export function useMakeCurrentLineIndexProperty(api: PlayerAPI|undefined, captions: Captions|undefined) {
  return useMemo<Bacon.Property<any, null|number>|null>(() => {
    if (!captions) {
      return null;
    }
    if (!api) {
      return null;
    }
    return api.time$
      .map(time => {
        return {index: captions.getLineIndexForTime(time), time};
      })
      .scan<number|null>(null, (previousIdx, now): number|null => {
        // If there is no current line, then sometimes show the previous or next line in a bid to smooth out
        // small holes between the lines.
        // TODO: Replace this logic with smoothHolesBetweenLines().
        if (now.index.current === null) {

          // This is the first line - show it early.
          if (now.index.previous === null) {
            // And we do have a "next" line.
            if (now.index.next !== null) {
              const nextStart = captions.getTime(now.index.next)!;
              if (nextStart - now.time < 1) {
                return 0;
              }
            }
            return null;
          }

          // Keep showing the previous line for a little while
          else if (previousIdx !== null) {
            let lineTime = captions.getTime(previousIdx);
            let lineDuration = captions.getDuration(previousIdx);

            if (lineTime === null || lineDuration === null) {
              return now.index.current;
            }

            // The end of the current line
            let threshold = (lineTime + lineDuration);

            // For one second more, accept to show the previous one.
            // TODO: Sometimes the lines are 1.1s apart, leading to flickering. Should we not hold the
            // line at all in certain cases? Or have a min/max threshold kind of thing.
            if (now.time > threshold && now.time - threshold < 2) {
              return previousIdx;
            }
          }
        }

        return now.index.current;
      })
      .skipDuplicates();

  }, [api ? api.time$ : null, captions]);
}


/**
 * This is currently not used in the Bacon property above because for historical reasons, those is written
 * to be used in a scan()/reduce(). I believe this can be changed, but did not want to test it for now.
 * Consider this the new version.
 */
export function smoothHolesBetweenLines(index: LineIndexResult, time: number, captions: Captions): number|null {
  // If there is no current line, then sometimes show the previous or next line in a bid to smooth out
  // small holes between the lines.
  if (index.current === null) {

    // This is the first line - show it early.
    if (index.previous === null) {
      // And we do have a "next" line.
      if (index.next !== null) {
        const nextStart = captions.getTime(index.next)!;
        if (nextStart - time < 1) {
          return 0;
        }
      }
      return null;
    }

    // Keep showing the previous line for a little while
    else {
      let lineTime = captions.getTime(index.previous);
      let lineDuration = captions.getDuration(index.previous);

      if (lineTime === null || lineDuration === null) {
        return index.current;
      }

      // The end of the current line
      let threshold = (lineTime + lineDuration);

      // For one second more, accept to show the previous one.
      // TODO: Sometimes the lines are 1.1s apart, leading to flickering. Should we not hold the
      // line at all in certain cases? Or have a min/max threshold kind of thing.
      if (time > threshold && time - threshold < 2) {
        return index.previous;
      }
    }
  }

  return index.current;
}