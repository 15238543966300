import {ContentIssueListQuery_contentIssues_edges_node} from "../../types/ContentIssueListQuery";
import React from "react";
import {useState, useCallback} from "react";
import {ChangeIssueAssignmentDialog} from "./ChangeIssueAssignmentDialog";

export function AssignmentField(props: {
  label?: string;
  record?: ContentIssueListQuery_contentIssues_edges_node;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsOpen(true);
    },
    [setIsOpen]
  );

  return (
    <>
      <div onClick={handleClick}>
        {props.record?.assignedTo?.name || (
          <span style={{color: "gray"}}>Unassigned</span>
        )}
      </div>

      <ChangeIssueAssignmentDialog
        show={isOpen}
        onRequestClose={() => setIsOpen(false)}
        selectedIds={[props?.record?.id!]}
      />
    </>
  );
}
