import {PlayerState, VideoProps} from "../Video/Api";
import React, {useCallback} from "react";
import {useCaptionTrackContext} from "./Manager";
import {DefaultPlayer} from "../Video";
import loglevel from 'loglevel';


const log = loglevel.getLogger("ContextAwarePlayer")
log.setLevel("DEBUG")


type ContextAwarePlayerProps = Pick<VideoProps, Exclude<keyof VideoProps, 'controlRef'|'onTime'>>;


export function ContextAwarePlayer(props: ContextAwarePlayerProps) {
  const {...otherProps} = props;

  const {popupState, playerAPI, setPlayerState, setPlayerAPI} = useCaptionTrackContext()

  const handleClick = useCallback(() => {
    // If the popup is open, then a click will NOT play (or even pause).
    // We allow the area to serve as a "click the popup away" thing.
    if (popupState.isOpen) {
      return;
    }

    if (playerAPI?.getState().isPlaying) {
      log.info('handleClick(): pause, because currently playing.')
      playerAPI?.pause();
    } else {
      log.info('handleClick(): play, because currently paused.')
      playerAPI?.play();
    }
  }, [popupState, playerAPI]);

  const handlePlayerStateChanged = useCallback((newState: PlayerState) => {
    setPlayerState(newState)

    if (props.onStateChange) {
      props.onStateChange(newState);
    }

    // send a "suggest popup close" event, because popup state is currenlty managed outside.
    // if (newState.isPlaying && !this.state.playerState!.isPlaying) {
    //   this.setState({clickedVocable: null});
    // }
  }, [setPlayerState]);

  if (!props.track) {
    return null;
  }

  return <DefaultPlayer
    {...otherProps}
    onStateChange={handlePlayerStateChanged}
    controlRef={setPlayerAPI}
    onClick={handleClick}
  />
}