import React, {useContext} from "react";
import {Button, Card, FormControlLabel, Checkbox} from "@material-ui/core";
import {EpisodeList} from "./EpisodeList";
import {useRedirect} from "../../components/useRedirect";
import {useMutation} from "@apollo/client";
import {gql} from "@apollo/client";

export function SeasonList(props: {record?: any; refetch?: () => void}) {
  const {record} = props;
  const redirectCreate = useRedirect(`/seasons/create?${record.id}`);
  const redirect = useRedirect();

  return (
    <div>
      {props.record.seasons.map((season: any) => {
        return (
          <Card
            style={{
              padding: "1em",
              marginTop: "1em",
            }}
            key={season.id}
          >
            <h3 style={{marginTop: 0}}>
              {season.name || (
                <span>
                  <em>Unnamed Season</em>
                </span>
              )}
            </h3>

            <div style={{margin: "10px 0"}}>
              <Button
                variant={"contained"}
                onClick={() => {
                  redirect(`/seasons/${season.id}/edit`);
                }}
              >
                Edit Season
              </Button>
              &nbsp;&nbsp;
              <ToggleSeasonPublicCheckbox record={season} />
            </div>

            <EpisodeList
              units={season.units}
              seriesId={record.id}
              seasonId={season.id}
              refetch={props.refetch}
            />
          </Card>
        );
      })}

      <div style={{marginTop: "20px"}}>
        <Button variant={"contained"} onClick={redirectCreate}>
          Add Season
        </Button>
      </div>
    </div>
  );
}

function useMarkSeasonPublic(seasonId: string) {
  return useMutation<any>(
    gql`
      mutation($isPublic: Boolean!, $seasonId: String!) {
        markSeasonPublic(isPublic: $isPublic, seasonId: $seasonId) {
          season {
            id
            isReady
          }
        }
      }
    `,
    {
      variables: {
        seasonId: seasonId,
      },
    }
  );
}

function ToggleSeasonPublicCheckbox(props: {
  record: {id: string; isReady: boolean};
}) {
  const [markSeasonPublic] = useMarkSeasonPublic(props.record.id);

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={props.record.isReady}
          onChange={(e) => {
            markSeasonPublic({variables: {isPublic: e.target.checked}});
          }}
        />
      }
      label="Public & Published"
      style={{
        fontWeight: "bold",
      }}
    />
  );
}
