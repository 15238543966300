import * as React from "react";
import {useState, useEffect, useContext, useMemo} from "react";
import createAuth0Client from "@auth0/auth0-spa-js";
import {__RouterContext} from "react-router";
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client";

const DEFAULT_REDIRECT_CALLBACK = (appState: any, router: any) => {
  // Redirects to whatever is stored in app state, or redirects to the current url, effectively removing the auth0 state arguments.
  const url =
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname;

  if (!router) {
    window.location.replace(url);
  }

  router.history.replace(url);
};

export const Auth0Context = React.createContext<any>(null);

export const useAuth0 = () => {
  const ctx = useContext(Auth0Context);
  if (!ctx) {
    throw new Error(
      "You need to setup the Auth0Provider component at the tree root."
    );
  }
  return ctx;
};

interface Props extends Auth0ClientOptions {
  children: any;
  getRedirectUri: () => void;
  onRedirectCallback?: (appState: any, router: any) => void;
}

export const Auth0Provider = ({
  children,
  getRedirectUri,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}: Props) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState<Auth0Client>();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const router = useContext(__RouterContext);

  useEffect(() => {
    const initAuth0 = async () => {
      // Sometimes this fails with error 400, but it does not raise an exception, it just never returns!
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes("code=")) {
        const {appState} = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState, router);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
      }

      setLoading(false);
    };

    initAuth0().catch((e) => {
      console.error(e);
      console.log("Auth0 initialization has failed, the app will be broken.");
    });
    // eslint-disable-next-line
  }, []);

  function getAppState() {
    // Must be a local pathname, because router.replace() later expects one.
    return {
      targetUrl: `${window.location.pathname}${window.location.hash}`,
    };
  }

  const loginWithPopup = async (p = {}) => {
    const params = {
      appState: getAppState(),
      redirect_uri: getRedirectUri(),
      ...p,
    };

    setPopupOpen(true);
    try {
      await auth0Client!.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client!.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client!.handleRedirectCallback();
    const user = await auth0Client!.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  const value = useMemo(() => {
    return {
      isAuthenticated,
      user,
      loading,
      popupOpen,
      loginWithPopup,
      handleRedirectCallback,
      getIdTokenClaims: (...p: any[]) => auth0Client!.getIdTokenClaims(...p),
      loginWithRedirect: (p: any[]) => {
        const params = {
          appState: getAppState(),
          redirect_uri: getRedirectUri(),
          ...p,
        };
        return auth0Client!.loginWithRedirect(params as any);
      },
      getTokenSilently: (...p: any[]) => auth0Client!.getTokenSilently(...p),
      getTokenWithPopup: (...p: any[]) => auth0Client!.getTokenWithPopup(...p),
      logout: (p: any) => {
        const params = {
          returnTo: getRedirectUri(),
          ...p,
        };
        return auth0Client!.logout(params);
      },
    };
  }, [
    isAuthenticated,
    user,
    loading,
    popupOpen,
    loginWithPopup,
    handleRedirectCallback,
    auth0Client,
  ]);

  return (
    <Auth0Context.Provider value={value}>{children}</Auth0Context.Provider>
  );
};
