import React from "react";
import {TitleForRecord} from "react-admin";
import {useQuery} from "@apollo/client";
import {SeasonList} from "./SeasonList";
import {useApolloShowController} from "../../dataProvider/apolloControllers";
import {gql as gqlm} from "@apollo/client";
import {InfoTabs} from "./InfoTabs";
import {SeasonQuery} from "../../types/SeasonQuery";

const useSeries = (seriesId: string) => {
  return useQuery<SeasonQuery>(
    gqlm`
    query SeasonQuery($seriesId: ID!) {
      series(id: $seriesId) {
        id,
        name,
        description,
        isReady,
        links {
          type,
          value
        }
        
        image { url },
        bannerImage(fallback: false) { url }
        
        seasons(includeNotReady: true) {
          id,
          name,
          isReady,
          units(includeNotReady: true) {
            id,
            name,
            description,
            isReady
          }
        }
      }
    }
  `,
    {
      variables: {
        seriesId,
      },
    }
  );
};

export const SeriesShow = (props: any) => {
  const query = useSeries(props.id);
  const controllerProps = useApolloShowController(query, (data) => data.series);

  return (
    <div>
      <TitleForRecord
        title={`Series: ${query?.data?.series?.name}`}
        record={{}}
      />

      {controllerProps.record ? (
        <InfoTabs series={controllerProps.record} />
      ) : null}
      {controllerProps.record ? (
        <SeasonList {...controllerProps} refetch={() => query.refetch()} />
      ) : null}
    </div>
  );
};
