import {useLocation} from "react-router";

// NB: This is definitely used *outside* of the react router, so useLocation() is not an option.
export function useURLQuery(): URLSearchParams {
  return new URLSearchParams(window.location.search);
}

export function useRouterURLQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}
