import * as React from "react";

export const BookIcon = React.memo((props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <path fill="#1783bd" d="M41 6v36a2 2 0 01-2 2h-5V4h5a2 2 0 012 2z" />
      <linearGradient
        id="prefix__a"
        x1={323.856}
        x2={323.856}
        y1={-227.305}
        y2={-224.163}
        gradientTransform="rotate(-90 305.067 42.79)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.441} stopColor="#999998" />
        <stop offset={0.653} stopColor="#c1c1c0" />
        <stop offset={0.88} stopColor="#e5e5e5" />
        <stop offset={1} stopColor="#f3f3f3" />
      </linearGradient>
      <path
        fill="url(#prefix__a)"
        d="M39 6v36a2 2 0 01-2 2h-6V4h6a2 2 0 012 2z"
      />
      <linearGradient
        id="prefix__b"
        x1={3.638}
        x2={15.691}
        y1={8.572}
        y2={41.405}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#0176d0" />
        <stop offset={1} stopColor="#16538c" />
      </linearGradient>
      <path
        fill="url(#prefix__b)"
        d="M7 6v36a2 2 0 002 2h2V4H9a2 2 0 00-2 2z"
      />
      <linearGradient
        id="prefix__c"
        x1={16.78}
        x2={31.215}
        y1={5.154}
        y2={44.475}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#33bef0" />
        <stop offset={1} stopColor="#22a5e2" />
      </linearGradient>
      <path
        fill="url(#prefix__c)"
        d="M35 4H11v40h24a2 2 0 002-2V6a2 2 0 00-2-2z"
      />
      <path fill="#1b9de2" d="M16 14h16v2H16z" />
    </svg>
  );
});
