import React from "react";
import {
  EditView,
  SimpleForm,
  TextInput,
  NumberInput,
  SelectInput,
} from "react-admin";
import {ArrayInput, SimpleFormIterator} from "react-admin";
import {useMutation, useQuery} from "@apollo/client";
import {useApolloEditController} from "../dataProvider/apolloControllers";
import {gql} from "@apollo/client";
import {Query} from "./SeriesList";

const useSeries = (seriesId?: string) => {
  return useQuery(
    gql`
      query SeasonQuery($seriesId: ID!) {
        series(id: $seriesId) {
          id
          name
          description
          difficulty
          type
          links {
            type
            value
          }
        }
      }
    `,
    {
      skip: !seriesId,
      variables: {
        seriesId: seriesId,
      },
    }
  );
};

export const useSaveSeries = () => {
  return useMutation<any, any>(
    gql`
      mutation saveSeries($id: ID, $properties: SeriesProperties!) {
        saveSeries(id: $id, properties: $properties) {
          series {
            __typename
            id
            name
            description
            difficulty
            type
            links {
              type
              value
            }
          }
        }
      }
    `,
    {
      variables: {},
      update(cache, {data}: any) {
        let result: any;
        try {
          // @ts-ignore
          result = cache.readQuery({
            query: Query,
          });
        } catch (e) {
          return;
        }

        cache.writeQuery({
          query: Query,
          data: {
            seriesCollection: [
              ...result.seriesCollection,
              data.saveSeries.series,
            ],
          },
        });
      },
    }
  );
};

export const useSeriesEditCreate = (props?: {id?: string}) => {
  const id = props ? props.id : undefined;
  const query = useSeries(id);
  const [save, saveOp] = useSaveSeries();

  return useApolloEditController({
    query,
    saveQuery: saveOp,
    id: id,
    resourceName: "series",
    getRecord: (data) => data.series,
    onSave: async (data) => {
      await save({
        variables: {
          id: id,
          properties: {
            name: data.name,
            description: data.description,
            type: data.type,
            links: data.links?.map(({type, value}: any) => ({type, value})),
          },
        },
      });
      return {id: "foo"};
    },
  });
};

export const SeriesEdit = (props: any) => {
  return (
    <EditView {...useSeriesEditCreate(props)}>
      <SeriesEditForm />
    </EditView>
  );
};

export const SeriesEditForm = (props: any) => {
  const {isCreate, ...otherProps} = props;

  return (
    <SimpleForm {...otherProps}>
      <TextInput source="name" />
      <TextInput multiline source="description" />
      <SelectInput
        source="type"
        choices={[
          {id: "", name: "Standard"},
          {id: "music-album", name: "Music Album"},
        ]}
      />

      <ArrayInput source="links">
        <SimpleFormIterator>
          <SelectInput
            source="type"
            choices={[
              {id: "instagram", name: "Instagram"},
              {id: "youtube", name: "Youtube"},
              {id: "youtube-channel", name: "Youtube Channel"},
              {id: "aparat", name: "Aparat"},
              {id: "url", name: "URL"},
            ]}
          />
          <TextInput source="value" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  );
};
