import {MediaSource} from "../../models";
import {makeYoutubeUrl} from "../../contrib/Youtube";
import {CUSTOM_MEDIA_SOURCE_TYPES} from "../../models/MediaSource";

export async function resolveMediaSource(mediaSource: MediaSource): Promise<MediaSource> {
  if (CUSTOM_MEDIA_SOURCE_TYPES[mediaSource.type]) {
    const overwrites = await CUSTOM_MEDIA_SOURCE_TYPES[mediaSource.type](mediaSource.url);
    mediaSource = {...mediaSource, ...overwrites};
  }
  return mediaSource;
}

export async function makeVideoUrl(mediaSource: MediaSource): Promise<string> {
  mediaSource = await resolveMediaSource(mediaSource);

  if (mediaSource.type === 'youtube') {
    return makeYoutubeUrl(mediaSource.url)
  }
  if (mediaSource.type === 'url') {
    return mediaSource.url!;
  }
  throw new Error(`Unknown media source type: ${mediaSource.type}`);
}