import React, {useCallback, useRef} from "react";
import {Cancel} from "@material-ui/icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import {
  SelectInput,
  SimpleForm,
  TextInput,
  BooleanInput,
  useNotify,
} from "react-admin";
import {TaskStatus} from "../TaskEdit";
import {FormSpy, FormSpyRenderProps} from "react-final-form";
import {gql} from "@apollo/client";
import {useMutation} from "@apollo/client";
import Button from "../../components/Button";

export function ChangeStatusDialog(props: {
  show: boolean;
  onRequestClose: any;
  selectedIds: string[];
}) {
  const formAPI = useRef<any>(null);
  const handleSave = useCallback(() => {
    formAPI.current.submit();
  }, []);
  const notify = useNotify();

  const [changeStatus, {data: mutationData}] = useMutation<any, any>(gql`
    mutation setTasksStatus($ids: [ID]!, $status: TaskStatus!, $mark: Boolean) {
      setTasksStatus(ids: $ids, status: $status, markObjectsAsReady: $mark) {
        tasks {
          id
        }
      }
    }
  `);

  return (
    <Dialog fullWidth open={props.show} onClose={props.onRequestClose}>
      <DialogTitle>Change Status</DialogTitle>
      <DialogContent>
        <SimpleForm
          toolbar={null}
          save={async (data: any) => {
            await changeStatus({
              variables: {
                ids: props.selectedIds,
                status: data.status,
                mark: data.mark_ready,
              },
            });
            notify("Task Status Changes Saved!");
            props.onRequestClose();
          }}
        >
          <FormSpy
            render={(props: FormSpyRenderProps) => {
              formAPI.current = props.form;
              return null;
            }}
          />
          <SelectInput source="status" choices={TaskStatus} />
          <BooleanInput source="mark_ready" />
        </SimpleForm>
      </DialogContent>
      <DialogActions>
        <Button
          label={"Save"}
          size={"medium"}
          variant={"contained"}
          onClick={handleSave}
        />
        <Button
          label="ra.action.cancel"
          onClick={props.onRequestClose}
          size={"medium"}
        >
          <Cancel />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
