import React from "react";
import {
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  Button,
} from "@material-ui/core";
import {useQuery} from "@apollo/client";
import {gql} from "@apollo/client";

import {Line, Vocable} from "languagetool-player/src/models";
import {Captions} from "languagetool-player/src/models/Captions";
import {getVocableText} from "languagetool-player/src/models/getVocableText";
import {CaptionTrack} from "languagetool-player/src/models/formats/CaptionTrack";

export function CaptionTrackDialog(props: {
  onRequestClose: () => void;
  isOpen: boolean;

  trackVersionId?: string;
}) {
  return (
    <Dialog onClose={props.onRequestClose} open={props.isOpen}>
      <DialogTitle>Inspect Track</DialogTitle>

      <Content trackVersionId={props.trackVersionId} />

      <DialogActions>
        <Button onClick={props.onRequestClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

function Content(props: {trackVersionId?: string}) {
  const {data, loading, error} = useQuery(
    gql`
      query CaptionTrackPopup($id: ID!) {
        captionTrackVersion(id: $id) {
          id
          data
        }
      }
    `,
    {
      skip: !props.trackVersionId,
      variables: {
        id: props.trackVersionId,
      },
    }
  );

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>ERROR!</div>;
  }

  return (
    <DialogContent>
      <Lines data={data.captionTrackVersion.data} />
    </DialogContent>
  );
}

function Lines(props: {data: string}) {
  const data: CaptionTrack = JSON.parse(props.data);
  const parsed = new Captions("fa", data);

  const lines = data.lines.map((lineGroup, idx) => {
    const formatted = <SubtitleLine lineGroup={lineGroup} parsed={parsed} />;

    return (
      <div
        style={{
          background: "#ececec",
          margin: "0.2em 0",
        }}
        key={idx}
      >
        <strong>{idx + 1}&nbsp;&nbsp;&nbsp;</strong>
        {formatted}
      </div>
    );
  });

  return <div>{lines}</div>;
}

function SubtitleLine(props: {lineGroup: Line; parsed: Captions}) {
  const prePostFormatter = (x?: string) => x;

  const lineEls = props.lineGroup.elements.map((line, idx) => {
    const wordEls = line.map((el: Vocable, idx: number) => {
      let textToRender = getVocableText(el, {});

      const hasNode = props.parsed.getNodesForVocable(el.id).length > 0;

      return (
        <span key={idx}>
          {prePostFormatter(el.pre)}
          <span
            style={{
              color: hasNode ? "black" : "red",
            }}
          >
            {textToRender}
          </span>
          {prePostFormatter(el.post)}
        </span>
      );
    });

    return <div key={idx}>{wordEls}</div>;
  });

  return <div>{lineEls}</div>;
}
