import {
  Filter,
  NullableBooleanInput,
  TextInput,
  SelectInput,
} from "react-admin";
import React from "react";

export const StatusOptions = [
  {id: "", name: ""},

  // Was marked as ready (maybe has another task attached, maybe not)
  {id: "ready", name: "Ready"},

  // Not ready, but has a task
  {id: "not-ready-but-task", name: "Not Ready, But Task"},

  // Not ready and no task
  {id: "not-ready", name: "Not Ready"},

  {id: "has-task", name: "Has Task"},
  {id: "no-task", name: "No Task"},
];

export const StatusOptionsNoTasks = [
  {id: "", name: ""},
  {id: "ready", name: "Ready"},
  {id: "not-ready", name: "Not Ready"},
];

export const SiteOptions = [
  {id: "", name: ""},
  {id: "has-site", name: "Assigned"},
  {id: "has-no-site", name: "Not Assigned"},
  {id: "farsi.school", name: "Farsi.school"},
];

export const TypeOptions = [
  {id: "", name: ""},
  {id: "video", name: "Video"},
  {id: "book", name: "Book"},
];

export const UnitFilter = (props: any) => {
  const {setFilters, context} = props;

  return (
    <div>
      <Filter {...props}>
        <TextInput label="Search" source="c_search" alwaysOn />
        <SelectInput
          label="Site"
          choices={SiteOptions}
          source="c_site"
          alwaysOn
        />
        <NullableBooleanInput
          label="Part of Series"
          source="c_has_series"
          alwaysOn
        />
        <SelectInput
          label="Public"
          choices={StatusOptionsNoTasks}
          source="c_public"
          alwaysOn
        />
        <SelectInput
          label="Media"
          choices={StatusOptionsNoTasks}
          source="c_media"
          alwaysOn
        />
        <SelectInput
          label="Subtitles"
          choices={StatusOptions}
          source="c_subtitles"
          alwaysOn
        />
        <SelectInput
          label="Translation"
          choices={StatusOptions}
          source="c_translation"
          alwaysOn
        />
        <SelectInput
          label="Grammar"
          choices={StatusOptions}
          source="c_grammar"
          alwaysOn
        />
        <SelectInput
          label="Type"
          choices={TypeOptions}
          source="c_type"
          alwaysOn
        />
      </Filter>

      {context !== "button" ? (
        <div
          style={{
            marginBottom: "1em",
            fontSize: ".8em",
          }}
        >
          <strong>Predefined</strong>:
          <Preset
            label={"To Be Subtitled"}
            setFilters={setFilters}
            filters={{
              c_subtitles: "not-ready",
              c_media: "ready",
            }}
          />
          <Preset
            label={"To Be Translated"}
            setFilters={setFilters}
            filters={{
              c_subtitles: "ready",
              c_translation: "not-ready",
            }}
          />
          <Preset
            label={"To Be Grammared"}
            setFilters={setFilters}
            filters={{
              c_subtitles: "ready",
              c_grammar: "not-ready",
            }}
          />
          <Preset
            label={"To Be Published"}
            setFilters={setFilters}
            filters={{
              c_subtitles: "ready",
              c_grammar: "ready",
              c_translation: "ready",
              c_media: "ready",
              c_public: "not-ready",
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export function Preset(props: {label: string; setFilters: any; filters: any}) {
  return (
    <a
      href={"#"}
      style={{margin: "0 0.5em"}}
      onClick={(e) => {
        e.preventDefault();
        props.setFilters(props.filters);
      }}
    >
      {props.label}
    </a>
  );
}
