import React, {Fragment, useState, useCallback} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {fade} from "@material-ui/core/styles/colorManipulator";
import ActionDelete from "@material-ui/icons/Delete";
import classnames from "classnames";
import {useTranslate, useRefresh, useNotify, useRedirect} from "ra-core";

import {Button} from "react-admin";
import {Confirm} from "./Confirm";

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
    "&:hover": {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
}));

const DeleteWithConfirmButton = ({
  classes: classesOverride,
  className,
  icon,
  label = "ra.action.delete",
  onClick,
  record,
  redirect: redirectTo,
}: any) => {
  const [open, setOpen] = useState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const classes = useStyles({classes: classesOverride});

  const handleClick = (e: any) => {
    setOpen(true);
    e.stopPropagation();
  };

  const handleDialogClose = (e: any) => {
    setOpen(false);
    e.stopPropagation();
  };

  return (
    <Fragment>
      <Button
        onClick={handleClick}
        label={label}
        className={classnames(
          "ra-delete-button",
          classes.deleteButton,
          className
        )}
        key="button"
      >
        {icon}
      </Button>

      <Confirm
        isOpen={open}
        loading={false}
        title="ra.message.delete_title"
        translateOptions={{
          name: "resource",
          id: record.id,
        }}
        content="ra.message.delete_content"
        onConfirm={onClick}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

DeleteWithConfirmButton.defaultProps = {
  redirect: "list",
  icon: <ActionDelete />,
};

export {DeleteWithConfirmButton};
