import React, {useContext} from "react";
import {CreateView} from "react-admin";
import {SeasonEditForm, useSeasonEditCreate} from "./SeasonEdit";
import {__RouterContext} from "react-router";

export const SeasonCreate = (props: {id: string}) => {
  const router = useContext(__RouterContext);
  const seriesId = router.location.search.slice(1);

  const controllerProps = useSeasonEditCreate({
    seriesId,
  });

  if (!seriesId) {
    return <div>Needs series id in url.</div>;
  }

  return (
    <CreateView {...controllerProps}>
      <SeasonEditForm isCreate />
    </CreateView>
  );
};
