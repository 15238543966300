import React from "react";
import {Labeled} from "ra-ui-materialui";
import {
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import {useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {UnitShowQuery_unit} from "../../types/UnitShowQuery";

export function SummaryTab(props: {unit: UnitShowQuery_unit}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Labeled label="ID">
        <div>{props.unit.id}</div>
      </Labeled>
      <Labeled label="Name">
        <div>{props.unit.name}</div>
      </Labeled>
      <Labeled label="Image">
        {props.unit.previewImage ? (
          <img src={props.unit.previewImage.url} />
        ) : null}
      </Labeled>
      <Labeled label="Description">
        <div>{props.unit.description}</div>
      </Labeled>
      <Labeled label="Links">
        <div>
          {props.unit.metadata.aparat ? (
            <a href={`https://www.aparat.com/v/${props.unit.metadata.aparat}`}>
              Aparat
            </a>
          ) : null}
          {props.unit.metadata.youtube ? (
            <a
              href={`https://www.youtube.com/watch?v=${props.unit.metadata.youtube}`}
            >
              Youtube
            </a>
          ) : null}
        </div>
      </Labeled>
      <Labeled label="Status">
        <CaptionTrackStatus record={props.unit} />
      </Labeled>
      <AccessStatus record={props.unit} />
    </div>
  );
}

function useMarkCaptionTrackVersionTextReady(versionId: string) {
  return useMutation<any>(
    gql`
      mutation($isReady: Boolean!, $versionId: String!) {
        markCaptionTrackVersionTextReady(
          isReady: $isReady
          trackVersionId: $versionId
        ) {
          captionTrackVersion {
            captionTrack {
              id
              isTextReady
            }
          }
        }
      }
    `,
    {
      variables: {
        versionId,
      },
    }
  );
}

function useMarkCaptionTrackVersionGrammarReady(versionId: string) {
  return useMutation<any>(
    gql`
      mutation($isReady: Boolean!, $versionId: String!) {
        markCaptionTrackVersionGrammarReady(
          isReady: $isReady
          trackVersionId: $versionId
        ) {
          captionTrackVersion {
            captionTrack {
              id
              isGrammarReady
            }
          }
        }
      }
    `,
    {
      variables: {
        versionId,
      },
    }
  );
}

function useMarkUnitPublic(unitId: string) {
  return useMutation<any>(
    gql`
      mutation($isPublic: Boolean!, $unitId: String!) {
        markUnitPublic(isPublic: $isPublic, unitId: $unitId) {
          unit {
            id
            isReady
          }
        }
      }
    `,
    {
      variables: {
        unitId,
      },
    }
  );
}

function useMarkUnitMetadataComplete(unitId: string) {
  return useMutation<any>(
    gql`
      mutation($isComplete: Boolean!, $unitId: String!) {
        markUnitMetadataComplete(isComplete: $isComplete, unitId: $unitId) {
          unit {
            id
            isMetadataComplete
          }
        }
      }
    `,
    {
      variables: {
        unitId,
      },
    }
  );
}

function CaptionTrackStatus(props: {record: UnitShowQuery_unit}) {
  const {record} = props;

  const [approveText] = useMarkCaptionTrackVersionTextReady(
    record.captionTrack?.currentVersion?.id ?? ""
  );
  const [approveGrammar] = useMarkCaptionTrackVersionGrammarReady(
    record.captionTrack?.currentVersion?.id ?? ""
  );

  const [markUnitPublic] = useMarkUnitPublic(record.id);
  const [markUnitMetadataComplete] = useMarkUnitMetadataComplete(record.id);

  if (!record.captionTrack) {
    return <div>Does not have a caption track. Go to tracks.</div>;
  }

  if (!record.captionTrack.currentVersion) {
    return <div>The caption track has no current version. Go to tracks.</div>;
  }

  return (
    <div style={{display: "flex", flexDirection: "column"}}>
      <FormControlLabel
        control={
          <Checkbox
            checked={record.captionTrack.isTextReady}
            onChange={(e) => {
              approveText({variables: {isReady: e.target.checked}});
            }}
          />
        }
        label="Text Approved & Ready"
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={record.captionTrack.isGrammarReady}
            onChange={(e) => {
              approveGrammar({variables: {isReady: e.target.checked}});
            }}
          />
        }
        label="Grammar Approved & Ready"
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={record.isMetadataComplete}
            onChange={(e) => {
              markUnitMetadataComplete({
                variables: {isComplete: e.target.checked},
              });
            }}
          />
        }
        label="Metadata Ready"
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={record.isReady}
            onChange={(e) => {
              markUnitPublic({variables: {isPublic: e.target.checked}});
            }}
          />
        }
        label="Public & Published"
        style={{
          fontWeight: "bold",
        }}
      />
    </div>
  );
}

function useSetUnitAccessControl() {
  const [mutate] = useMutation<any>(
    gql`
      mutation($unitId: ID!, $siteId: ID!, $requiredTokens: [String]) {
        setUnitAccessControl(
          siteId: $siteId
          unitId: $unitId
          requiredTokens: $requiredTokens
        ) {
          unit {
            id
            accessDefinitions {
              siteId
              requiredTokens
            }
          }
        }
      }
    `
  );

  return (unitId: string, siteId: string, requiredTokens?: string[]) => {
    return mutate({
      variables: {
        unitId,
        siteId,
        requiredTokens,
      },
    });
  };
}

function AccessStatus(props: {record: UnitShowQuery_unit}) {
  const {record} = props;

  const setUnitAccessControl = useSetUnitAccessControl();

  // Find the access definition for farsi.school
  const def = record.accessDefinitions.filter((def) => {
    return def.siteId === "farsischool";
  })[0];
  const currentOption = !def
    ? "default"
    : def.requiredTokens?.length
    ? "paid"
    : "free";

  return (
    <div style={{display: "flex", flexDirection: "column"}}>
      <FormControl>
        <InputLabel>Who can access it?</InputLabel>
        <Select
          value={currentOption}
          onChange={(e) => {
            const now = e.target.value;
            if (now === "paid") {
              setUnitAccessControl(record.id, "farsischool", ["full-access"]);
            } else if (now === "free") {
              setUnitAccessControl(record.id, "farsischool", []);
            } else if (now === "default") {
              setUnitAccessControl(record.id, "farsischool");
            }
          }}
        >
          <MenuItem value={"default"}>Site-wide default</MenuItem>
          <MenuItem value={"paid"}>Only with a paid subscription</MenuItem>
          {/*<MenuItem value={20}>*/}
          {/*  Free access, only with an account.*/}
          {/*</MenuItem>*/}
          <MenuItem value={"free"}>
            Free access, even without an account.
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
