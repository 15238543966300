import React, {Fragment, useState, useCallback} from "react";
import {BulkDeleteButton, Button} from "react-admin";
import {CreateTaskDialog} from "./CreateTaskDialog";

export function BulkActionButtons(props: any) {
  return (
    <Fragment>
      <CreateTaskButton label="Reset Views" {...props} />
    </Fragment>
  );
}

function CreateTaskButton(props: any) {
  const {selectedIds} = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  return (
    <>
      <Button label="Create Tasks" onClick={handleClick} />
      <CreateTaskDialog
        show={isOpen}
        onRequestClose={() => setIsOpen(false)}
        selectedUnitIds={selectedIds}
      />
    </>
  );
}
