import React, {Children, Fragment, isValidElement} from "react";
import MuiToolbar from "@material-ui/core/Toolbar";
import withWidth from "@material-ui/core/withWidth";
import {makeStyles} from "@material-ui/core/styles";
import classnames from "classnames";

import {SaveButton} from "react-admin";
import {DeleteWithConfirmButton} from "./DeleteWithConfirmButton";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[100]
        : theme.palette.grey[900],
  },
  desktopToolbar: {
    marginTop: theme.spacing(2),
  },
  mobileToolbar: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    padding: "16px",
    width: "100%",
    boxSizing: "border-box",
    flexShrink: 0,
    zIndex: 2,
  },
  defaultToolbar: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
  },
  spacer: {
    [theme.breakpoints.down("xs")]: {
      height: "5em",
    },
  },
}));

const Toolbar = ({
  basePath,
  children,
  className,
  classes: classesOverride,
  handleSubmit,
  handleSubmitWithRedirect,
  invalid,
  pristine,
  record,
  redirect,
  resource,
  saving,
  submitOnEnter,
  undoable,
  width,

  onDelete,
  ...rest
}: any) => {
  const classes = useStyles({classes: classesOverride});
  return (
    <Fragment>
      <MuiToolbar
        className={classnames(
          classes.toolbar,
          {
            [classes.mobileToolbar]: width === "xs",
            [classes.desktopToolbar]: width !== "xs",
          },
          className
        )}
        role="toolbar"
        {...rest}
      >
        {Children.count(children) === 0 ? (
          <div className={classes.defaultToolbar}>
            <SaveButton
              handleSubmitWithRedirect={handleSubmitWithRedirect}
              invalid={invalid}
              redirect={redirect}
              saving={saving}
              submitOnEnter={submitOnEnter}
            />
            {record && typeof record.id !== "undefined" && (
              <DeleteWithConfirmButton record={record} onClick={onDelete} />
            )}
          </div>
        ) : (
          children
        )}
      </MuiToolbar>
      <div className={classes.spacer} />
    </Fragment>
  );
};

export const ToolbarWithCustomDelete = withWidth({initialWidth: "xs"})(Toolbar);
