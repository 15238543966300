import * as React from "react";

export function InjectTopBottom(props: {
  children: any;
  top?: any;
  bottom?: any;
}) {
  const {children, top, bottom, ...moreProps} = props;
  const onlyChild = React.Children.only(children);

  return (
    <>
      {top}
      {React.cloneElement(onlyChild, moreProps)}
      {bottom}
    </>
  );
}
