import Uppy from "@uppy/core";
import {DragDrop} from "@uppy/react";
import React, {useEffect, useMemo, useReducer, useRef} from "react";
import AwsS3 from "@uppy/aws-s3-multipart";
import {Box} from "./Box";
import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/progress-bar/dist/style.css";
import {UploadProgress} from "./UploadProgress";

function useUppy(opts: {onUploadComplete: (uploadId: string) => void}) {
  const onUploadCompleteRef = useRef(opts.onUploadComplete);
  useEffect(() => {
    onUploadCompleteRef.current = opts.onUploadComplete;
  });

  const [status, dispatch] = useReducer(
    (state: any, action: any) => {
      switch (action.type) {
        case "set-progress":
          return {...state, progress: action.progress};
        case "set-state":
          return {
            ...state,
            isUploading: action.isUploading,
            progress:
              action.progress !== undefined ? action.progrees : state.progress,
          };
        default:
          throw new Error();
      }
    },
    {
      progress: 0,
      isUploading: false,
    }
  );

  function createUppy() {
    const uppy = Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        maxFileSize: null,
        minNumberOfFiles: 1,
        allowedFileTypes: null,
      },
      autoProceed: true,
    });

    uppy.use(AwsS3, {
      limit: 4,
      companionUrl: `${process.env.REACT_APP_HOSTING_URL}/api/`,
    });

    uppy.on("upload", () => {
      dispatch({type: "set-state", isUploading: true, progress: 0});
    });
    uppy.on(
      "upload-progress",
      (file, {bytesTotal, bytesUploaded, uploader}) => {
        dispatch({type: "set-progress", progress: bytesUploaded / bytesTotal});
      }
    );
    uppy.on("upload-success", (file, response) => {
      dispatch({type: "set-state", isUploading: false});

      // Our server abuses the only return value that uppy passed through to us to give us the id of the
      // upload file object on the server. Unfortunately, not even the internal Uppy Upload ID is available
      // here!
      const uploadId = response.uploadURL;
      onUploadCompleteRef.current(uploadId);
    });
    uppy.on("upload-error", (file, response) => {
      dispatch({type: "set-state", isUploading: false});
      uppy.reset();
      alert("The upload failed!");
    });

    uppy.on("cancel-all", (result) => {
      dispatch({type: "set-state", isUploading: false});
    });

    return uppy;
  }
  const uppy = useMemo(createUppy, [dispatch, onUploadCompleteRef]);

  useEffect(() => {
    uppy.resumeAll();
  }, [uppy]);

  return [status, uppy];
}

export function NewUploadArea(props: {
  onUploadComplete: (uploadId: string) => void;
}) {
  const [status, uppy] = useUppy({onUploadComplete: props.onUploadComplete});
  const DragDropA = DragDrop as any;

  let child: any;
  if (status.isUploading) {
    child = <UploadProgress uppy={uppy} />;
  } else {
    child = <DragDropA uppy={uppy} />;
  }

  return <Box>{child}</Box>;
}
